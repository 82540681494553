import { useMutation } from '@apollo/client';
import React from 'react';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import MasterForm from '../components/MasterForm';
import MasterFormTitle from '../components/MasterFormTitle';
import { CREATE_LOCATION } from './graphql/Mutations';

function CreateLocation() {
  const { navigate } = useRouter();
  const [createMutation, { loading: createLoading }] = useMutation(
    CREATE_LOCATION,
    {
      onCompleted() {
        navigate(ROUTES.LOCATIONS);
      },
      onError() {},
    },
  );

  return (
    <>
      <MasterForm
        title={
          <MasterFormTitle
            navigation={ROUTES.LOCATIONS}
            title={FORM_TYPE.location}
            isEdit={false}
          />
        }
        loading={createLoading}
        mutation={createMutation}
        formType={FORM_TYPE.location}
        isEdit={false}
      />
    </>
  );
}

export default CreateLocation;
