import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin, message } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './auth.less';
import { LOGIN } from './graphql/Mutations';

const { required, email, password } = formValidatorRules;

const Login = () => {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);

  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {},
  });

  const successCallback = (accessToken, userData, refreshToken) => {
    initializeAuth(accessToken, userData, refreshToken);
    if (userData?.forcePasswordReset) {
      navigate(ROUTES.RESET);
    } else {
      navigate(ROUTES.CONTACTS);
    }
  };

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim().toLowerCase(),
        password: values?.password?.trim(),
      };
      const response = await loginMutate({
        variables: { data: formValues },
      });
      if (response?.data) {
        const user = {
          ...response?.data?.loginUser,
        };
        const accessToken = user?.accessToken;
        const userData = {
          ...user?.data,
          defaultLocation: user?.data?.locations?.[0]
            ? {
                ...user?.data?.locations?.[0],
                value: user?.data?.locations?.[0]?.id,
                label: user?.data?.locations?.[0]?.name,
              }
            : null,
        };
        const tokenRefresh = user?.refreshToken;
        successCallback(accessToken, userData, tokenRefresh);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center mb-48">
              <img src="/logo-with-text.png" alt="logo" />
            </div>
            <Form name="Login" onFinish={onFinish} size="large" form={form}>
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter email" />
              </Form.Item>

              <Form.Item
                name="password"
                className="mb-8"
                rules={[
                  { required, message: 'Please enter password!' },
                  password,
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter password"
                  min={8}
                />
              </Form.Item>
              <Form.Item className="text-right mb-8">
                <Link
                  to={ROUTES.LOGIN}
                  onClick={() => message.info('Please connect to your admin')}
                >
                  Forgot password ?
                </Link>
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      </div>
    </div>
  );
};

export default Login;
