import { gql } from '@apollo/client';

export const CREATE_LOCATION = gql`
  mutation CreateLocation($data: CreateLocationInput!) {
    createLocation(data: $data) {
      message
    }
  }
`;

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation(
    $data: UpdateLocationInput!
    $where: LocationWhereUniqueInput!
  ) {
    updateLocation(data: $data, where: $where) {
      message
    }
  }
`;

export const TOGGLE_LOCATION_STATUS = gql`
  mutation ToggleLocationStatus(
    $data: ToggleLocationStatus!
    $where: LocationWhereUniqueInput!
  ) {
    toggleLocationStatus(data: $data, where: $where) {
      message
    }
  }
`;
