import { Select } from 'antd';
import React from 'react';

const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

const CommonDropdown = ({
  list = [],
  placeholder = '',
  mode,
  defaultValue,
  onChange,
  loading = false,
  minWidth = '200px',
  ...rest
}) => (
  <Select
    labelInValue
    showSearch
    {...(mode && { mode })}
    {...(defaultValue && { defaultValue })}
    placeholder={placeholder}
    loading={loading}
    optionFilterProp="children"
    onChange={onChange}
    filterOption={filterOption}
    options={list}
    allowClear
    style={{ minWidth }}
    {...rest}
  />
);
export default CommonDropdown;
