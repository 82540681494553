import { gql } from '@apollo/client';

export const GET_SPECIALIZATIONS = gql`
  query Specializations(
    $filter: SpecializationsFilter
    $sort: SpecializationsSort
  ) {
    specializations(filter: $filter, sort: $sort) {
      specializations {
        createdAt
        createdBy
        description
        id
        key
        name
        isActive
        updatedAt
        creator {
          email
          id
          firstName
          lastName
          role
        }
      }
      count
    }
  }
`;

export const GET_SPECIALIZATION = gql`
  query Specialization($where: SpecializationWhereUniqueInput!) {
    specialization(where: $where) {
      createdAt
      createdBy
      description
      id
      key
      name

      updatedAt
    }
  }
`;
