import { gql } from '@apollo/client';

export const CREATE_SEGMENT = gql`
  mutation CreateSegment($data: CreateUpdateSegmentInput!) {
    createSegment(data: $data) {
      message
    }
  }
`;

export const DELETE_ADMIN_SEGMENT = gql`
  mutation DeleteSegmentAdmin($where: SegmentWhereUniqueInput!) {
    deleteSegmentAdmin(where: $where) {
      message
    }
  }
`;

export const DELETE_USER_SEGMENT = gql`
  mutation DeleteSegmentAdmin($where: SegmentWhereUniqueInput!) {
    deleteSegment(where: $where) {
      message
    }
  }
`;

export const UPDATE_ADMIN_SEGMENT = gql`
  mutation UpdateSegmentAdmin(
    $data: CreateUpdateSegmentInput!
    $where: SegmentWhereUniqueInput!
  ) {
    updateSegmentAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const UPDATE_USER_SEGMENT = gql`
  mutation UpdateSegment(
    $data: CreateUpdateSegmentInput!
    $where: SegmentWhereUniqueInput!
  ) {
    updateSegment(data: $data, where: $where) {
      message
    }
  }
`;
