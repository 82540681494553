import { Divider } from 'antd';
import React from 'react';
import '../modules/template/styles/section-title.less';

const SectionTitle = ({ title, className }) => (
  <div className={`custom-divider ${className}`}>
    {title && <span className="form-divider-text">{title}</span>}
    <Divider className="form-divider" />
  </div>
);

export default SectionTitle;
