import { useQuery } from '@apollo/client';
import { Button, Card, Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import {
  formValidatorRules,
  handleProtectedNavigation,
} from '../../common/utils';
import RouterPrompt from '../../components/RouterPrompt';
import useRouter from '../../hooks/useRouter';
import { GET_CURRENT_USER } from '../auth/graphql/Queries';
import ChangePassword from './ChangePassword';

const { name, email } = formValidatorRules;

function Profile() {
  const [form] = Form?.useForm();
  const { navigate } = useRouter();
  const { data, loading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const [isPrompt, setIsPrompt] = useState(false);
  const [changeModal, setChangeModal] = useState(false);

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const onFinish = async () => {
    //
  };

  return (
    <>
      {!loading && (
        <Form
          form={form}
          className="sticky-action-form"
          layout="vertical"
          initialValues={{
            ...data?.userProfile,
            name: `${data?.userProfile?.firstName || ''} ${
              data?.userProfile?.lastName || ''
            }`,
          }}
          onFinish={onFinish}
        >
          <Card className="ant-body-scroll" title="Profile">
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={10} xl={12}>
                  <Form.Item name="name" label="Name" rules={[name]}>
                    <Input placeholder="Enter First Name" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={10} xl={12}>
                  <Form.Item name="email" label="Email Id" rules={[email]}>
                    <Input disabled placeholder="Enter Email Id" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={10} xl={12}>
                  <Form.Item name="role" label="Role" rules={[name]}>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={10} xl={12}>
                  <Form.Item name="location" label="Location" rules={[name]}>
                    <Input placeholder="Enter Location" disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Button type="link" onClick={() => setChangeModal(true)}>
                Change password ?
              </Button>
            </div>
          </Card>
        </Form>
      )}
      <Modal open={changeModal} closable={false} footer={null}>
        <ChangePassword setChangeModal={setChangeModal} />
      </Modal>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}
export default Profile;
