import { gql } from '@apollo/client';

export const CREATE_CONTACT_ADMIN = gql`
  mutation CreateContactAdmin($data: CreateContactInputAdmin!) {
    createContactAdmin(data: $data) {
      message
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(data: $data) {
      message
    }
  }
`;

export const UPDATE_CONTACT_ADMIN = gql`
  mutation UpdateContactAdmin(
    $where: ContactWhereUniqueInput!
    $data: UpdateContactAdminInput!
  ) {
    updateContactAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $where: ContactWhereUniqueInput!
    $data: UpdateContactInput!
  ) {
    updateContact(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_BULK_CONTACT_ADMIN = gql`
  mutation UpdateBulkContactsAdmin(
    $where: BulkContactWhereInput!
    $data: UpdateBulkContactInput!
  ) {
    updateBulkContactsAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const DELETE_CONTACT_ADMIN = gql`
  mutation DeleteContactAdmin($where: ContactWhereUniqueInput!) {
    deleteContactAdmin(where: $where) {
      message
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($where: ContactWhereUniqueInput!) {
    deleteContact(where: $where) {
      message
    }
  }
`;

export const IMPORT_CONTACT = gql`
  mutation ImportContact($data: ImportContactInput) {
    importContact(data: $data) {
      message
    }
  }
`;
export const IMPORT_CONTACT_ADMIN = gql`
  mutation ImportContactAdmin($data: ImportContactInput) {
    importContactAdmin(data: $data) {
      message
    }
  }
`;
