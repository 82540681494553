import {
  ArrowLeftOutlined,
  CloseOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { omit } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  COUNTRY_CODE,
  GENDER,
  NAME_PREFIX,
  REGEX,
  STATUS,
} from '../../common/constants';
import {
  formValidatorRules,
  handleProtectedNavigation,
} from '../../common/utils';
import CommonDropdown from '../../components/CommonDropdown';
import LoaderComponent from '../../components/LoaderComponent';
import RouterPrompt from '../../components/RouterPrompt';
import SectionTitle from '../../components/SectionTitle';
import useGetRole from '../../hooks/useGetRole';
import useRouter from '../../hooks/useRouter';
import CitySelect from './components/CitySelect';
import CountrySelect from './components/CountrySelect';
import LocationSelect from './components/LocationSelect';
import OtherSpecializationModel from './components/OtherSpecializationModel';
import SpecializationSelect from './components/SpecializationSelect';
import StateSelect from './components/StateSelect';
import TagSelect from './components/TagSelect';
import {
  CREATE_CONTACT,
  CREATE_CONTACT_ADMIN,
  UPDATE_CONTACT,
  UPDATE_CONTACT_ADMIN,
} from './graphql/Mutations';
import {
  GET_CONTACT_ADMIN,
  GET_CONTACT_USER,
  SPECIALIZATION,
} from './graphql/Queries';

const { required } = formValidatorRules;

const Prefix = ({ name, disabled = false, initialValue, options, ...rest }) => (
  <Form.Item name={name} noStyle initialValue={initialValue}>
    <Select
      style={{
        width: 100,
      }}
      options={options}
      disabled={disabled}
      {...rest}
    />
  </Form.Item>
);

function ContactForm({ edit, dataLoading }) {
  const { getCurrentUser } = useContext(AppContext);
  const { defaultLocation, locations } = getCurrentUser() || null;

  const permission = useGetRole();
  const { navigate } = useRouter();
  const [form] = Form?.useForm();
  const countryId = Form.useWatch('countryId', form)?.value;
  const stateId = Form.useWatch('stateId', form)?.value;
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [isOtherSpecializationModel, setIsOtherSpecializationModel] = useState(
    false,
  );
  const dataKey = permission ? 'contactAdmin' : 'contact';

  const [createContact] = useMutation(
    permission ? CREATE_CONTACT_ADMIN : CREATE_CONTACT,
  );
  const [updateContact] = useMutation(
    permission ? UPDATE_CONTACT_ADMIN : UPDATE_CONTACT,
    {
      onError() {},
    },
  );

  const [getSpecialization] = useLazyQuery(SPECIALIZATION, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { loading: getContactLoading } = useQuery(
    permission ? GET_CONTACT_ADMIN : GET_CONTACT_USER,
    {
      skip: !edit,
      onCompleted: async (res) => {
        const formData = res?.[dataKey]?.data;
        form.setFieldsValue({
          ...formData,
          locationId: formData?.location
            ? {
                value: formData?.location?.id,
                label: formData?.location?.name,
              }
            : null,
          tags: formData?.tags?.map((tag) => ({
            lable: tag?.name,
            value: tag?.name,
          })),
          primaryContactNo: `******${formData?.primaryContactNoPostfix}`,
          secondaryContactNo: formData?.secondaryContactNo?.contactNoPostfix
            ? `******${formData?.secondaryContactNo?.contactNoPostfix}`
            : '',
          secondaryCountryCode: formData?.secondaryContactNo?.countryCode,
          primaryEmail: formData?.primaryEmailPrefix,
          countryId: formData?.country
            ? {
                value: formData?.country?.id,
                label: formData?.country?.name,
              }
            : null,
          stateId: formData?.state
            ? {
                value: formData?.state?.id,
                label: formData?.state?.name,
              }
            : null,
          cityId: formData?.city
            ? {
                value: formData?.city?.id,
                label: formData?.city?.name,
              }
            : null,
          optConsent: {
            value: formData?.optConsent,
            label: formData?.optConsent ? 'Opt-in' : 'Opt-out',
          },
          specializationIds: formData?.specialization
            ? {
                value: formData?.specialization?.[0]?.id,
                label: formData?.specialization?.[0]?.name,
              }
            : null,
          additionalInfo: Object?.entries(
            formData?.metaData,
          ).map(([key, value]) => ({ key, value })),
        });
      },
      variables: {
        where: {
          id: edit,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setIsSubmitDisabled(false);
    setShowPrompt(true);
  };

  const onFinish = async (value) => {
    const contactValue = {
      additionalInfo:
        value?.additionalInfo &&
        Object?.fromEntries(
          value?.additionalInfo.map((obj) => [obj?.key, obj?.value]),
        ),
      addressLine1: value?.addressLine1,
      addressLine2: value?.addressLine2,
      cityId: value?.cityId?.value,
      countryCode: value?.countryCode,
      countryId: value?.countryId?.value,
      gender: value?.gender,
      locationId: value?.locationId?.value,
      isActive: !!value?.isActive,
      notes: value?.notes,
      name: value?.name,
      optConsent: value?.optConsent?.value,
      otherSpecialization: value?.otherSpecialization,
      primaryContactNo: value?.primaryContactNo,
      primaryEmail: value?.primaryEmail,
      ...(value?.secondaryContactNo && {
        secondaryContactNo: {
          contactNo: value?.secondaryContactNo,
          countryCode: value?.secondaryCountryCode,
        },
      }),
      specializationIds: value?.specializationIds
        ? [value?.specializationIds?.value]
        : [],
      stateId: value?.stateId?.value,
      tags: value?.tags?.[0]?.value || value?.tags?.value || [],
      userId: value?.userId,
      zipCode: value?.zipCode,
      ...(value?.name && { namePrefix: value?.namePrefix }),
    };

    try {
      setIsSubmitDisabled(true);
      if (!edit) {
        await createContact({
          variables: {
            data: contactValue,
          },
        });
      } else {
        const editData = omit(contactValue, [
          'secondaryContactNo',
          'countryCode',
          'primaryContactNo',
          'primaryEmail',
          'secondaryCountryCode',
        ]);
        updateContact({
          variables: {
            where: {
              id: edit,
            },
            data: {
              ...editData,
            },
          },
        });
      }
    } catch (error) {
      //
    } finally {
      setIsSubmitDisabled(false);
      navigate(-1);
    }
  };

  const stateVariables = useMemo(() => ({ countryId }), [countryId]);
  const cityVariables = useMemo(() => ({ stateId }), [stateId]);
  const { Title } = Typography;
  if (dataLoading || getContactLoading) return <LoaderComponent />;
  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              onClick={handleBack}
              icon={<ArrowLeftOutlined />}
              disabled={isSubmitDisabled}
            />

            {edit ? 'Edit Contact' : 'Add Contact'}
          </Title>
        }
        actions={[
          <div key="action-button" className="text-right">
            <Space>
              <Button onClick={handleBack}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  form?.submit();
                }}
                disabled={isSubmitDisabled}
              >
                Submit
              </Button>
            </Space>
          </div>,
        ]}
        loading={getContactLoading}
      >
        <div className="card-body-wrapper">
          <Form
            name="detailsForm"
            form={form}
            onValuesChange={handleShowPrompt}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              locationId: defaultLocation?.id && {
                value: defaultLocation?.id,
                label: defaultLocation?.name,
              },
              optConsent: {
                label: 'Opt-in',
                value: true,
              },
              isActive: true,
            }}
          >
            <SectionTitle title="CONTACT DETAILS" />
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                <Form.Item
                  name="primaryContactNo"
                  label="Primary Contact"
                  rules={
                    !edit && [
                      {
                        required,
                        message: 'Please enter primary contact number!',
                      },
                    ]
                  }
                  normalize={(value) => value?.replace(/[^0-9]+/, '')}
                >
                  <Input
                    placeholder="Enter primary contact number"
                    addonBefore={
                      <Prefix
                        disable={edit}
                        name="countryCode"
                        options={COUNTRY_CODE}
                        initialValue="+91"
                      />
                    }
                    type="text"
                    maxLength={20}
                    disabled={edit}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                <Form.Item
                  name="secondaryContactNo"
                  label="Secondary Contact"
                  rules={
                    !edit && [
                      {
                        message: 'Please enter secondary contact number',
                      },
                    ]
                  }
                  normalize={(value) => value?.replace(/[^0-9]+/, '')}
                >
                  <Input
                    placeholder="Enter secondary contact number"
                    addonBefore={
                      <Prefix
                        name="secondaryCountryCode"
                        disable={edit}
                        options={COUNTRY_CODE}
                        initialValue="+91"
                      />
                    }
                    disabled={edit}
                    type="text"
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      message: 'Please select Gender!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Gender"
                    optionFilterProp="children"
                    options={GENDER}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                <Form.Item
                  name="primaryEmail"
                  label="Email address"
                  rules={
                    !edit
                      ? [
                          {
                            message: 'Please enter valid Email address!',
                            type: 'email',
                          },
                        ]
                      : []
                  }
                >
                  <Input placeholder="Enter email address" disabled={edit} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                <Form.Item
                  name="name"
                  label="Full Name"
                  rules={[
                    { message: 'Please enter Full Name!' },
                    {
                      pattern: REGEX?.NAME,
                      message:
                        'Please enter a valid Full Name (letters and spaces only)!',
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Full Name"
                    addonBefore={
                      <Prefix
                        name="namePrefix"
                        disable={edit}
                        options={NAME_PREFIX}
                        initialValue="DR"
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <SectionTitle title="ADDRESS" className="mt-16" />
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="addressLine1"
                  label="Hospital Address Line 1"
                  rules={[{ message: 'Please enter hospital address line 1!' }]}
                >
                  <Input placeholder="Enter Hospital Address Line 1" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <Form.Item
                  name="addressLine2"
                  label="Hospital Address Line 2"
                  rules={[{ message: 'Please enter hospital address line 2!' }]}
                >
                  <Input placeholder="Enter Hospital Address Line 2" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="countryId" label="Country">
                  <CountrySelect
                    onChange={(selectedCountry) => {
                      form.setFieldsValue({
                        countryId: selectedCountry,
                        stateId: null,
                        cityId: null,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="stateId" label="State">
                  <StateSelect
                    disabled={!countryId}
                    variables={stateVariables}
                    queryOptions={{
                      skip: !countryId,
                    }}
                    onChange={(selectedState) => {
                      form.setFieldsValue({
                        stateId: selectedState,
                        cityId: null,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="cityId" label="City">
                  <CitySelect
                    disabled={!stateId}
                    variables={cityVariables}
                    queryOptions={{
                      skip: !stateId,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="zipCode"
                  label="ZIP code"
                  rules={[
                    {
                      message: 'Please enter ZIP code!',
                    },
                  ]}
                >
                  <Input placeholder="Enter ZIP code" type="number" />
                </Form.Item>
              </Col>
            </Row>
            <SectionTitle title="OTHERS" className="mt-16" />
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="specializationIds" label="Specialization">
                  <SpecializationSelect
                    onChange={async (value) => {
                      if (value) {
                        const { data } = await getSpecialization({
                          variables: {
                            where: {
                              id: value?.key,
                            },
                          },
                        });
                        if (data && data.specialization.key === 'OTHER') {
                          setIsOtherSpecializationModel(true);
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <OtherSpecializationModel
                showModal={isOtherSpecializationModel}
                handleOk={() => setIsOtherSpecializationModel(false)}
                onCancel={() => {
                  setIsOtherSpecializationModel(false);
                  form.setFieldValue('specializationIds', null);
                }}
              />
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="tags" label="Tags" style={{ width: '100%' }}>
                  <TagSelect />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item name="isActive" label="Status">
                  <Select
                    placeholder="Select Status"
                    optionFilterProp="children"
                    options={STATUS}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <Form.Item
                  name="locationId"
                  label="Location"
                  rules={[
                    {
                      required: true,
                      message: 'Please select Location!',
                    },
                  ]}
                >
                  {!permission ? (
                    <CommonDropdown
                      showSearch
                      placeholder="Select Location"
                      optionFilterProp="children"
                      list={locations?.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))}
                    />
                  ) : (
                    <LocationSelect locations={locations} />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8} xl={6} xxl={6}>
                <Form.Item name="optConsent" label="Opt Consent">
                  <CommonDropdown
                    placeholder="Select opt consent"
                    optionFilterProp="children"
                    list={[
                      {
                        label: 'Opt-in',
                        value: true,
                      },
                      {
                        label: 'Opt-out',
                        value: false,
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  name="notes"
                  label="Notes"
                  rules={[
                    {
                      message: 'Please enter Notes!',
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item label="Metadata">
                  <Form.List name="additionalInfo">
                    {(subFields, subOpt) => (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: 16,
                        }}
                        className="dynamic-input"
                      >
                        {subFields?.map((subField, index) => (
                          <Space key={subField?.key}>
                            <Form.Item noStyle name={[subField?.name, 'key']}>
                              <Input placeholder="key" />
                            </Form.Item>
                            <Form.Item noStyle name={[subField?.name, 'value']}>
                              <Input placeholder="Value" />
                            </Form.Item>
                            {index !== 0 && (
                              <CloseOutlined
                                onClick={() => {
                                  subOpt?.remove(subField?.name);
                                }}
                              />
                            )}
                          </Space>
                        ))}
                        <Button
                          type="dashed"
                          onClick={() => subOpt?.add()}
                          block
                        >
                          <PlusCircleOutlined /> Add
                        </Button>
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}

export default ContactForm;
