import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { ROLE_KEYS } from '../common/constants';

const useGetRole = () => {
  const {
    state: {
      currentUser: { role },
    },
  } = useContext(AppContext);

  return role !== ROLE_KEYS?.USER;
};

export default useGetRole;
