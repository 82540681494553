import { gql } from '@apollo/client';

export const CREATE_TAG = gql`
  mutation CreateTag($data: CreateTagInput!) {
    createTag(data: $data) {
      message
    }
  }
`;

export const UPDATE_TAG = gql`
  mutation UpdateTag($data: UpdateTagInput!, $where: TagWhereUniqueInput!) {
    updateTag(data: $data, where: $where) {
      message
    }
  }
`;

export const TOGGLE_TAG_STATUS = gql`
  mutation ToggleTagStatus(
    $data: ToggleTagStatus!
    $where: TagWhereUniqueInput!
  ) {
    toggleTagStatus(data: $data, where: $where) {
      message
    }
  }
`;
