import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Card, Form, Tooltip, Typography } from 'antd';
import Modal from 'antd/es/modal/Modal';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import DeleteIcon from '../../assets/icon/DeleteIcon';
import EditPen from '../../assets/icon/EditPen';
import {
  DATE_FORMATS,
  MODULES,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROLE_KEYS,
  ROUTES,
  SEGMENT_ATTRIBUTES,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useVerifyPermissions from '../../hooks/useVerifyPermissions';
import { COUNTRIES_LIST } from '../contacts/graphql/Queries';
import SegmentModalForm from './SegmentModalForm';
import { DELETE_ADMIN_SEGMENT, DELETE_USER_SEGMENT } from './graphql/Mutations';
import {
  GET_ADMIN_SEGMENT_LISTING,
  GET_COUNTRY_BY_STATE,
  GET_USER_SEGMENT_LISTING,
} from './graphql/Queries';

const { Title } = Typography;

function SegmentListing() {
  const { getCurrentUser } = useContext(AppContext);
  const { role } = getCurrentUser();
  const [form] = Form.useForm();
  const permission = role === ROLE_KEYS.SUPER_ADMIN || role === ROLE_KEYS.ADMIN;
  const { navigate } = useRouter();
  const [isPrompt, setIsPrompt] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isEdit, setIsEdit] = useState();
  const [showOuterList, setOuterShowForm] = useState(true);
  const [deleteId, setDeleteId] = useState();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [loading, setLoading] = useState(true);

  const dataKey = permission ? 'segmentsAdmin' : 'segments';

  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    search: '',
    currentPage: 0,
  });
  const [listSort, setListSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });
  const segmentAddPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.SEGMENT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.CREATE,
  });
  const segmentEditPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.SEGMENT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.UPDATE,
  });

  const segmentDeletePermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.SEGMENT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.DELETE,
  });

  const {
    loading: adminFetchLoading,
    data: adminListingData,
    refetch: adminListingRefetch,
  } = useQuery(
    permission ? GET_ADMIN_SEGMENT_LISTING : GET_USER_SEGMENT_LISTING,
    {
      variables: {
        sort: {
          sortOn: listSort?.sortOn,
          sortBy: listSort?.sortBy,
        },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
          search: paginationConfig?.search,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [deleteAdminSegment] = useMutation(
    permission ? DELETE_ADMIN_SEGMENT : DELETE_USER_SEGMENT,
    {
      onError() {},
    },
  );

  const handleSearchChange = (text) => {
    setPaginationConfig({ search: text });
  };

  const handleOk = async () => {
    try {
      await deleteAdminSegment({ variables: { where: { id: deleteId } } });
    } catch (error) {
      return error;
    } finally {
      setIsModal(false);
      await adminListingRefetch();
    }
  };

  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));

    setPaginationConfig((prev) => ({
      ...prev,
      limit: pagination?.limit,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const [dynamicInputData, setDynamicInputData] = useState({});

  useQuery(COUNTRIES_LIST, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicInputData((prev) => ({
        ...prev,
        countries: res?.countries?.data.map((items) => ({
          label: items?.name,
          value: items?.id,
        })),
      }));
    },
    onError() {},
  });

  const [countryByState] = useLazyQuery(GET_COUNTRY_BY_STATE, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const formatConditions = async (values) => {
    const setValues = await Promise.all(
      values?.conditions?.map(async (items) => ({
        conditions: await Promise.all(
          items?.conditions?.map(async ({ operator, attribute, value }) => {
            if (attribute === SEGMENT_ATTRIBUTES.STATE) {
              const res = await countryByState({
                variables: {
                  where: {
                    stateId: value,
                  },
                },
              });

              if (res?.data?.state?.country?.id) {
                setSelectedCountry(res?.data?.state?.country?.id);
              }

              return {
                id: attribute,
                country: res?.data?.state?.country?.id,
                operator,
                value,
              };
            }

            return {
              id: attribute,
              operator,
              value:
                attribute === SEGMENT_ATTRIBUTES.CREATED_AT
                  ? dayjs(value)
                  : value,
            };
          }),
        ),
      })),
    );

    return setValues;
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
    },
    {
      title: 'Total Contact',
      dataIndex: 'totalContacts',
      key: 'totalContacts',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      align: 'left',
      render: (value) => dayjs(value)?.format(DATE_FORMATS?.REGULAR),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      render: (value) => dayjs(value)?.format(DATE_FORMATS.WITH_TIME),
      sorter: true,
      defaultSortOrder: 'descend',
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'center',
      align: 'center',
      width: '9%',
      render: (_, rowValue) => (
        <div className="action-button d-flex justify-end">
          {segmentEditPermission && (
            <Tooltip title="Edit">
              <Button
                type="text"
                onClick={async (e) => {
                  e.stopPropagation();
                  setLoading(true);
                  setIsPrompt(true);
                  setIsEdit(rowValue?.id);
                  setOuterShowForm(false);

                  const conditions = await formatConditions(rowValue);

                  form.setFieldsValue({
                    name: rowValue?.name,
                    description: rowValue?.description,
                    conditions,
                  });
                }}
                icon={<EditPen />}
              />
            </Tooltip>
          )}
          {segmentDeletePermission && (
            <Tooltip title="Delete">
              <Button
                type="text"
                danger
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteId(rowValue?.id);
                  setIsModal(true);
                }}
                icon={<DeleteIcon />}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  const handleClose = () => {
    setIsPrompt(false);
    setIsModal(false);
    setIsEdit(false);
  };
  const handleRowClick = (record) => {
    const id = record?.id || ':id';
    const handleRow = () => {
      navigate(`${ROUTES.SEGMENTS}/view/${id}`);
    };
    return {
      onClick: handleRow,
    };
  };

  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.SEGMENTS}
          <Badge
            count={adminListingData?.[dataKey]?.count
              ?.toString()
              ?.padStart(2, '0')}
          />
        </div>
        {segmentAddPermission && (
          <Button
            className="ml-8"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setLoading(false);
              setIsPrompt(true);
              setIsEdit(false);
              setSelectedCountry(null);
            }}
          >
            Add Segment
          </Button>
        )}
      </Title>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="contact-sidebar">
              <div className="contact-filter-left" />
              <div className="contact-filter-right">
                <div className="contact-filter">
                  <SearchComponent getData={handleSearchChange} />
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            className="pointer"
            columns={columns}
            loadingData={adminFetchLoading}
            data={adminListingData?.[dataKey]?.segments}
            onRow={handleRowClick}
            onChange={handleTableChange}
            paginationConfig={{
              ...paginationConfig,
              total: adminListingData?.segmentsAdmin?.count,
              showSizeChanger: false,
            }}
          />
        </div>
        <Modal
          open={isModal}
          closable={false}
          onOk={handleOk}
          onCancel={handleClose}
          title="Delete Segment"
          okText="Yes"
          cancelText="No"
        >
          Are you sure you want to delete this segment?
        </Modal>
        <SegmentModalForm
          loading={loading}
          setLoading={setLoading}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          dynamicInputData={dynamicInputData}
          setDynamicInputData={setDynamicInputData}
          isPrompt={isPrompt}
          handleClose={handleClose}
          isEdit={isEdit}
          setOuterShowForm={setOuterShowForm}
          showOuterList={showOuterList}
          setIsPrompt={setIsPrompt}
          adminListingRefetch={adminListingRefetch}
          form={form}
        />
      </Card>
    </>
  );
}

export default SegmentListing;
