import { gql } from '@apollo/client';

export const GET_ADMIN_CAMPAIGN_LISTING = gql`
  query CampaignsAdmin($filter: CampaignsFilter, $sort: CampaignsSort) {
    campaignsAdmin(filter: $filter, sort: $sort) {
      campaigns {
        id
        name
        totalMessages
        createdAt
        updatedAt
        createdBy
        creator {
          firstName
          lastName
          id
        }
      }
      count
    }
  }
`;

export const GET_ADMIN_CAMPAIGN = gql`
  query CampaignAdmin($where: CampaignWhereUniqueInput!) {
    campaignAdmin(where: $where) {
      createdAt
      createdBy
      id
      name
      updatedAt
      totalMessages
      creator {
        id
        lastName
        firstName
      }
    }
  }
`;

export const GET_USER_CAMPAIGN = gql`
  query Campaign($where: CampaignWhereUniqueInput!) {
    campaign(where: $where) {
      createdAt
      createdBy
      creator {
        id
        firstName
        lastName
      }
      id
      name
      totalMessages
      updatedAt
    }
  }
`;

export const GET_USER_CAMPAIGN_LISTING = gql`
  query Campaigns($filter: CampaignsFilter, $sort: CampaignsSort) {
    campaigns(filter: $filter, sort: $sort) {
      campaigns {
        createdAt
        createdBy
        id
        name
        totalMessages
        updatedAt
        creator {
          id
          lastName
          firstName
        }
      }
      count
    }
  }
`;

export const GET_CAMPAIGN_MESSAGE_ADMIN = gql`
  query MessagesAdmin(
    $where: CampaignUniqueInput!
    $filter: MessageFilterInput
    $sort: MessageSortInput
  ) {
    messagesAdmin(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        id
        status
        segment {
          creator {
            firstName
          }
          name
        }
        updatedAt
        timezone {
          shortCode
          city
          isActive
          updatedAt
        }
        template {
          category
          name
          components {
            text
            type
          }
        }
        scheduledDate
        contactConditions {
          conditions {
            attribute
            operator
            value
          }
          logicalOperator
        }
        totalContacts
      }
    }
  }
`;

export const GET_CAMPAIGN_MESSAGE_USER = gql`
  query Messages(
    $where: CampaignUniqueInput!
    $filter: MessageFilterInput
    $sort: MessageSortInput
  ) {
    messages(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        id
        status
        segment {
          creator {
            firstName
          }
        }
        updatedAt
        timezone {
          shortCode
          city
          isActive
          updatedAt
        }
        template {
          components {
            text
            type
          }
          id
        }
        scheduledDate
        contactConditions {
          conditions {
            attribute
            operator
            value
          }
          logicalOperator
        }
        totalContacts
      }
    }
  }
`;

export const GET_ADMIN_TEMPLATES = gql`
  query TemplatesAdmin($filter: TemplatesFilter) {
    templatesAdmin(filter: $filter) {
      count
      templates {
        id
        name
        status
      }
    }
  }
`;

export const GET_USER_TEMPLATES = gql`
  query Templates($filter: TemplatesFilter) {
    templates(filter: $filter) {
      count
      templates {
        id
        name
        status
      }
    }
  }
`;

export const GET_TIMEZONE = gql`
  query ActiveTimezones {
    activeTimezones {
      count
      data {
        id
        city
        shortCode
        offset
        remark
        isActive
      }
    }
  }
`;

export const GET_ADMIN_SEGMENTS = gql`
  query SegmentsAdmin($filter: SegmentsFilter) {
    segmentsAdmin(filter: $filter) {
      segments {
        id
        name
        totalContacts
      }
      count
    }
  }
`;

export const GET_SEGMENTS = gql`
  query Segments {
    segments {
      segments {
        id
        name
      }
    }
  }
`;

export const GET_ADMIN_MESSAGE = gql`
  query MessageAdmin($where: MessageUniqueInput!) {
    messageAdmin(where: $where) {
      message
      data {
        id
        scheduledDate
        segment {
          id
          name
        }
        status
        template {
          id
          name
        }
        timezone {
          id
          city
          shortCode
          remark
          offset
        }
        contactConditions {
          conditions {
            attribute
            operator
            value
          }
        }
      }
    }
  }
`;

export const GET_USER_MESSAGE = gql`
  query Message($where: MessageUniqueInput!) {
    message(where: $where) {
      message
      data {
        id
        scheduledDate
        segment {
          id
          name
          conditions {
            conditions {
              attribute
              operator
              value
            }
          }
        }
        status
        template {
          id
        }
        timezone {
          id
          city
        }
        contactConditions {
          conditions {
            attribute
            operator
            value
          }
        }
      }
    }
  }
`;

export const GET_MESSAGE_ADMIN_SEGMET_DETAILS = gql`
  query MessageAdmin($where: MessageUniqueInput!) {
    messageAdmin(where: $where) {
      message
      data {
        segment {
          name
          id
        }
        contactConditions {
          conditions {
            operator
            attribute
            value
          }
          logicalOperator
        }
        id
      }
    }
  }
`;

export const GET_SEGMENT_MESSAGE_CONTACT_LIST = gql`
  query SegmentContacts(
    $where: SegmentContactWhereUniqueInput!
    $sort: ContactsSort
    $filter: ContactsFilter
  ) {
    segmentContacts(where: $where, sort: $sort, filter: $filter) {
      data {
        name
        primaryContactNo
        primaryContactNoPostfix
        id
      }
      count
    }
  }
`;

export const GET_SEGMENT_MESSAGE_CONTACT_COUNT = gql`
  query SegmentContacts($where: SegmentContactWhereUniqueInput!) {
    segmentContacts(where: $where) {
      count
    }
  }
`;

export const GET_MESSAGE_SENT_COUNT = gql`
  query GetMessageStatusCount($where: MessageUniqueInput!) {
    getMessageStatusCount(where: $where) {
      delivered
      failed
      pending
      read
      send
    }
  }
`;

export const GET_MESSAGE_CAMP_NAME_ADMIN = gql`
  query CampaignAdmin($where: CampaignWhereUniqueInput!) {
    campaignAdmin(where: $where) {
      name
      id
    }
  }
`;

export const GET_MESSAGE_CAMP_NAME = gql`
  query Campaign($where: CampaignWhereUniqueInput!) {
    campaign(where: $where) {
      name
      id
    }
  }
`;

export const GET_MESSAGE_ADMIN_TEMPLATE = gql`
  query MessageTemplatesAdmin($filter: MessageTemplatesFilter) {
    messageTemplatesAdmin(filter: $filter) {
      templates {
        id
        name
      }
      count
    }
  }
`;

export const GET_MESSAGE_TEMPLATE = gql`
  query MessageTemplates($filter: MessageTemplatesFilter) {
    messageTemplates(filter: $filter) {
      templates {
        id
        name
      }
      count
    }
  }
`;

export const GET_CAMPAIGN_MESSAGE_ANALYTICS_ADMIN = gql`
  query campaignAnalyticsAdmin(
    $where: MessageUniqueInput!
    $filter: CampaignAnalyticsFilter
    $sort: CampaignsSort
  ) {
    campaignAnalyticsAdmin(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        id
        attempted
        sent
        delivered
        read
        replied
        failed
        pending
        optOut
        updatedAt

        template {
          id
          name
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_MESSAGE_ANALYTICS_USER = gql`
  query CampaignAnalytics(
    $where: MessageUniqueInput!
    $sort: CampaignsSort
    $filter: CampaignAnalyticsFilter
  ) {
    campaignAnalytics(where: $where, sort: $sort, filter: $filter) {
      count
      data {
        id
        template {
          name
          id
        }
        attempted
        sent
        delivered
        read
        replied
        failed
        pending
        optOut
        updatedAt
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query Location($where: LocationWhereUniqueInput!) {
    location(where: $where) {
      id
      name
      key
    }
  }
`;

export const GET_SPECIALIZATION = gql`
  query Specialization($where: SpecializationWhereUniqueInput!) {
    specialization(where: $where) {
      id
      key
      name
    }
  }
`;

export const GET_TAG = gql`
  query Tag($where: TagWhereUniqueInput!) {
    tag(where: $where) {
      id
      key
      name
    }
  }
`;
