import { DownOutlined, IdcardOutlined, LoginOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Space } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import MapPin from '../../../assets/icon/MapPin';
import { ROUTES } from '../../../common/constants';
import CommonDropdown from '../../../components/CommonDropdown';
import useGetRole from '../../../hooks/useGetRole';
import useRouter from '../../../hooks/useRouter';
import LocationSelect from '../../../modules/contacts/components/LocationSelect';

const UserProfile = () => {
  const { getCurrentUser, dispatch, isLocation, setIsLocation } = useContext(
    AppContext,
  );
  const permission = useGetRole();
  const {
    location: { pathname },
  } = useRouter();
  const { firstName = '', lastName = '', locations, defaultLocation } =
    getCurrentUser() || {};

  const items = [
    {
      key: 'profile',
      label: <Link to={ROUTES.PROFILE}>My Profile</Link>,
      icon: <IdcardOutlined />,
    },
    {
      key: 'logout',
      label: (
        <div
          onClick={() => {
            dispatch({ type: 'LOGOUT' });
            // eslint-disable-next-line no-undef
            window.location = ROUTES.LOGIN;
          }}
        >
          Logout
        </div>
      ),
      icon: <LoginOutlined />,
    },
  ];
  useEffect(() => {
    if (defaultLocation) {
      setIsLocation(true);
    }
  }, [defaultLocation]);
  return (
    <Space>
      {!permission ? (
        <CommonDropdown
          list={locations?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))}
          suffixIcon={<MapPin />}
          status={!isLocation && 'error'}
          placeholder="Select default location"
          defaultValue={
            (defaultLocation?.id || defaultLocation?.value) && {
              label: defaultLocation?.name,
              value: defaultLocation?.value || defaultLocation?.id,
            }
          }
          onChange={(value) => {
            dispatch({
              type: 'SET_CURRENT_USER',
              data: {
                ...getCurrentUser(),
                defaultLocation: value?.value
                  ? {
                      id: value?.value,
                      name: value?.label,
                      value: value?.value,
                    }
                  : null,
              },
            });
          }}
        />
      ) : (
        <LocationSelect
          minWidth="200px"
          allowClear
          defaultValue={
            (defaultLocation?.id || defaultLocation?.value) && {
              label: defaultLocation?.name,
              value: defaultLocation?.value || defaultLocation?.id,
            }
          }
          locations={locations}
          placeholder="Select default location"
          suffixIcon={<MapPin />}
          status={!isLocation && 'error'}
          onChange={(value) => {
            dispatch({
              type: 'SET_CURRENT_USER',
              data: {
                ...getCurrentUser(),
                defaultLocation: value?.value
                  ? {
                      id: value?.value,
                      name: value?.label,
                      value: value?.value,
                    }
                  : null,
              },
            });
          }}
        />
      )}

      <Dropdown
        menu={{ items, selectedKeys: [`${pathname?.split('/')?.[1]}`] }}
        trigger={['click']}
      >
        <Space className="pointer d-flex align-center gap-4" size="small">
          <Avatar className="header-avatar" alt="Avatar">{`${
            firstName ? firstName?.[0] : ''
          }${lastName ? lastName?.[0] : ''}`}</Avatar>
          <span className="m-hide">{`${firstName || ''} ${
            lastName || ''
          }`}</span>
          <DownOutlined />
        </Space>
      </Dropdown>
    </Space>
  );
};

export default UserProfile;
