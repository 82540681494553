import { gql } from '@apollo/client';

export const GET_TEMPLATE_LANGUAGES = gql`
  query Languages {
    activeLanguages {
      languages {
        id
        name
        isActive
        code
      }
    }
  }
`;

export const GET_ADMIN_TEMPLATEAS = gql`
  query TemplatesAdmin($filter: TemplatesFilter, $sort: TemplatesSort) {
    templatesAdmin(filter: $filter, sort: $sort) {
      count
      templates {
        category
        components {
          text
          format
          type
          placeholder {
            bodyText {
              field
              order
              text
            }
          }
        }
        id
        creator {
          lastName
          firstName
          id
        }
        createdBy
        languageId
        name
        status
        language {
          name
          id
        }
        updatedAt
        dailyEditsCount
        monthlyEditsCount
        statusMetaData
      }
    }
  }
`;

export const GET_USER_TEMPLATEAS = gql`
  query Templates($filter: TemplatesFilter, $sort: TemplatesSort) {
    templates(filter: $filter, sort: $sort) {
      count
      templates {
        category
        components {
          text
          format
          type
          placeholder {
            bodyText {
              field
              order
              text
            }
          }
        }
        id
        creator {
          lastName
          firstName
          id
        }
        createdBy
        languageId
        name
        status
        language {
          name
          id
        }
        updatedAt
        dailyEditsCount
        monthlyEditsCount
      }
    }
  }
`;

export const GET_TEMPLATE_ADMIN = gql`
  query Query($where: TemplateWhereUniqueInput!) {
    templateAdmin(where: $where) {
      id
      name
      category
      languageId
      language {
        id
      }
      status
      components {
        buttons {
          order
          phoneNumber
          example
          text
          type
          url
        }
        format
        id
        media {
          key
          url
        }
        placeholder {
          bodyText {
            order
            field
            text
          }
          headerText {
            field
            text
            order
          }
        }
        text
        type
      }
      createdBy
      dailyEditsCount
      monthlyEditsCount
      statusMetaData
      createdAt
      updatedAt
    }
  }
`;

export const GET_TEMPLATE = gql`
  query Template($where: TemplateWhereUniqueInput!) {
    template(where: $where) {
      id
      name
      category
      languageId
      language {
        id
      }
      status
      components {
        buttons {
          order
          phoneNumber
          example
          text
          type
          url
        }
        format
        id
        media {
          key
          url
        }
        placeholder {
          bodyText {
            order
            field
            text
          }
          headerText {
            field
            text
            order
          }
        }
        text
        type
      }
      createdBy
      dailyEditsCount
      monthlyEditsCount
      statusMetaData
      createdAt
      updatedAt
    }
  }
`;

export const GET_TEMPLATE_HEADER_SIGN_URL = gql`
  query GetTemplateHeaderSignedPutUrl($data: TemplateHeaderSignedPutUrlInput!) {
    getTemplateHeaderSignedPutUrl(data: $data) {
      key
      url
    }
  }
`;
