import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_USERS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      users {
        email
        firstName
        lastName
        role
        locations {
          name
          key
          id
        }
        forcePasswordReset
        id
        roles {
          id
          key
          name
          permissions {
            access {
              create
              delete
              read
              update
            }
            module
          }
        }
      }
      count
    }
  }
`;

export const GET_USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      firstName
      lastName
      email
      roles {
        key
        name
        id
      }
      locations {
        key
        name
        id
      }
    }
  }
`;

export const GET_ROLES = gql`
  query Roles {
    roles {
      roles {
        name
        id
        key
      }
    }
  }
`;

export const GET_LOCATIONS = gql`
  query ActiveLocations {
    activeLocations {
      locations {
        name
        id
        key
      }
    }
  }
`;

export const GET_ACTIVE_ROLES = gql`
  query ActiveRoles {
    activeRoles {
      count
      roles {
        id
        key
        name
      }
    }
  }
`;
