import { Button } from 'antd';
import React from 'react';

const CommonButton = ({ icon, shape = 'round', label = '', ...rest }) => (
  <>
    <Button icon={icon} shape={shape} {...rest}>
      {label}
    </Button>
  </>
);

export default CommonButton;
