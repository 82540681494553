import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import MasterForm from '../components/MasterForm';
import MasterFormTitle from '../components/MasterFormTitle';
import { UPDATE_SPECIALIZATION } from './graphql/Mutations';
import { GET_SPECIALIZATION } from './graphql/Queries';

function EditSpecialization() {
  const { id } = useParams();
  const { navigate } = useRouter();
  const { data: specializationData, loading: fetchLoading } = useQuery(
    GET_SPECIALIZATION,
    {
      variables: { where: { id } },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateSpecialization, { loading: updateLoading }] = useMutation(
    UPDATE_SPECIALIZATION,
    {
      onCompleted() {
        navigate(ROUTES.SPECIALIZATION);
      },
    },
  );

  return (
    <>
      <MasterForm
        title={
          <MasterFormTitle
            navigation={ROUTES.SPECIALIZATION}
            title={FORM_TYPE.specialization}
            isEdit
          />
        }
        formType={FORM_TYPE.specialization}
        isEdit
        isView={false}
        formData={specializationData}
        loading={fetchLoading || updateLoading}
        mutation={updateSpecialization}
      />
    </>
  );
}

export default EditSpecialization;
