const { gql } = require('@apollo/client');

export const GET_ADMIN_SEGMENT_LISTING = gql`
  query SegmentsAdmin($sort: SegmentsSort, $filter: SegmentsFilter) {
    segmentsAdmin(sort: $sort, filter: $filter) {
      count
      segments {
        conditions {
          conditions {
            attribute
            operator
            value
          }
        }
        createdAt
        createdBy
        creator {
          id
          firstName
          lastName
        }
        description
        id
        name
        totalContacts
        updatedAt
      }
    }
  }
`;

export const GET_USER_SEGMENT_LISTING = gql`
  query Segments($sort: SegmentsSort, $filter: SegmentsFilter) {
    segments(sort: $sort, filter: $filter) {
      count
      segments {
        conditions {
          conditions {
            attribute
            operator
            value
          }
        }
        createdAt
        createdBy
        creator {
          id
          firstName
          lastName
        }
        description
        id
        name
        totalContacts
        updatedAt
      }
    }
  }
`;

export const GET_ADMIN_SEGMENT = gql`
  query SegmentAdmin($where: SegmentWhereUniqueInput!) {
    segmentAdmin(where: $where) {
      conditions {
        conditions {
          attribute
          operator
          value
        }
      }
      createdAt
      createdBy
      creator {
        id
        firstName
        lastName
      }
      description
      id
      name
      totalContacts
      updatedAt
    }
  }
`;

export const GET_SEGMENT_CONTACTS = gql`
  query SegmentContacts(
    $where: SegmentContactWhereUniqueInput!
    $filter: ContactsFilter
    $sort: ContactsSort
  ) {
    segmentContacts(where: $where, filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        optConsent
        primaryContactNo
        primaryContactNoPostfix
        countryCode
        namePrefix
      }
      optOutContacts
    }
  }
`;

export const GET_USER_SEGMENT = gql`
  query Segment($where: SegmentWhereUniqueInput!) {
    segment(where: $where) {
      id
      conditions {
        conditions {
          attribute
          operator
          value
        }
      }
      createdAt
      createdBy
      creator {
        firstName
        id
        lastName
      }
      description
      name
      totalContacts
      updatedAt
    }
  }
`;

export const GET_COUNTRY_CODE = gql`
  query Countries {
    countries {
      data {
        id
        name
        phoneCode
      }
    }
  }
`;

export const GET_ACTIVE_LOCATIONS = gql`
  query ActiveLocations {
    activeLocations {
      locations {
        id
        name
        key
      }
    }
  }
`;

export const GET_ACTIVE_SPECIALIZATIONS = gql`
  query ActiveSpecializations {
    activeSpecializations {
      count
      specializations {
        id
        key
        name
      }
    }
  }
`;

export const GET_ACTIVE_TAGS = gql`
  query Tags {
    activeTags {
      tags {
        id
        key
        name
      }
      count
    }
  }
`;

export const GET_COUNTRY_BY_STATE = gql`
  query State($where: StateUniqueInput!) {
    state(where: $where) {
      country {
        id
        name
      }
      id
      name
    }
  }
`;
