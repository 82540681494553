import { gql } from '@apollo/client';

export const CONTACTS_ADMIN = gql`
  query ContactsAdmin($filter: ContactsFilter, $sort: ContactsSort) {
    contactsAdmin(filter: $filter, sort: $sort) {
      data {
        name
        id
        primaryContactNoPostfix
        primaryEmailPrefix
        specialization {
          id
          key
          name
          isActive
        }
        optConsent
        tags {
          id
          isActive
          key
          name
        }
        createdAt
        otherSpecialization
        updatedAt
        namePrefix
      }
      count
    }
  }
`;

export const CONTACTS_USER = gql`
  query Contacts($filter: ContactsFilter, $sort: ContactsSort) {
    contacts(filter: $filter, sort: $sort) {
      data {
        name
        id
        primaryContactNoPostfix
        primaryEmailPrefix
        specialization {
          id
          key
          name
          isActive
        }
        optConsent
        tags {
          id
          isActive
          key
          name
        }
        createdAt
        otherSpecialization
      }
      count
    }
  }
`;

export const TAG_LIST = gql`
  query Tags {
    tags {
      tags {
        id
        name
        key
      }
    }
  }
`;

export const ACTIVE_TAG_LIST = gql`
  query ActiveTags($filter: ActiveTagsFilter) {
    activeTags(filter: $filter) {
      count
      tags {
        id
        key
        name
      }
    }
  }
`;

export const SEGMENTS_LIST = gql`
  query Segments {
    segments {
      segments {
        id
        name
      }
    }
  }
`;

export const SPECIALIZATIONS_LIST = gql`
  query Specializations {
    specializations {
      specializations {
        id
        key
        name
      }
    }
  }
`;

export const ACTIVE_SPECIALIZATIONS_LIST = gql`
  query ActiveSpecializations($filter: ActiveSpecializationsFilter) {
    activeSpecializations(filter: $filter) {
      count
      specializations {
        id
        key
        name
      }
    }
  }
`;

export const SPECIALIZATION = gql`
  query Specialization($where: SpecializationWhereUniqueInput!) {
    specialization(where: $where) {
      key
      name
    }
  }
`;

export const ACTIVE_LOCATIONS_LIST = gql`
  query ActiveLocations($filter: ActiveLocationsFilter) {
    activeLocations(filter: $filter) {
      locations {
        key
        id
        name
      }
      count
    }
  }
`;

export const CITIES_LIST = gql`
  query Cities($where: StateUniqueInput, $filter: CitiesStatesFilter) {
    cities(where: $where, filter: $filter) {
      data {
        name
        id
      }
      count
    }
  }
`;

export const STATES_LIST = gql`
  query States($where: CountryUniqueInput!, $filter: CitiesStatesFilter) {
    states(where: $where, filter: $filter) {
      count
      data {
        name
        id
      }
    }
  }
`;

export const COUNTRIES_LIST = gql`
  query Countries($filter: CitiesStatesFilter) {
    countries(filter: $filter) {
      count
      data {
        id
        name
        phoneCode
      }
    }
  }
`;

export const GET_CONTACT_ADMIN = gql`
  query ContactAdmin($where: ContactWhereUniqueInput!) {
    contactAdmin(where: $where) {
      data {
        id
        name
        primaryEmail
        secondaryEmail {
          emailPrefix
        }
        primaryEmailPrefix
        primaryContactNo
        secondaryContactNo {
          countryCode
          contactNoPostfix
        }
        primaryContactNoPostfix
        gender
        addressLine1
        addressLine2
        zipCode
        city {
          name
          id
        }
        state {
          name
          id
        }
        country {
          name
          id
        }
        countryCode
        metaData
        notes
        location {
          key
          name
          id
        }
        creator {
          id
          firstName
          email
        }
        specialization {
          id
          name
          key
        }
        otherSpecialization
        assignee {
          id
          email
          firstName
        }
        source
        optConsent
        isActive
        createdAt
        updatedAt
        tags {
          id
          name
          key
        }
        namePrefix
      }
    }
  }
`;

export const GET_CONTACT_USER = gql`
  query Contact($where: ContactWhereUniqueInput!) {
    contact(where: $where) {
      data {
        id
        name
        primaryEmail
        secondaryEmail {
          emailPrefix
        }
        primaryEmailPrefix
        primaryContactNo
        secondaryContactNo {
          countryCode
          contactNoPostfix
        }
        primaryContactNoPostfix
        gender
        addressLine1
        addressLine2
        zipCode
        city {
          name
          id
        }
        state {
          name
          id
        }
        country {
          name
          id
        }
        countryCode
        metaData
        notes
        location {
          key
          name
          id
        }
        creator {
          id
          firstName
          email
        }
        specialization {
          id
          name
          key
        }
        otherSpecialization
        assignee {
          id
          email
          firstName
        }
        source
        optConsent
        isActive
        createdAt
        updatedAt
        tags {
          id
          name
          key
        }
        namePrefix
      }
    }
  }
`;

export const LIST_GENRES = gql`
  query {
    listGenre {
      id
      name
    }
  }
`;

export const COUNTRIES = gql`
  query countries($filter: CountriesFilter) {
    countries(filter: $filter) {
      count
      data {
        id
        countryCode
        englishName
      }
    }
  }
`;

export const LANGUAGES = gql`
  query languages($filter: LanguagesFilter) {
    languages(filter: $filter) {
      count
      data {
        id
        languageCode
        englishName
      }
    }
  }
`;

export const LIST_PERSONS = gql`
  query listPersons($filter: ListPersonsFilter!, $sort: ListPersonsSort!) {
    listPersons(filter: $filter, sort: $sort) {
      count
      data {
        id
        name
      }
    }
  }
`;

export const GET_CONTACT_LOGS = gql`
  query contactEditHistoriesAdmin(
    $where: ContactWhereUniqueInput!
    $sort: ContactsEditHistoriesSort
    $filter: ContactsEditHistoriesFilter
  ) {
    contactEditHistoriesAdmin(where: $where, sort: $sort, filter: $filter) {
      contactEditHistories {
        source
        oldValue
        newValue
        id

        contact {
          id
          name
        }
        editor {
          id
          firstName
          lastName
          role
        }
        createdAt
      }
      count
    }
  }
`;

export const GET_USER_CONTACT_LOGS = gql`
  query ContactEditHistories(
    $where: ContactWhereUniqueInput!
    $filter: ContactsEditHistoriesFilter
    $sort: ContactsEditHistoriesSort
  ) {
    contactEditHistories(where: $where, filter: $filter, sort: $sort) {
      contactEditHistories {
        source
        oldValue
        newValue
        id

        contact {
          id
          name
        }
        editor {
          id
          firstName
          lastName
          role
        }
        createdAt
      }
      count
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query GetContactSignedPutUrl($data: GetContactSignedPutUrlInput) {
    getContactSignedPutUrl(data: $data) {
      key
      url
    }
  }
`;
