import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { COUNTRY_CODE } from '../../../../common/constants';

const Prefix = ({ name, disabled = false }) => (
  <Form.Item name={name} noStyle initialValue={COUNTRY_CODE[0].value}>
    <Select
      style={{
        width: 70,
      }}
      options={COUNTRY_CODE}
      disabled={disabled}
    />
  </Form.Item>
);

export const PhoneNumber = ({ name, disabled = false }) => (
  <>
    <Row gutter={[24, 24]} className="segment-rule-container mb-8 p-16">
      <Col span={12}>
        <Form.Item
          name={[name, 'key']}
          label="Enter button text"
          rules={[{ required: true, message: 'Enter button title' }]}
          className="form-filed-custom"
        >
          <Input
            placeholder="Enter button title"
            maxLength={24}
            showCount
            disabled={disabled}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[name, 'value']}
          normalize={(value) => value?.replace(/[^0-9]+/, '')}
          rules={[{ required: true, message: 'Enter number' }]}
          className="form-filed-custom"
          label="Enter button value"
        >
          <Input
            addonBefore={
              <Prefix name={[name, 'countryCode']} disabled={disabled} />
            }
            placeholder="Enter mobile number"
            maxLength={20}
            showCount
            disabled={disabled}
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export const WebLink = ({ name, disabled = false }) => (
  <>
    <Row gutter={[24, 24]} className="segment-rule-container mb-8 p-16">
      <Col span={12}>
        <Form.Item
          name={[name, 'key']}
          rules={[{ required: true, message: 'Enter button title' }]}
          className="form-filed-custom"
          label="Enter button title"
        >
          <Input placeholder="Enter button title" disabled={disabled} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name={[name, 'value']}
          className="form-filed-custom"
          label="Enter button value"
          rules={[
            { required: true, message: 'Please enter url!' },
            {
              type: 'url',
              message: 'Enter valid url!',
            },
          ]}
        >
          <Input placeholder="Enter replay" disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  </>
);
export const CopyCode = ({ name, disabled = false }) => (
  <>
    <Row gutter={[24, 24]} className="segment-rule-container mb-8 p-16">
      <Col span={24}>
        <Form.Item
          name={[name, 'value']}
          rules={[{ required: true, message: 'Enter Code' }]}
          className="form-filed-custom"
          label="Enter button value"
        >
          <Input placeholder="Enter code" disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export const QuickReply = ({ name, disabled = false }) => (
  <>
    <Row gutter={[24, 24]} className="segment-rule-container mb-8 p-16">
      <Col span={24}>
        <Form.Item
          name={[name, 'value']}
          className="form-filed-custom"
          label="Enter button value"
          rules={[{ required: true, message: 'Enter reply' }]}
        >
          <Input placeholder="Enter replay" disabled={disabled} />
        </Form.Item>
      </Col>
    </Row>
  </>
);
