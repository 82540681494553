import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import useGetRole from '../../../hooks/useGetRole';
import {
  GET_ADMIN_CAMPAIGN_LISTING,
  GET_USER_CAMPAIGN_LISTING,
} from '../../campaign/graphql/Queries';

const variableSelector = ({ skip, limit, search }) => ({
  filter: { skip, limit, search },
});
const dataSelector = (data) =>
  data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const CampaignSelect = (props) => {
  const permission = useGetRole();
  const dataKeys = {
    data: permission ? 'campaignsAdmin' : 'campaigns',
    count: 'count',
    records: 'campaigns',
  };
  return (
    <InfiniteSelect
      query={
        permission ? GET_ADMIN_CAMPAIGN_LISTING : GET_USER_CAMPAIGN_LISTING
      }
      variableSelector={variableSelector}
      dataKeys={dataKeys}
      dataSelector={dataSelector}
      placeholder="Select Campaign"
      {...props}
    />
  );
};

export default CampaignSelect;
