import { useEffect, useState } from 'react';

function useAutoComplete() {
  const [autoCompleted, setAutoCompleted] = useState(true);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key !== 'Backspace' && event.keyCode !== 8) {
        if (!autoCompleted) {
          setAutoCompleted(true);
        }
      } else if (autoCompleted) {
        setAutoCompleted(false);
      }
    }

    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup function
    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [autoCompleted]);

  return [autoCompleted, setAutoCompleted];
}

export default useAutoComplete;
