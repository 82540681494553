import { Select } from 'antd';
import { debounce } from 'lodash';
import React, { useMemo, useState } from 'react';
import { LIMIT } from '../common/constants';
import useInfiniteQuery from '../hooks/useInfiniteQuery';

const InfiniteSelect = ({
  query,
  dataSelector,
  variableSelector,
  dataKeys,
  fetchPolicy = 'network-only',
  limit = LIMIT,
  searchable = true,
  multiple = false,
  variables: variables$,
  queryOptions,
  className = '',
  minWidth,
  ...rest
}) => {
  const [search, setSearch] = useState('');

  const variables = useMemo(() => ({ search, ...variables$ }), [
    search,
    variables$,
  ]);
  const { data, loading, loadingMore, hasMore, fetchMore } = useInfiniteQuery(
    query,
    {
      variableSelector,
      dataKeys,
      fetchPolicy,
      limit,
      variables,
      ...queryOptions,
    },
  );

  const options = useMemo(() => dataSelector(data) || [], [data, dataSelector]);

  const handleSearch = useMemo(() => debounce(setSearch, 500), []);

  const handleScroll = (e) => {
    if (!loading && !loadingMore && hasMore) {
      if (
        e.target?.scrollTop + e.target?.offsetHeight >=
        e?.target?.scrollHeight
      ) {
        fetchMore();
      }
    }
  };

  return (
    <Select
      labelInValue
      loading={loading || loadingMore}
      filterOption={false}
      notFoundContent="Not found"
      onPopupScroll={handleScroll}
      options={options}
      className={className}
      style={{ minWidth }}
      {...(multiple && { mode: 'multiple' })}
      {...(searchable && { showSearch: true, onSearch: handleSearch })}
      {...rest}
    />
  );
};

export default InfiniteSelect;
