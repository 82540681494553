import {
  ContactsOutlined,
  FileDoneOutlined,
  GroupOutlined,
  HomeOutlined,
  MessageOutlined,
  SwapOutlined,
  TableOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';
import {
  MODULES,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
} from '../../../common/constants';
import Copyright from '../../../components/Copyright';
import useRouter from '../../../hooks/useRouter';
import useVerifyPermissions from '../../../hooks/useVerifyPermissions';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidebar() {
  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const onMenuSelect = (e) => {
    navigate(e?.key);
  };

  const analyticsPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.DASHBOARD_ANALYTICS,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });

  const adminPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.ADMIN_MANAGEMENT,
  });

  const contactPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CONTACT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });

  const templatePermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.TEMPLATE_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });

  const campaignPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });
  const segmentPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.SEGMENT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });

  const menuItems = [
    // For future use
    analyticsPermission &&
      getItem(MODULES?.DASHBOARD, ROUTES?.MAIN, <HomeOutlined />),
    contactPermission &&
      getItem(MODULES?.CONTACTS, ROUTES?.CONTACTS, <ContactsOutlined />),
    adminPermission &&
      getItem(MODULES?.SUPER_ADMIN, ROUTES?.SUPER_ADMIN, <UserAddOutlined />),
    templatePermission &&
      getItem(MODULES?.TEMPLATE, ROUTES?.TEMPLATE, <FileDoneOutlined />),
    campaignPermission &&
      getItem(MODULES?.CAMPAIGN, ROUTES?.CAMPAIGN, <MessageOutlined />),
    segmentPermission &&
      getItem(MODULES?.SEGMENTS, ROUTES?.SEGMENTS, <GroupOutlined />),
    getItem(
      MODULES?.IMPORT_EXPORT,
      ROUTES?.IMPORT_EXPORT,
      <SwapOutlined rotate={90} />,
    ),
    adminPermission &&
      getItem(MODULES.MASTER_LIST, 'sub4', <TableOutlined />, [
        getItem(MODULES.SPECIALIZATION, ROUTES.SPECIALIZATION),
        getItem(MODULES.LOCATIONS, ROUTES.LOCATIONS),
        getItem(MODULES?.ROLE_PERMISSION, ROUTES?.ROLE_PERMISSION),
        getItem(MODULES?.TAGS, ROUTES?.TAGS),
      ]),
  ];

  return (
    <div className="side-bar">
      <div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[`/${pathname?.split('/')?.[1]}`]}
          defaultSelectedKeys={[ROUTES?.CONTACTS]}
          onSelect={onMenuSelect}
          onClick={onMenuSelect}
          items={menuItems}
        />
      </div>
      <div className="pt-16 pb-16">
        <Copyright />
      </div>
    </div>
  );
}

export default Sidebar;
