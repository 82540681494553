// import React from 'react';

// function ViewLocation() {
//   return <div>ViewLocation</div>;
// }

// export default ViewLocation;

import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import MasterForm from '../components/MasterForm';
import MasterFormTitle from '../components/MasterFormTitle';
import { GET_LOCATION } from './graphql/Queries';

function ViewLocation() {
  const { id } = useParams();
  const { data: specializationData, loading: fetchLoading } = useQuery(
    GET_LOCATION,
    {
      variables: { where: { id } },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  return (
    <>
      <MasterForm
        title={
          <MasterFormTitle
            navigation={ROUTES.LOCATIONS}
            title={FORM_TYPE.view_location}
            isView
          />
        }
        formType={FORM_TYPE.view_location}
        isView
        formData={specializationData}
        loading={fetchLoading}
      />
    </>
  );
}

export default ViewLocation;
