import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useAutoComplete from '../../../../hooks/useAutoComplete';
import EditorToolbar, { formats, modules } from './QuillToolbar';

const QuillEditor = ({
  value,
  onChange,
  disabled = false,
  setCharacterCount,
}) => {
  const [autoComplete] = useAutoComplete();
  const quillRef = useRef(null);

  const changeHandler = (val, _, _curr, editor) => {
    setCharacterCount(editor?.getLength() - 1);
    if (autoComplete) {
      const regexFind = /\{\{(?!\d+\}\})/g;
      const regex = /\{\{\d+\}\}/g;
      const matches = val?.match(regex);
      const replacementString = `{{${matches?.length + 1 || 1}}}`;
      const result = val?.replace(regexFind, replacementString);
      onChange(result, editor);
    } else {
      onChange(val, editor);
    }
  };

  return (
    <div className={`text-editor ${disabled ? 'disabled' : ''}`}>
      <EditorToolbar />
      <ReactQuill
        ref={quillRef}
        readOnly={disabled}
        theme="snow"
        value={value}
        onChange={changeHandler}
        placeholder="Write something awesome..."
        modules={modules}
        formats={formats}
      />
      <span
        style={{
          position: 'absolute',
          right: '0',
          bottom: '0',
          padding: '10px',
        }}
      >
        {quillRef?.current?.editor?.getLength() - 1}/1024
      </span>
    </div>
  );
};

export default QuillEditor;
