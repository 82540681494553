import { useQuery } from '@apollo/client';
import { Card, Space } from 'antd';
import dayjs from 'dayjs';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import { DATE_FORMATS } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import useRouter from '../../hooks/useRouter';
import {
  GET_CAMPAIGN_MESSAGE_ANALYTICS_ADMIN,
  GET_CAMPAIGN_MESSAGE_ANALYTICS_USER,
} from './graphql/Queries';

function MessageAnalytics({ permission }) {
  const { params } = useRouter();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
  });
  const [listSort, setListSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });

  const { data: analyticsData, loading: fetchLoading } = useQuery(
    permission
      ? GET_CAMPAIGN_MESSAGE_ANALYTICS_ADMIN
      : GET_CAMPAIGN_MESSAGE_ANALYTICS_USER,
    {
      variables: {
        where: { id: params?.id },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
        },
        sort: {
          sortBy: listSort.sortBy,
          sortOn: listSort.sortOn,
        },
      },
    },
  );
  const dataKey = permission ? 'campaignAnalyticsAdmin' : 'campaignAnalytics';
  const { data, count } = analyticsData?.[dataKey] || {};

  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig((prev) => ({
      ...prev,
      limit: pagination?.limit,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'template',
      key: 'template',
      ellipsis: true,
      align: 'left',
      width: '30%',
      render: (value, allValues) => (
        <Space direction="vertical">
          <div className="campaign-analytics-listing-name">
            {truncate(value?.name, { length: 25 })}
          </div>
          <div style={{ fontSize: '12px' }}>
            [{dayjs(allValues?.updatedAt).format(DATE_FORMATS.WITH_TIME)}]
          </div>
        </Space>
      ),
    },
    {
      title: 'Attempted',
      dataIndex: 'attempted',
      key: 'attempted',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Read',
      dataIndex: 'read',
      key: 'read',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Failed',
      dataIndex: 'failed',
      key: 'failed',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      key: 'sent',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Delivered',
      dataIndex: 'delivered',
      key: 'delivered',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Opt-out',
      dataIndex: 'optOut',
      key: 'optOut',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
    {
      title: 'Replied',
      dataIndex: 'replied',
      key: 'replied',
      ellipsis: true,
      align: 'center',
      width: '10%',
    },
  ];
  return (
    <Card className="ant-body-scroll">
      <CommonTable
        columns={columns}
        loadingData={fetchLoading}
        data={data}
        onChange={handleTableChange}
        paginationConfig={{
          ...paginationConfig,
          total: count,
          showSizeChanger: false,
        }}
      />
    </Card>
  );
}

export default MessageAnalytics;
