import { filter, includes, map } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { MODULES_KEY, ROLE_KEYS } from '../common/constants';

const useVerifyPermissions = ({ modulekey, allowedPermissions }) => {
  const {
    state: { currentUser, permission },
  } = useContext(AppContext);
  if (ROLE_KEYS.USER === currentUser?.role) {
    if (modulekey === MODULES_KEY?.ADMIN_MANAGEMENT) {
      return false;
    }
    const modulePermission = filter(
      permission,
      (data) => data?.module === modulekey,
    );
    if (modulePermission?.[0]?.access) {
      const accessPermission = map(
        filter(Object?.keys(modulePermission?.[0]?.access), (data) =>
          includes(allowedPermissions, data),
        ),
        (data) => modulePermission?.[0]?.access?.[data],
      )?.every((record) => record === true);
      return accessPermission;
    }
  }
  return true;
};
export default useVerifyPermissions;
