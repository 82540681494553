import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import { CITIES_LIST } from '../graphql/Queries';

const variableSelector = ({ skip, limit, search, stateId }) => ({
  filter: { skip, limit, search },
  where: {
    stateId,
  },
});

const dataSelector = (data) =>
  data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const dataKeys = {
  data: 'cities',
  count: 'count',
  records: 'data',
};

const CitySelect = (props) => (
  <InfiniteSelect
    query={CITIES_LIST}
    variableSelector={variableSelector}
    dataKeys={dataKeys}
    dataSelector={dataSelector}
    placeholder="Select City"
    {...props}
  />
);

export default CitySelect;
