import React from 'react';
import { ROUTES } from '../../common/constants';
import MasterFormTitle from '../master-list/components/MasterFormTitle';
import CampaignForm from './components/CampaignForm';

function AddCampaign() {
  return (
    <>
      <CampaignForm
        title={
          <MasterFormTitle
            navigation={ROUTES.CAMPAIGN}
            title="Campaign"
            isEdit={false}
          />
        }
      />
    </>
  );
}

export default AddCampaign;
