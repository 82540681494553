import { Form, Input, Modal } from 'antd';
import React, { useState } from 'react';

const OtherSpecializationModel = ({ showModal, handleOk, onCancel }) => {
  const [InputValue, setInputValue] = useState('');
  return (
    <div>
      <Modal
        open={showModal}
        centered
        title={
          <div className="d-flex justify-end align-center">
            <div id="add-credit-btn" />
          </div>
        }
        closable={false}
        onCancel={onCancel}
        okText="Save"
        onOk={handleOk}
        okButtonProps={{ disabled: !InputValue }}
        forceRender
      >
        <Form.Item
          name="otherSpecialization"
          label="Specialization"
          style={{ width: '100%' }}
        >
          <Input
            placeholder="Enter other specialization"
            onChange={(e) => setInputValue(e.target.value)}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default OtherSpecializationModel;
