import { gql } from '@apollo/client';

export const ROLE_LISTING = gql`
  query Roles($filter: RolesFilter, $sort: RolesSort) {
    roles(filter: $filter, sort: $sort) {
      count
      roles {
        createdAt
        createdBy
        creator {
          id
          lastName
          role
          firstName
          email
        }
        description
        id
        key
        name
        isActive
        permissions {
          access {
            create
            delete
            read
            update
          }
          module
        }
        updatedAt
      }
    }
  }
`;

export const GET_ROLE = gql`
  query Role($where: RoleWhereUniqueInput!) {
    role(where: $where) {
      createdAt
      createdBy
      creator {
        id
        firstName
        lastName
      }
      description
      id
      isActive
      key
      name
      permissions {
        access {
          create
          delete
          read
          update
        }
        module
      }
      updatedAt
    }
  }
`;

export const ROLE_MODULES = gql`
  query Modules($filter: ModulesFilter) {
    modules(filter: $filter) {
      count
      modules {
        key
        name
      }
    }
  }
`;
