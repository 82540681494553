import { useQuery } from '@apollo/client';
import { Badge, Card, DatePicker, Space, Tag, Typography } from 'antd';
import { camelCase, startCase } from 'lodash';
import React, { useState } from 'react';
import { MODULES, STATUS_FILTER } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import useGetRole from '../../hooks/useGetRole';
import UserFilter from './components/UserFilter';
import { GET_IMPORT_EXPORTS } from './graphql/Queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function ImportExport() {
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    dateRange: {
      from: null,
      to: null,
    },
    status: [],
  });
  const [listSort, setListSort] = useState({
    field: 'updatedAt',
    order: 'DESC',
    creatorId: '',
  });
  const permission = useGetRole();
  const { data: listingData, loading: listingLoading } = useQuery(
    GET_IMPORT_EXPORTS,
    {
      variables: {
        sort: {
          field: listSort?.field,
          order: listSort?.order,
        },
        filter: {
          ...(paginationConfig?.dateRange?.from &&
            paginationConfig?.dateRange?.to && {
              dateRange: {
                from: paginationConfig?.dateRange?.from?.startOf('day'),
                to: paginationConfig?.dateRange?.to?.endOf('day'),
              },
            }),
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
          search: paginationConfig?.search,
          status: paginationConfig?.status,
          creatorId: listSort?.creatorId,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const handleTableChange = (pagination, filter, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig((prev) => ({
      ...prev,
      status: filter?.status,
      limit: pagination?.limit,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const columns = [
    {
      title: 'Source File',
      dataIndex: 'sourceFileName',
      key: 'sourceFileName',
      render: (value, allValues) => (
        <>
          {value ? (
            <a href={allValues?.sourceFileUrl} target="_blank" rel="noreferrer">
              {value?.split('/')?.[1]}
            </a>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Failed File',
      dataIndex: 'failedFileName',
      key: 'failedFileName',
      render: (value, allValues) => (
        <>
          {value ? (
            <a href={allValues?.failedFileUrl} target="_blank" rel="noreferrer">
              {value?.split('/')?.[1]}
            </a>
          ) : (
            '-'
          )}
        </>
      ),
    },
    {
      title: 'Creater',
      dataIndex: 'requester',
      key: 'requester',
      width: 200,
      render: (value) => `${value?.firstName || ''} ${value?.lastName || ''}`,
    },
    {
      title: 'status',
      key: 'status',
      dataIndex: 'status',

      render: (_, record) => {
        const statusIndicator =
          (record?.status === 'PENDING' && 'blue') ||
          (record?.status === 'COMPLETED' && 'green') ||
          (record?.status === 'FAILED' && 'red') ||
          (record?.status === 'PARTIALLY_FAILED' && 'red') ||
          (record?.status === 'PROCESSING' && 'orange');

        return (
          <>
            <Tag color={statusIndicator}>
              {startCase(camelCase(record?.status))}
            </Tag>
          </>
        );
      },

      filters: STATUS_FILTER,
    },

    {
      title: 'Created Count',
      dataIndex: 'createdCount',
      key: 'createdCount',
      render: (value) => value ?? '-',
    },
    {
      title: 'Updated Count',
      dataIndex: 'updatedCount',
      key: 'updatedCount',
      render: (value) => value ?? '-',
    },
    {
      title: 'Failed Count',
      dataIndex: 'failedCount',
      key: 'failedCount',
      render: (value) => value ?? '-',
    },
    {
      title: 'Total',
      dataIndex: 'totalCount',
      key: 'totalCount',
      render: (value) => value ?? '-',
    },
  ];
  const handleRangePicker = (value) => {
    setPaginationConfig((prev) => ({
      ...prev,
      dateRange: {
        from: value?.[0]?.startOf('day'),
        to: value?.[1]?.endOf('day'),
      },
    }));
  };

  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.IMPORT_EXPORT}
          <Badge
            count={listingData?.importsExportJobs?.count
              ?.toString()
              ?.padStart(2, '0')}
          />
        </div>
        <RangePicker onChange={handleRangePicker} />
      </Title>

      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="contact-sidebar">
              <div className="contact-filter-left" />
              <div className="contact-filter-right">
                <div className="contact-filter">
                  <Space>
                    {permission && (
                      <UserFilter
                        allowClear
                        className="filter-width"
                        onChange={(value) => {
                          setListSort((prev) => ({
                            ...prev,
                            creatorId: value?.value || '',
                          }));
                        }}
                      />
                    )}
                  </Space>
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            onChange={handleTableChange}
            loadingData={listingLoading}
            columns={columns}
            data={listingData?.importsExportJobs?.data}
            paginationConfig={{
              ...paginationConfig,
              total: listingData?.importsExportJobs?.count,
              showSizeChanger: false,
            }}
          />
        </div>
      </Card>
    </>
  );
}

export default ImportExport;
