import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Card,
  Modal,
  Space,
  Tooltip,
  Typography,
  message,
} from 'antd';
import React, { useState } from 'react';
import DeleteIcon from '../../assets/icon/DeleteIcon';
import EditPen from '../../assets/icon/EditPen';
import PasswordIcon from '../../assets/icon/Password';
import { LIMIT, MODULES, ROUTES } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import useRouter from '../../hooks/useRouter';
import { GENERATE_PASSWORD } from '../auth/graphql/Mutations';
import { DELETE_USER } from './graphql/Mutations';
import { GET_USERS } from './graphql/Queries';

const { Title } = Typography;

function SuperAdminContactList() {
  const { navigate } = useRouter();
  const [isModal, setIsModal] = useState(false);
  const [isTempPasswordModal, setIsTempPasswordModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [generatePasswordId, setGeneratePasswordId] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [userData, setUserData] = useState('');

  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    currentPage: 0,
    showSizeChanger: false,
  });

  const { data, loading, refetch } = useQuery(GET_USERS, {
    variables: {
      filter: {
        skip: paginationConfig?.currentPage * LIMIT,
        limit: LIMIT,
      },
      sort: {
        sortOn: 'updatedAt',
        sortBy: 'DESC',
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    onCompleted() {
      refetch();
      setIsModal(false);
    },
    onError() {},
  });
  const [generatePassword, { loading: generateLoading }] = useMutation(
    GENERATE_PASSWORD,
    {
      onCompleted(res) {
        setNewPassword(res?.resetPassword?.password);
      },
      onError() {},
    },
  );
  const handleClipboard = () => {
    try {
      if (newPassword?.length > 0) {
        // eslint-disable-next-line no-undef
        navigator.clipboard.writeText(newPassword);
        message.success('Generated Password Copied To Clipboard');
        setIsTempPasswordModal(false);
        setGeneratePasswordId('');
        setUserData('');
        setNewPassword('');
      }
    } catch (error) {
      message.error('Failed to copy');
    }
  };

  const deleteHandler = async () => {
    try {
      await deleteUser({
        variables: {
          where: {
            id: deleteUserId,
          },
        },
      });
    } catch (error) {
      return error;
    }
  };
  const generatePasswordHandler = async () => {
    try {
      if (generatePasswordId) {
        await generatePassword({
          variables: {
            where: {
              id: generatePasswordId,
            },
          },
        });
      }
    } catch (error) {
      return error;
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, allValues) => {
        const { firstName = '', lastName = '' } = allValues;
        return firstName || lastName
          ? `${allValues?.firstName || ''} ${allValues?.lastName || ''}`
          : '-';
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      ellipsis: true,
      align: 'left',
      render: (value) => value,
    },
    {
      title: 'Permission roles',
      dataIndex: 'roles',
      key: 'roles',
      ellipsis: true,
      align: 'left',
      render: (value) => value?.map(({ name }) => name)?.join(', '),
    },
    {
      title: 'Location',
      dataIndex: 'locations',
      key: 'locations',
      ellipsis: true,
      align: 'left',
      render: (value) => value?.map(({ name }) => name)?.join(', '),
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      className: 'cursor-auto',
      fixed: 'right',
      width: 160,
      align: 'center',

      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => (
        <div className="action-button d-flex justify-start">
          <Tooltip title="Edit" placement="top" zIndex={4}>
            <Button
              type="text"
              onClick={() =>
                navigate(`${ROUTES.SUPER_ADMIN}/edit/${value?.id}`)
              }
              icon={<EditPen />}
            />
          </Tooltip>
          <Tooltip
            title="Generate Temporary password"
            placement="top"
            zIndex={4}
          >
            <Button
              type="text"
              danger
              onClick={() => {
                const isFullName = `${value?.firstName} ${value?.lastName}`;
                setIsTempPasswordModal(true);
                setGeneratePasswordId(value?.id);
                setUserData(isFullName || value?.firstName);
              }}
              icon={<PasswordIcon />}
            />
          </Tooltip>
          <Tooltip title="Delete" placement="top" zIndex={4}>
            <Button
              type="text"
              danger
              onClick={() => {
                setIsModal(true);
                setDeleteUserId(value?.id);
              }}
              icon={<DeleteIcon />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.SUPER_ADMIN}
          <Badge count={data?.users?.count || '00'} />
        </div>
        <Button
          icon={<PlusOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            navigate(`${ROUTES.ADD_SUPER_ADMIN_CONTACT}`);
          }}
        >
          Add User
        </Button>
      </Title>
      <Card className="ant-body-scroll ">
        <div className="card-body-wrapper p-0">
          <CommonTable
            scroll={{ x: 1500 }}
            columns={columns}
            data={data?.users?.users}
            loadingData={loading}
            tableClassName=""
            paginationConfig={{
              ...paginationConfig,
              total: data?.users?.count,
              roles: data?.users?.roles,
            }}
            onChange={(pagination) => {
              setPaginationConfig((prev) => ({
                ...prev,
                currentPage: pagination?.current - 1,
              }));
            }}
          />
        </div>
      </Card>

      <Modal
        title="'Delete contact'"
        okText="Yes"
        cancelText="No"
        open={isModal}
        onOk={deleteHandler}
        okButtonProps={{ loading: deleteLoading }}
        cancelButtonProps={{ disabled: deleteLoading }}
        onCancel={() => {
          setIsModal(false);
          setDeleteUserId('');
        }}
      >
        Are you sure you want to delete this contact?
      </Modal>

      <Modal
        maskClosable={false}
        title="Generate Temporary password"
        okText={newPassword?.length > 0 ? 'Copy to clipboard' : 'Yes'}
        cancelText={newPassword?.length > 0 ? 'Cancel' : 'No'}
        open={isTempPasswordModal}
        onOk={
          newPassword?.length > 0 ? handleClipboard : generatePasswordHandler
        }
        okButtonProps={{ loading: generateLoading }}
        cancelButtonProps={{ disabled: generateLoading }}
        onCancel={() => {
          setIsTempPasswordModal(false);
          setGeneratePasswordId('');
          setNewPassword('');
        }}
      >
        <>
          {newPassword?.length > 0 ? (
            <>
              <p>
                This is users new generated password <b>{newPassword}</b>
              </p>
            </>
          ) : (
            <Space>
              Are you sure want to reset password for user
              {userData?.length > 0 && <b>{userData}</b>}?
            </Space>
          )}
        </>
      </Modal>
    </>
  );
}

export default SuperAdminContactList;
