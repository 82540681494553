import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import useRouter from '../../hooks/useRouter';
import CampaignForm from './components/CampaignForm';

const { Title } = Typography;
function EditCampaign() {
  const { id } = useParams();
  const { navigate } = useRouter();

  return (
    <div>
      <CampaignForm
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(ROUTES.CAMPAIGN)}
            />
            Edit Campaign
          </Title>
        }
        id={id}
      />
    </div>
  );
}

export default EditCampaign;
