import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Form, Input, Space } from 'antd';

import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { ROLE_KEYS, ROUTES } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import useRouter from '../../../hooks/useRouter';
import {
  CREATE_ADMIN_CAMPAIGN,
  EDIT_ADMIN_CAMPAIGN,
  EDIT_USER_CAMPAIGN,
} from '../graphql/Mutations';
import { GET_ADMIN_CAMPAIGN, GET_USER_CAMPAIGN } from '../graphql/Queries';

const { required } = formValidatorRules;

function CampaignForm({ id, title }) {
  const { navigate } = useRouter();
  const { getCurrentUser } = useContext(AppContext);
  const [form] = Form.useForm();
  const userRole = getCurrentUser();

  const isAdmin =
    userRole?.role === ROLE_KEYS.SUPER_ADMIN ||
    userRole?.role === ROLE_KEYS.ADMIN;

  const { loading: fetchLoading, data: campaignData } = useQuery(
    isAdmin ? GET_ADMIN_CAMPAIGN : GET_USER_CAMPAIGN,

    {
      skip: !id,
      variables: { where: { id } },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const dataKey = isAdmin ? 'campaignAdmin' : 'campaign';

  const formInitialValues = {
    name: campaignData?.[dataKey]?.name,
  };

  const [createCampaign, { loading: createLoading }] = useMutation(
    CREATE_ADMIN_CAMPAIGN,
    {
      onCompleted() {
        navigate(ROUTES.CAMPAIGN);
      },
      onError() {},
    },
  );

  const [updateCampaign, { loading: updateLoading }] = useMutation(
    isAdmin ? EDIT_ADMIN_CAMPAIGN : EDIT_USER_CAMPAIGN,
    {
      onCompleted() {
        navigate(ROUTES.CAMPAIGN);
      },
      onError() {},
    },
  );

  const handleFinish = ({ name }) => {
    const mutationKey = id ? updateCampaign : createCampaign;

    mutationKey({
      variables: {
        data: {
          name,
        },
        ...(id
          ? {
              where: {
                id,
              },
            }
          : {}),
      },
    });
  };

  if (fetchLoading || createLoading || updateLoading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <Card className="" title={title}>
        <Form
          form={form}
          layout="vertical"
          initialValues={formInitialValues}
          onFinish={handleFinish}
        >
          <Form.Item
            label="Campaign Name"
            name="name"
            rules={[{ required, message: 'Please enter campaign name' }]}
          >
            <Input placeholder="Campaign name" allowClear />
          </Form.Item>
        </Form>
        <Space className="d-flex justify-start">
          <Button onClick={() => navigate(ROUTES.CAMPAIGN)}>Cancel</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Save
          </Button>
        </Space>
      </Card>
    </>
  );
}

export default CampaignForm;
