import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import { COUNTRIES_LIST } from '../graphql/Queries';

const variableSelector = ({ skip, limit, search }) => ({
  filter: { skip, limit, search },
});
const dataSelector = (data) =>
  data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const dataKeys = {
  data: 'countries',
  count: 'count',
  records: 'data',
};

const CountrySelect = (props) => (
  <InfiniteSelect
    query={COUNTRIES_LIST}
    variableSelector={variableSelector}
    dataKeys={dataKeys}
    dataSelector={dataSelector}
    placeholder="Select Country"
    {...props}
  />
);

export default CountrySelect;
