import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import MasterForm from '../components/MasterForm';
import MasterFormTitle from '../components/MasterFormTitle';
import { UPDATE_TAG } from './graphql/Mutations';
import { GET_TAG } from './graphql/Queries';

function EditTag() {
  const { id } = useParams();
  const { navigate } = useRouter();
  const { data: locationData, loading: fetchLoading } = useQuery(GET_TAG, {
    variables: { where: { id } },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateSpecialization, { loading: updateLoading }] = useMutation(
    UPDATE_TAG,
    {
      onCompleted() {
        navigate(ROUTES.TAGS);
      },
      onError() {},
    },
  );

  return (
    <>
      <MasterForm
        title={
          <MasterFormTitle
            navigation={ROUTES.TAGS}
            title={FORM_TYPE.tag}
            isEdit
          />
        }
        formType={FORM_TYPE.tag}
        isEdit
        formData={locationData}
        loading={fetchLoading || updateLoading}
        mutation={updateSpecialization}
      />
    </>
  );
}

export default EditTag;
