import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { segmentRules } from '../../../common/constants';

function RulesList({ onClick }) {
  return (
    <div>
      {segmentRules.map((item) => (
        <>
          <Row
            className="segment-rule-container mb-8"
            key={item.key}
            onClick={() => onClick(item.attribute, item.dataKey, item.title)}
            style={{ cursor: 'pointer' }}
          >
            <Col
              span={6}
              className="d-flex align-center segment-rule-sub-container p-16"
            >
              {item.title}
            </Col>
            <Col span={14} className="p-16">
              {item.description}
            </Col>
            <Col span={4} className="d-flex justify-end pr-16">
              <PlusOutlined />
            </Col>
          </Row>
        </>
      ))}
    </div>
  );
}

export default RulesList;
