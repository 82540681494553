import { Button, Dropdown, Form } from 'antd';
import React from 'react';
import '../../styles/whatsapp-action-button.less';
import { SortableList } from './SortableList';

const DynamicInput = ({ form, items = [], setItems, disabled = false }) => {
  const list = Form?.useWatch(['buttons'], form) || [];
  const totalButton =
    list?.map((item) => item?.list?.map((button) => button))?.flat()?.length ||
    [];

  return (
    <Form.List name="buttons">
      {(fields, { add, remove, move }) => (
        <>
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                add({ list: [{ id: e?.key }] });
                setItems(
                  items?.map((item) =>
                    item.key !== e.key ? item : { ...item, disabled: true },
                  ),
                );
              },
            }}
            placement="bottom"
            disabled={totalButton === 10 || disabled}
          >
            <Button>Select button</Button>
          </Dropdown>
          <div classNmae="whatsapp-button-wrapper">
            <SortableList
              fields={fields}
              remove={remove}
              form={form}
              setItems={setItems}
              move={move}
              items={items}
              totalButton={totalButton}
              list={list}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </Form.List>
  );
};

export default DynamicInput;
