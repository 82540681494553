import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Typography } from 'antd';
import React from 'react';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import MasterForm from '../components/MasterForm';
import { CREATE_TAG } from './graphql/Mutations';

const { Title } = Typography;
function AddTag() {
  const { navigate } = useRouter();
  const [createMutation, { loading: createLoading }] = useMutation(CREATE_TAG, {
    onCompleted() {
      navigate(ROUTES.TAGS);
    },
    onError() {},
  });

  return (
    <>
      <MasterForm
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(ROUTES.TAGS)}
            />
            Add Tag
          </Title>
        }
        loading={createLoading}
        mutation={createMutation}
        formType={FORM_TYPE.tag}
        isEdit={false}
      />
    </>
  );
}

export default AddTag;
