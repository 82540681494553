import { Modal } from 'antd';
import React from 'react';

const WarningModel = ({
  open = false,
  loading = false,
  onOk,
  description = '',
  okText = 'Yes',
  cancelText = 'No',
  onCancel,
  title,
  ...rest
}) => (
  <Modal
    title={title}
    okText={okText}
    cancelText={cancelText}
    open={open}
    onOk={onOk}
    okButtonProps={{ loading }}
    onCancel={onCancel}
    {...rest}
  >
    {description}
  </Modal>
);

export default WarningModel;
