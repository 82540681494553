import React from 'react';
import TemplateForm from './components/TemplateForm';

const CretaeTemplate = () => (
  <div>
    <TemplateForm />
  </div>
);

export default CretaeTemplate;
