import { gql } from '@apollo/client';

export const TEMPLATE_CREATE_AS_DRAFT = gql`
  mutation CreateTemplate($data: CreateTemplateInput!) {
    createTemplate(data: $data) {
      message
      template {
        id
        name
      }
    }
  }
`;

export const TEMPLATE_DELETE = gql`
  mutation DeleteTemplate($where: TemplateWhereUniqueInput!) {
    deleteTemplate(where: $where) {
      message
    }
  }
`;

export const TEMPLATE_DELETE_ADMIN = gql`
  mutation DeleteTemplateAdmin($where: TemplateWhereUniqueInput!) {
    deleteTemplateAdmin(where: $where) {
      message
    }
  }
`;

export const TEMPLATE_SUBMIT_ADMIN = gql`
  mutation SubmitTemplateAdmin($where: TemplateWhereUniqueInput!) {
    submitTemplateAdmin(where: $where) {
      message
    }
  }
`;

export const TEMPLATE_SUBMIT = gql`
  mutation SubmitTemplate($where: TemplateWhereUniqueInput!) {
    submitTemplate(where: $where) {
      message
    }
  }
`;

export const UPDATE_TEMPLATE_ADMIN = gql`
  mutation UpdateTemplateAdmin(
    $where: TemplateWhereUniqueInput!
    $data: UpdateTemplateInput!
  ) {
    updateTemplateAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation UpdateTemplate(
    $data: UpdateTemplateInput!
    $where: TemplateWhereUniqueInput!
  ) {
    updateTemplate(data: $data, where: $where) {
      message
    }
  }
`;

export const GET_TEMPLATE_HEADER_QR_SIGN_URL = gql`
  mutation GenerateQrCodeImage($data: GenerateQrCodeImageInput!) {
    generateQrCodeImage(data: $data) {
      key
      url
    }
  }
`;
