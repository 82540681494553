import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { RESET_PASSWORD } from '../auth/graphql/Mutations';

const { required, password } = formValidatorRules;

function ChangePassword({ setChangeModal }) {
  const { navigate } = useRouter();
  const { dispatch } = useContext(AppContext);
  const [resetPassword, { loading: resetLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted() {
        setChangeModal(false);
        navigate(ROUTES.LOGOUT, { replace: true });
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-undef
        window.location = '/login';
      },
      onError() {},
    },
  );

  const onFinish = async ({ newPassword, oldPassword }) => {
    try {
      await resetPassword({
        variables: {
          data: {
            newPassword,
            oldPassword,
          },
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };

  return (
    <>
      <div className="text-center">
        <h2 className="text-center">Change Password</h2>
      </div>
      <Spin spinning={resetLoading}>
        <Form
          name="reset-password"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          size="large"
          layout="vertical"
        >
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[{ required, message: 'Please enter your old password' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Enter your old password"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required, message: 'Please enter your new password' },
              password,
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Enter your new password"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The new password that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm your new password"
              allowClear
            />
          </Form.Item>
          <Form.Item className="full-width mb-8">
            <Button type="primary" className="full-width" htmlType="submit">
              Reset Password
            </Button>
          </Form.Item>
          <Form.Item className="text-center mb-0">
            <Button
              type="link"
              onClick={() => {
                setChangeModal(false);
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
}

export default ChangePassword;
