import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Card, Space, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import React, { useState } from 'react';
import DeleteIcon from '../../assets/icon/DeleteIcon';
import EditPen from '../../assets/icon/EditPen';
import {
  LIMIT,
  MODULES,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
  TEMPLATE_CATEGORY_LIST,
  TEMPLATE_LATEST_OLDEST_LIST,
  TEMPLATE_STATUS,
  TEMPLATE_STATUS_LIST,
  TEMPLATE_STATUS_RESPONSE,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import useGetRole from '../../hooks/useGetRole';
import useRouter from '../../hooks/useRouter';
import useVerifyPermissions from '../../hooks/useVerifyPermissions';
import WarningModel from './components/WarningModel';
import { TEMPLATE_DELETE, TEMPLATE_DELETE_ADMIN } from './graphql/Mutations';
import { GET_ADMIN_TEMPLATEAS, GET_USER_TEMPLATEAS } from './graphql/Queries';

const { Title } = Typography;

function Template() {
  const { navigate } = useRouter();
  const permission = useGetRole();
  const [isFilter, setIsFilter] = useState();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    current: 1,
  });
  const [isSort, setIsSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });
  const [deleteUserId, setDeleteUserId] = useState('');
  const [EditTemplateId, setEditTemplateId] = useState('');
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const dataType = permission ? 'templatesAdmin' : 'templates';
  const templateEditPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.TEMPLATE_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.UPDATE,
  });
  const templateAddPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.TEMPLATE_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.CREATE,
  });
  const templateDeletePermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.TEMPLATE_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.DELETE,
  });

  const { data, loading: getTemplateloading, refetch } = useQuery(
    permission ? GET_ADMIN_TEMPLATEAS : GET_USER_TEMPLATEAS,
    {
      variables: {
        sort: {
          sortBy: isSort?.sortBy,
          sortOn: isSort?.sortOn,
        },
        filter: {
          limit: LIMIT,
          search: isFilter?.search,
          skip: (paginationConfig?.current - 1) * LIMIT,
          ...(isFilter?.status?.[0] && { status: isFilter?.status?.[0] }),
          ...(isFilter?.category?.[0] && { category: isFilter?.category?.[0] }),
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [deleteTemplate, { loading: deleteLoading }] = useMutation(
    permission ? TEMPLATE_DELETE_ADMIN : TEMPLATE_DELETE,
    {
      onError() {},
    },
  );

  const tableChangeHandler = (pagination, filter, sorter) => {
    setIsFilter(omit(filter, 'name'));
    setIsSort((prev) => ({
      ...prev,
      ...(filter?.name && {
        sortOn: filter?.name?.[0],
      }),
      sortBy: sorter?.order !== 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig({ ...paginationConfig, ...pagination });
  };

  const getStatus = (value) => {
    switch (value) {
      case 'SUBMITTED':
        return <Tag color="magenta">{value}</Tag>;
      case 'DRAFT':
        return <Tag color="geekblue">{value}</Tag>;
      case 'APPROVED':
        return <Tag color="success">{value}</Tag>;
      case 'PENDING':
        return <Tag color="orange">{value}</Tag>;
      case 'REJECTED':
        return <Tag color="red">{value}</Tag>;
      case 'IN_APPEAL':
        return <Tag color="gold">{value}</Tag>;
      case 'PENDING_DELETION':
        return <Tag color="magenta">{value}</Tag>;
      case 'DISABLED':
        return <Tag color="purple">{value}</Tag>;
      case 'DELETED':
        return <Tag color="volcano">{value}</Tag>;
      case 'PAUSED':
        return <Tag color="volcano">{value}</Tag>;
      case 'FAILED':
        return <Tag color="red">{value}</Tag>;
      case 'LIMIT_EXCEEDED':
        return <Tag color="lime">{value}</Tag>;
      default:
        return <Tag>{value}</Tag>;
    }
  };

  const formatDateTimeForTable = (dateTimeString) =>
    dayjs(dateTimeString).format('MMMM D, YYYY, HH:mm');

  const columns = [
    {
      title: 'Template name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      width: 250,
      render: (value) => value ?? '-',
      sorter: true,
      filterMultiple: false,
      filters: TEMPLATE_LATEST_OLDEST_LIST,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      align: 'left',

      className: 'max-width-column',
      render: (value) => value ?? '-',
      filterMultiple: false,
      filters: TEMPLATE_CATEGORY_LIST,
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value?.name ?? '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value, allValues) => {
        const isHover =
          value === TEMPLATE_STATUS_RESPONSE.REJECTED ||
          value === TEMPLATE_STATUS_RESPONSE.FAILED;
        const responseKey =
          value === TEMPLATE_STATUS_RESPONSE.REJECTED ? 'reason' : 'message';
        return (
          (
            <Tooltip
              title={isHover && allValues?.statusMetaData?.[responseKey]}
            >
              {getStatus(value)}
            </Tooltip>
          ) ?? '-'
        );
      },
      filterMultiple: false,
      filters: TEMPLATE_STATUS_LIST,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value) => formatDateTimeForTable(value) ?? '-',
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      className: 'cursor-auto',
      fixed: 'center',
      align: 'center',
      width: '9%',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => {
        const submitDisabled =
          value?.dailyEditsCount === 1 ||
          value?.monthlyEditsCount === 10 ||
          !TEMPLATE_STATUS[value?.status]?.EDIT;

        return (
          <div className="action-button d-flex justify-end">
            {templateEditPermission && (
              <Tooltip title="Edit" placement="top" zIndex={4}>
                <Button
                  type="text"
                  onClick={() => {
                    if (submitDisabled) {
                      setEditTemplateId(value?.id);
                      setIsEditModal(true);
                    } else {
                      navigate(`${ROUTES.TEMPLATE}/edit/${value?.id}`);
                    }
                  }}
                  icon={<EditPen />}
                />
              </Tooltip>
            )}
            {templateDeletePermission && (
              <Tooltip title="Delete" placement="top" zIndex={4}>
                <Button
                  type="text"
                  danger
                  onClick={() => {
                    setIsDeleteModal(true);
                    setDeleteUserId(value?.id);
                  }}
                  icon={<DeleteIcon />}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.TEMPLATE}
          <Badge
            count={data?.[dataType]?.count?.toString()?.padStart(2, '0')}
          />
        </div>

        {templateAddPermission && (
          <Button
            icon={<PlusOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              navigate(`${ROUTES.ADD_TEMPLATE}`);
            }}
          >
            Add Template
          </Button>
        )}
      </Title>
      <Card
        className="ant-body-scroll "
        title={
          <>
            <div className="contact-sidebar">
              <div className="contact-filter-left" />
              <div className="contact-filter-right">
                <Space>
                  <SearchComponent
                    getData={(text) => {
                      setIsFilter((prev) => ({
                        ...prev,
                        search: text,
                      }));
                      if (paginationConfig.current !== 1) {
                        setPaginationConfig((prev) => ({
                          ...prev,
                          current: 1,
                        }));
                      }
                    }}
                  />
                </Space>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            onChange={tableChangeHandler}
            columns={columns}
            data={data?.[dataType]?.templates}
            loadingData={getTemplateloading}
            paginationConfig={{
              ...paginationConfig,
              total: data?.[dataType]?.count || 0,
              showSizeChanger: false,
            }}
          />
        </div>
      </Card>
      <WarningModel
        title="Delete template"
        description="Are you sure you want to delete this template?"
        open={isDeleteModal}
        onCancel={() => setIsDeleteModal(false)}
        loading={deleteLoading}
        onOk={async () => {
          try {
            const deleteUserRes = await deleteTemplate({
              variables: {
                where: {
                  id: deleteUserId,
                },
              },
            });
            if (deleteUserRes?.data) {
              refetch();
              setIsDeleteModal(false);
            } else {
              setIsDeleteModal(false);
            }
          } catch (error) {
            //
          }
        }}
      />
      <WarningModel
        title="Edit template"
        description="Currenty you can't edit this template? if press 'Yes' show the template details"
        open={isEditModal}
        onCancel={() => setIsEditModal(false)}
        loading={deleteLoading}
        onOk={() => navigate(`${ROUTES.TEMPLATE}/edit/${EditTemplateId}`)}
      />
    </>
  );
}

export default Template;
