import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import { ACTIVE_TAG_LIST } from '../../contacts/graphql/Queries';

const variableSelector = ({ skip, limit }) => ({
  filter: { skip, limit },
});
const dataSelector = (data) =>
  data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const dataKeys = {
  data: 'activeTags',
  count: 'count',
  records: 'tags',
};

const TagSelect = (props) => (
  <InfiniteSelect
    searchable={false}
    query={ACTIVE_TAG_LIST}
    variableSelector={variableSelector}
    dataKeys={dataKeys}
    dataSelector={dataSelector}
    placeholder="Select Tag"
    {...props}
  />
);

export default TagSelect;
