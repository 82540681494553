import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation LoginUser($data: LoginUserInput!) {
    loginUser(data: $data) {
      accessToken
      data {
        id
        email
        firstName
        lastName
        profileImage
        locationIds
        locations {
          id
          key
          name
        }
        role
        locations {
          key
          name
          id
        }
        forcePasswordReset
        disabledAt
        lastActiveOn
        roles {
          id
          key
          name
          permissions {
            access {
              read
              delete
              create
              update
            }
            module
          }
        }
      }
      message
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ChangePassword($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      message
    }
  }
`;

export const GENERATE_PASSWORD = gql`
  mutation ResetPassword($where: UserWhereUniqueInput!) {
    resetPassword(where: $where) {
      message
      password
    }
  }
`;
