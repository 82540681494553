import {
  ArrowLeftOutlined,
  InfoCircleOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Typography,
  message,
} from 'antd';
import { groupBy, map } from 'lodash';
import React, { useMemo, useState } from 'react';
import {
  BUTTON_TYPE,
  CATEGORY,
  FILE_TYPES,
  QR_CODE_URL,
  REGEX,
  ROUTES,
  TEMPLATE_STATUS,
} from '../../../common/constants';
import {
  convertHtmlTextToWhatsapp,
  convertWhatsappTextToHtml,
  fileUpload,
  handleProtectedNavigation,
} from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RouterPrompt from '../../../components/RouterPrompt';
import SectionTitle from '../../../components/SectionTitle';
import useGetRole from '../../../hooks/useGetRole';
import useRouter from '../../../hooks/useRouter';
import {
  GET_TEMPLATE_HEADER_QR_SIGN_URL,
  TEMPLATE_CREATE_AS_DRAFT,
  TEMPLATE_SUBMIT,
  TEMPLATE_SUBMIT_ADMIN,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_ADMIN,
} from '../graphql/Mutations';
import {
  GET_TEMPLATE,
  GET_TEMPLATE_ADMIN,
  GET_TEMPLATE_HEADER_SIGN_URL,
  GET_TEMPLATE_LANGUAGES,
} from '../graphql/Queries';
import DynamicValue from './DynamicValue';
import QuillEditor from './Editor/QuillEditor';
import HeaderInput from './HeaderInput';
import Preview from './Preview';
import UploadFormItem from './UploadAssets';
import DynamicInput from './WhatsappButton/DynamicButtons';

const TemplateForm = ({ edit }) => {
  const [form] = Form.useForm();
  const getButtonValue = Form.useWatch('buttons', form);
  const getSelectCategory = Form.useWatch('value', form);
  const mediaData = getSelectCategory?.headerUpload?.[0];
  const { navigate } = useRouter();
  const [current, setCurrent] = useState(0);
  const [type, setType] = useState(FILE_TYPES.NONE.id);
  const [isPrompt, setIsPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [nameError, setNameError] = useState([]);
  const [IsEditedTemplateStatus, setIsEditedTemplateStatus] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [components, setComponents] = useState();
  const [isGetTemplateDataLoading, setGetTemplateDataLoading] = useState(false);
  const [templateUpdate, setTemplateUpdate] = useState({
    dailyEditsCount: 0,
    monthlyEditsCount: 0,
  });
  const [characterCount, setCharacterCount] = useState(0);
  const [isButtonTypeSelction, setIsButtonTypeSelction] = useState([
    {
      key: BUTTON_TYPE.PHONE_NUMBER.id,
      label: BUTTON_TYPE.PHONE_NUMBER.title,
      disabled: false,
    },
    {
      key: BUTTON_TYPE.QUICK_REPLY.id,
      label: BUTTON_TYPE.QUICK_REPLY.title,
      disabled: false,
    },
    {
      key: BUTTON_TYPE.URL.id,
      label: BUTTON_TYPE.URL.title,
      disabled: false,
    },
    {
      key: BUTTON_TYPE.COPY_CODE.id,
      label: BUTTON_TYPE.COPY_CODE.title,
      disabled: false,
    },
  ]);
  const permission = useGetRole();
  const dataKey = permission ? 'templateAdmin' : 'template';
  const allButtons = getButtonValue
    ?.map((item) => item?.list?.map((button) => button))
    ?.flat();

  const submitDisabled =
    templateUpdate.dailyEditsCount === 1 ||
    templateUpdate.monthlyEditsCount === 10 ||
    (TEMPLATE_STATUS[IsEditedTemplateStatus]?.EDIT === undefined
      ? false
      : !TEMPLATE_STATUS[IsEditedTemplateStatus]?.EDIT);
  const componentDisabled = edit
    ? TEMPLATE_STATUS[IsEditedTemplateStatus]?.NO_EDIT_FIELD?.COMPONENTS
    : false;
  const selectedType = FILE_TYPES[type];
  const initialValues = {
    value: {
      selectHeaderType: FILE_TYPES.NONE.id,
      headerUpload: [],
      selectCategory: 'MARKETING',
    },
    isSubmit: true,
  };

  const [getHederSignUrl] = useLazyQuery(GET_TEMPLATE_HEADER_SIGN_URL, {
    fetchPolicy: 'network-only',
  });
  const [getHederQRSignUrl] = useMutation(GET_TEMPLATE_HEADER_QR_SIGN_URL, {
    fetchPolicy: 'network-only',
  });

  const { loading: getTemplateDataLoading } = useQuery(
    permission ? GET_TEMPLATE_ADMIN : GET_TEMPLATE,
    {
      skip: !edit,
      onCompleted: (res) => {
        const body =
          res?.[dataKey]?.components?.find((item) => item?.type === 'BODY') ||
          null;
        const buttons =
          res?.[dataKey]?.components?.find((item) => item?.type === 'BUTTONS')
            ?.buttons || [];
        const header =
          res?.[dataKey]?.components?.find((item) => item?.type === 'HEADER') ||
          null;
        const footer =
          res?.[dataKey]?.components?.find((item) => item?.type === 'FOOTER') ||
          null;

        setTemplateUpdate((prev) => ({
          ...prev,
          dailyEditsCount: res?.templateAdmin?.dailyEditsCount,
          monthlyEditsCount: res?.templateAdmin?.monthlyEditsCount,
        }));
        setComponents(header);
        setIsEditedTemplateStatus(res?.[dataKey]?.status);

        const groupedB = groupBy(buttons, 'type');
        setIsButtonTypeSelction(
          isButtonTypeSelction?.map((nValue) =>
            groupedB?.[nValue?.key]?.length > 0
              ? { ...nValue, disabled: true }
              : { ...nValue },
          ),
        );

        const actions = map(groupedB, (items) => ({
          list: map(items, (item) => {
            const listItem = {
              id: item?.type,
              value: item?.value,
            };
            if (item.type === 'PHONE_NUMBER') {
              listItem.id = item?.type;
              listItem.countryCode = `+${item?.phoneNumber?.substring(1, 3)}`;
              listItem.key = item?.text;
              listItem.value = item?.phoneNumber?.slice(3);
            } else if (item.type === 'QUICK_REPLY') {
              listItem.id = item?.type;
              listItem.value = item?.text;
            } else if (item.type === 'COPY_CODE') {
              listItem.value = item?.example?.[0];
              listItem.id = item?.type;
            } else if (item.type === 'URL') {
              listItem.id = item?.type;
              listItem.key = item?.text;
              listItem.value = item?.url;
            }
            return listItem;
          }),
        }));
        const formData = res?.[dataKey];
        setTemplateId(formData?.id);
        const bodyText = convertWhatsappTextToHtml(body?.text);
        setType(header?.format);
        form.setFieldsValue({
          value: {
            headerUpload: [
              {
                uid: '-1',
                name: header?.media?.key,
                status: 'done',
                thumbUrl: header?.media?.url,
              },
            ],
            selectCategory: formData?.category,
            selectLanguage: formData?.language?.id,
            templateName: formData?.name,
            selectHeaderType: header?.format || FILE_TYPES.NONE.id,
            headerInput: header?.text || '',
            headerQrInput: header?.media?.url || '',
            headerDynamicText: header?.placeholder?.headerText?.map((item) => ({
              key: item?.field,
              value: item?.text,
            })),
            templateBody: bodyText,
            footer: footer?.text,
          },
          dynamic: map(body?.placeholder?.bodyText, (item) => ({
            key: item?.field,
            value: item?.text,
          })),
          buttons: actions,
        });
      },
      variables: {
        where: {
          id: edit,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { data, loading: getLanguageLoading } = useQuery(
    GET_TEMPLATE_LANGUAGES,
    {
      onCompleted: (res) => {
        const englishLanguageId = res?.activeLanguages?.languages?.[0]?.id;
        form.setFieldValue(['value', 'selectLanguage'], englishLanguageId);
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [
    createTemplateAsDrafts,
    { loading: createAsDraftTemplateLoading },
  ] = useMutation(TEMPLATE_CREATE_AS_DRAFT);
  const [submitTemplate, { loading: submitTemplateLoading }] = useMutation(
    permission ? TEMPLATE_SUBMIT_ADMIN : TEMPLATE_SUBMIT,
  );
  const [updateTemplate, { loading: updateTemplateLoading }] = useMutation(
    permission ? UPDATE_TEMPLATE_ADMIN : UPDATE_TEMPLATE,
  );
  const getTemplateData = async (value) => {
    setGetTemplateDataLoading(true);
    const uploadFileData = value?.value?.headerUpload?.[0]?.originFileObj;
    let uploadUrl;
    let QRSignUrl;
    if (uploadFileData) {
      uploadUrl = await getHederSignUrl({
        skip: !uploadFileData,
        variables: {
          data: {
            fileName: uploadFileData?.name,
          },
        },
      });
      if (uploadUrl?.data?.getTemplateHeaderSignedPutUrl) {
        try {
          await fileUpload(
            uploadUrl?.data?.getTemplateHeaderSignedPutUrl?.url,
            uploadFileData,
          );
        } catch (error) {
          return false;
        }
      }
    }

    if (
      value?.value?.selectHeaderType === 'QR_CODE' &&
      components?.media?.url !== value?.value?.headerQrInput
    ) {
      QRSignUrl = await getHederQRSignUrl({
        variables: {
          data: {
            content: value?.value?.headerQrInput,
          },
        },
      });
    }

    const allNewButton = value?.buttons?.map((item) =>
      item?.list?.map((button) => button),
    );
    const allNewbuttons = allNewButton?.flat();

    const bodyText = convertHtmlTextToWhatsapp(value?.value?.templateBody);
    const buttons =
      (allNewbuttons?.length > 0 &&
        allNewbuttons?.map((item, index) => ({
          order: index + 1,
          type: item?.id,
          ...(item?.id === BUTTON_TYPE.PHONE_NUMBER.id && {
            phoneNumber: `${item?.countryCode}${item?.value}`,
            text: item?.key,
          }),
          ...(item?.id === BUTTON_TYPE.URL.id && {
            url: item?.value,
            text: item?.key,
          }),
          ...(item?.id === BUTTON_TYPE.COPY_CODE.id && {
            example: item?.value,
          }),
          ...(item?.id === BUTTON_TYPE.QUICK_REPLY.id && {
            text: item?.value,
          }),
        }))) ||
      [];
    const currentTemplateData = {
      category: getSelectCategory?.selectCategory,
      languageId: getSelectCategory?.selectLanguage,
      name: getSelectCategory?.templateName,
      components: [
        ...(getSelectCategory?.selectHeaderType !== FILE_TYPES.NONE.id
          ? [
              {
                type: 'HEADER',
                format: selectedType?.id,
                ...(selectedType?.id !== FILE_TYPES.TEXT.id && {
                  media: {
                    key: uploadUrl?.data?.getTemplateHeaderSignedPutUrl?.key
                      ? uploadUrl?.data?.getTemplateHeaderSignedPutUrl?.key
                      : getSelectCategory?.headerUpload?.[0]?.name,
                  },
                }),
                ...(selectedType?.id === FILE_TYPES.QR_CODE.id && {
                  media: {
                    key:
                      components?.media?.url !==
                        getSelectCategory?.headerQrInput && !edit
                        ? QRSignUrl?.data?.generateQrCodeImage?.key
                        : components?.media?.key,
                  },
                }),
                ...(selectedType?.id === FILE_TYPES.TEXT.id && {
                  text: getSelectCategory?.headerInput,
                }),
                ...(getSelectCategory?.headerDynamicText && {
                  placeholder: {
                    headerText: value?.value?.headerDynamicText?.map(
                      ({ key, value: headerDynamicValue }, id) => ({
                        order: id + 1,
                        text: headerDynamicValue,
                        field: key,
                      }),
                    ),
                  },
                }),
              },
            ]
          : []),
        ...(value?.value?.templateBody
          ? [
              {
                type: 'BODY',
                text: bodyText,
                ...(value?.dynamic?.length > 0 && {
                  placeholder: {
                    bodyText: value?.dynamic?.map((item, index) => ({
                      field: item?.key,
                      order: index + 1,
                      text: item?.value,
                    })),
                  },
                }),
              },
            ]
          : []),
        ...(value?.value?.footer
          ? [
              {
                type: 'FOOTER',
                text: value?.value?.footer,
              },
            ]
          : []),
        ...(allNewbuttons?.length > 0
          ? [
              {
                type: 'BUTTONS',
                buttons: buttons || [],
              },
            ]
          : []),
      ],
    };
    setGetTemplateDataLoading(false);
    return currentTemplateData;
  };

  const createTemplateAsDraft = async (value) => {
    const templateData = await getTemplateData(value);
    if (templateData === false) {
      message.error('Image upload failed!, please try again.');
      setGetTemplateDataLoading(false);
      return;
    }
    try {
      if (!edit) {
        const { data: draftTemplateData } = await createTemplateAsDrafts({
          variables: {
            data: templateData,
          },
        });
        if (draftTemplateData) {
          return draftTemplateData;
        }
      } else {
        await updateTemplate({
          variables: {
            where: {
              id: edit,
            },
            data: templateData,
          },
        });
        navigate(ROUTES.TEMPLATE);
      }
    } catch (error) {
      return null;
    }
  };

  const onFinish = async (value) => {
    if (value?.isSubmit) {
      if (!edit) {
        const draftTemplateId = await createTemplateAsDraft(value);
        const submitTemplateId = draftTemplateId?.createTemplate?.template?.id;
        if (submitTemplateId) {
          try {
            await submitTemplate({
              variables: {
                where: {
                  id: submitTemplateId || '',
                },
              },
            });
          } catch (error) {
            return error;
          } finally {
            navigate(ROUTES.TEMPLATE);
          }
        }
      } else {
        const templateData = await getTemplateData(value);
        if (templateData === false) {
          message.error('Image upload failed!, please try again.');
          setGetTemplateDataLoading(false);
          return;
        }
        try {
          await updateTemplate({
            variables: {
              where: {
                id: edit,
              },
              data: templateData,
            },
          });
          if (IsEditedTemplateStatus === TEMPLATE_STATUS.DRAFT.ID) {
            await submitTemplate({
              variables: {
                where: {
                  id: templateId || '',
                },
              },
            });
          }
        } catch (error) {
          //
        } finally {
          navigate(ROUTES.TEMPLATE);
        }
      }
    } else {
      const createData = await createTemplateAsDraft(value);
      if (createData?.createTemplate?.template?.id) {
        navigate(ROUTES.TEMPLATE);
      }
    }
  };

  const onTypeChange = (value) => {
    setType(value);
    form.setFieldsValue({
      value: { headerUpload: null },
    });
  };

  const remove = getSelectCategory?.headerInput?.includes(`{{1}}`);
  const regex = /\{\{\d+\}\}/g;
  const matches = getSelectCategory?.templateBody?.match(regex);

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    const NextPageError = form?.getFieldError(['value', 'templateName']) || [];
    setNameError(NextPageError);
    setShowPrompt(true);
  };
  const previewThumbnail = useMemo(
    () =>
      mediaData?.originFileObj
        ? URL.createObjectURL(mediaData?.originFileObj)
        : mediaData?.thumbUrl,
    [getSelectCategory],
  );
  const { Title } = Typography;

  return (
    <LoaderComponent
      spinning={
        isGetTemplateDataLoading ||
        createAsDraftTemplateLoading ||
        submitTemplateLoading ||
        updateTemplateLoading
      }
    >
      <Title
        className="site-page-header d-flex align-center gap-4 p-0 mb-8 mt-0"
        level={3}
      >
        <Button
          type="text"
          shape="circle"
          onClick={handleBack}
          icon={<ArrowLeftOutlined />}
        />
        {edit ? 'Edit Template' : 'Add Template'}
      </Title>
      <Card
        className="ant-template-card ant-body-scroll"
        title={
          <Steps
            current={current}
            className="steps"
            items={[{ title: 'Basic details' }, { title: 'Template details' }]}
          />
        }
        actions={[
          <div key="action-button" className="text-right">
            <Space>
              {current !== 0 && (
                <Button
                  onClick={() => {
                    setCurrent(0);
                  }}
                >
                  Previous
                </Button>
              )}
              {current === 1 && (
                <Button
                  type="primary"
                  onClick={() => {
                    form.setFieldValue('isSubmit', true);
                    form.submit();
                  }}
                  disabled={submitDisabled}
                >
                  Submit
                </Button>
              )}
              {current === 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrent(1);
                  }}
                  disabled={
                    !(
                      getSelectCategory?.templateName && nameError?.length === 0
                    )
                  }
                >
                  Next
                </Button>
              )}
              {(IsEditedTemplateStatus
                ? TEMPLATE_STATUS[IsEditedTemplateStatus]?.IN_DRAFT
                : true) &&
                current === 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldValue('isSubmit', false);
                      form.submit();
                    }}
                  >
                    Save as draft
                  </Button>
                )}
            </Space>
          </div>,
        ]}
        loading={getTemplateDataLoading}
      >
        <Row gutter={[24, 24]} className="card-body-wrapper">
          <Col span={current === 1 ? 16 : 24}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              form={form}
              onValuesChange={handleShowPrompt}
              validateTrigger={['onChange', 'onSubmit']}
              initialValues={initialValues}
            >
              <Row gutter={[16, 16]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={12}
                  style={{ position: 'relative' }}
                >
                  <Form.Item
                    tooltip={{
                      icon: <InfoCircleOutlined />,
                      className: 'default-pointer',
                      title:
                        'Can only contain lowercase alphanumeric characters and underscores (_). No other characters or white space are allowed.',
                    }}
                    name={['value', 'templateName']}
                    label="Template Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter template name!',
                        whitespace: true,
                      },
                      {
                        validator(_, value) {
                          const rex = value?.match(REGEX.SNAKCASETEXT);
                          if (value && !rex) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject(
                              new Error('Enter valid name'),
                            );
                          }
                          return Promise?.resolve();
                        },
                      },
                    ]}
                    hidden={current === 1}
                  >
                    <Input
                      placeholder="Enter template Name"
                      maxLength={512}
                      showCount
                      disabled={
                        edit
                          ? TEMPLATE_STATUS[IsEditedTemplateStatus]
                              ?.NO_EDIT_FIELD?.NAME
                          : false
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <Form.Item
                    label="Select Category"
                    name={['value', 'selectCategory']}
                    hidden={current === 1}
                    rules={[
                      {
                        required: true,
                        message: 'Please select category!',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select category"
                      options={CATEGORY}
                      disabled={
                        edit
                          ? TEMPLATE_STATUS[IsEditedTemplateStatus]
                              ?.NO_EDIT_FIELD?.CATEGORY
                          : false
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                  <Form.Item
                    label="Select Language"
                    name={['value', 'selectLanguage']}
                    hidden={current === 1}
                    rules={[
                      {
                        required: true,
                        message: 'Please select language!',
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        edit
                          ? TEMPLATE_STATUS[IsEditedTemplateStatus]
                              ?.NO_EDIT_FIELD?.LANGUAGE
                          : false
                      }
                      placeholder="Select language"
                      loading={getLanguageLoading}
                      options={data?.activeLanguages?.languages.map(
                        ({ name, id }) => ({
                          label: name,
                          value: id,
                        }),
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {current === 1 && (
                <SectionTitle title="HEADER" className="mt-16" />
              )}
              <Row gutter={[16, 16]} className="template-wrapper">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
                  <Form.Item
                    label="Select media type"
                    name={['value', 'selectHeaderType']}
                    hidden={current === 0}
                  >
                    <Select
                      placeholder="Select header type"
                      onChange={onTypeChange}
                      options={Object.values(FILE_TYPES).map(
                        ({ title, id }) => ({
                          label: title,
                          value: id,
                        }),
                      )}
                      allowClear
                      disabled={componentDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  {type === FILE_TYPES.QR_CODE.id && (
                    <Form.Item
                      name={['value', 'headerQrInput']}
                      label={`Add ${selectedType?.title} Link`}
                      hidden={current === 0}
                      rules={[
                        {
                          required: true,
                          message: 'Enter url',
                          whitespace: false,
                        },
                        {
                          type: 'url',
                          message: 'Invalid url',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Link"
                        disabled={componentDisabled}
                      />
                    </Form.Item>
                  )}
                  {type === FILE_TYPES.TEXT.id && (
                    <Form.Item
                      name={['value', 'headerInput']}
                      label={`Add ${selectedType?.title}`}
                      hidden={current === 0}
                      rules={[
                        {
                          required: true,
                          message: 'Enter template header',
                        },
                      ]}
                    >
                      <HeaderInput disabled={componentDisabled} />
                    </Form.Item>
                  )}
                  <Form.Item name="isSubmit" hidden>
                    <Radio>Radio</Radio>
                  </Form.Item>
                  {remove && type === FILE_TYPES.TEXT.id && (
                    <Row gutter={[16, 0]} className="full-width">
                      <DynamicValue
                        matches={['{{1}}']}
                        disabled={componentDisabled}
                        form={form}
                        name={['value', 'headerDynamicText']}
                        hidden={current === 0}
                      />
                    </Row>
                  )}
                  {(type === FILE_TYPES.IMAGE.id ||
                    type === FILE_TYPES.VIDEO.id ||
                    type === FILE_TYPES.DOCUMENT.id) && (
                    <UploadFormItem
                      form={form}
                      selectedType={selectedType}
                      current={current}
                      disabled={componentDisabled}
                    />
                  )}
                </Col>
              </Row>
              {current === 1 && <SectionTitle title="BODY" className="mt-16" />}
              <Row gutter={[16, 16]} className="template-wrapper">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  className="body-editor"
                >
                  <Form.Item
                    name={['value', 'templateBody']}
                    label="Template Body"
                    hidden={current === 0}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter template Body!',
                      },
                      {
                        validator() {
                          if (characterCount > 1024) {
                            return Promise.reject(
                              new Error(
                                'You have exceeded the 1024 character limit, Please check !',
                              ),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <QuillEditor
                      disabled={componentDisabled}
                      characterCount={characterCount}
                      setCharacterCount={setCharacterCount}
                    />
                  </Form.Item>
                </Col>

                {matches?.length > 0 && current === 1 && (
                  <Row gutter={[16, 0]} className="full-width">
                    <DynamicValue
                      matches={matches}
                      disabled={componentDisabled}
                      form={form}
                      name="dynamic"
                    />
                  </Row>
                )}
              </Row>
              {current === 1 && (
                <SectionTitle title="FOOTER" className="mt-16" />
              )}
              <Row gutter={[16, 16]} className="template-wrapper">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    name={['value', 'footer']}
                    label="Footer Description"
                    hidden={current === 0}
                  >
                    <Input
                      placeholder="Enter footer description"
                      maxLength={60}
                      showCount
                      disabled={componentDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {current === 1 && (
                <SectionTitle title="BUTTON" className="mt-16" />
              )}
              <Row gutter={[16, 16]} className="template-wrapper">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  {current === 1 && (
                    <DynamicInput
                      form={form}
                      items={isButtonTypeSelction}
                      setItems={setIsButtonTypeSelction}
                      disabled={componentDisabled}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
          {current === 1 && (
            <Col span={8} className="template-preview-wrapper">
              <Preview
                obj={
                  <div>
                    {type === FILE_TYPES.TEXT.id && (
                      <p>{getSelectCategory?.headerInput}</p>
                    )}
                    {type === FILE_TYPES.QR_CODE.id && (
                      <Image src={QR_CODE_URL} alt="QR code" />
                    )}
                    {mediaData && (
                      <>
                        {type === FILE_TYPES.IMAGE.id && (
                          <Image src={previewThumbnail} alt="img" />
                        )}
                        {type === FILE_TYPES.VIDEO.id && (
                          <video width="100%">
                            <source src={previewThumbnail} />
                            <track
                              src="captions_en.vtt"
                              kind="captions"
                              srcLang="en"
                              label="english_captions"
                            />
                          </video>
                        )}
                        {type === FILE_TYPES.DOCUMENT.id && (
                          <object
                            data={previewThumbnail}
                            type="application/pdf"
                            width="100%"
                            className="pdf-viewer"
                          >
                            <p>
                              Alternative text - include a link{' '}
                              <a
                                href="https://www.orimi.com/pdf-test.pdf"
                                alt="PDF link"
                              >
                                to the PDF!
                              </a>
                            </p>
                          </object>
                        )}
                      </>
                    )}
                    {getSelectCategory?.templateBody && (
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: getSelectCategory?.templateBody,
                        }}
                      />
                    )}
                    {getSelectCategory?.footer && (
                      <p style={{ color: '##5b5c61', fontSize: '12px' }}>
                        {getSelectCategory?.footer}
                      </p>
                    )}
                    {allButtons?.length > 0 &&
                      allButtons.slice(0, 2)?.map((item) => (
                        <div className="preview-button-wrapper" key={item?.key}>
                          {item?.id === 'PHONE_NUMBER' && <PhoneOutlined />}{' '}
                          {item?.id === 'QUICK_REPLY' && 'Quick replay'}
                          {item?.id === 'COPY_CODE' && 'Copy'}
                          {item?.key}
                        </div>
                      ))}
                    {allButtons?.length > 2 && (
                      <div className="preview-button-wrapper">
                        See all options
                      </div>
                    )}
                  </div>
                }
              />
            </Col>
          )}
        </Row>
      </Card>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={() => handleProtectedNavigation(true, navigate, -1)}
        handleCancel={() => setIsPrompt(false)}
      />
    </LoaderComponent>
  );
};

export default TemplateForm;
