import { PlusOutlined } from '@ant-design/icons';
import { Form, Upload } from 'antd';
import { isArray } from 'lodash';
import React from 'react';
import { messageContext } from '../../../components/AppContextHolder';

const UploadFormItem = ({ form, selectedType, current, disabled }) => {
  const filedValue = Form.useWatch(['value', 'headerUpload'], form);
  return (
    <Form.Item
      name={['value', 'headerUpload']}
      accept={selectedType?.accept}
      label={`Add ${selectedType?.title}`}
      hidden={current === 0}
      rules={[
        {
          required: true,
          message: `Please add ${selectedType?.title}`,
        },
      ]}
      normalize={(value) => {
        const { name, size } = value?.[0]?.originFileObj || {};
        const isSizeLimit = size / 1024 / 1024 <= selectedType?.sizeLimit;
        if (isSizeLimit) {
          return value;
        }
        if (!isSizeLimit && name && size) {
          messageContext?.error(
            `${selectedType?.title} size should be less than ${selectedType?.sizeLimit} mb!`,
          );
        }
        return [];
      }}
      getValueFromEvent={(e) => {
        if (isArray(e)) {
          return e;
        }
        return e?.fileList;
      }}
      valuePropName="fileList"
    >
      <Upload
        maxCount={1}
        disabled={disabled}
        className="upload-image-container"
        accept={selectedType?.accept}
        showUploadList={{
          showPreviewIcon: false,
          showRemoveIcon: true,
        }}
        listType="picture-card"
        beforeUpload={() => false}
        onChange={() => form?.resetFields(['audio', 'audioUrl'])}
      >
        {!(filedValue?.length > 0) && (
          <div className="d-flex align-center justify-center flex-vertical upload-content">
            <PlusOutlined />
            <p>Upload {selectedType?.title}</p>
          </div>
        )}
      </Upload>
    </Form.Item>
  );
};

export default UploadFormItem;
