import { gql } from '@apollo/client';

export const GET_IMPORT_EXPORTS = gql`
  query ImportsExportJobs(
    $sort: ImportExportJobsSort
    $filter: ImportExportJobsFilter
  ) {
    importsExportJobs(sort: $sort, filter: $filter) {
      count
      data {
        id
        status
        error
        requester {
          id
          firstName
          lastName
        }
        failedCount
        failedFileName
        failedFileUrl
        sourceFileName
        sourceFileUrl
        totalCount
        updatedAt
        updatedCount
        entity
        createdCount
        createdAt
        type
      }
    }
  }
`;

export const GET_USER_CONTACTS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      users {
        id
        firstName
        lastName
      }
      count
    }
  }
`;
