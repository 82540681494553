import { useQuery } from '@apollo/client';
import { Badge, Card, Descriptions, Divider, Typography } from 'antd';
import { camelCase, startCase } from 'lodash';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROLE_KEYS, ROUTES } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import LoaderComponent from '../../components/LoaderComponent';
import MasterFormTitle from '../master-list/components/MasterFormTitle';
import {
  GET_ADMIN_SEGMENT,
  GET_SEGMENT_CONTACTS,
  GET_USER_SEGMENT,
} from './graphql/Queries';

const { Title } = Typography;

function ViewSegment() {
  const { getCurrentUser } = useContext(AppContext);
  const userRole = getCurrentUser();
  const { id } = useParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    search: '',
  });
  const [listSort, setListSort] = useState({
    sortOn: 'name',
    sortBy: 'ASC',
  });

  const { loading: adminFetchLoading, data: adminListingData } = useQuery(
    userRole?.role === ROLE_KEYS.USER ? GET_USER_SEGMENT : GET_ADMIN_SEGMENT,
    {
      variables: {
        where: { id },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { loading: contactsFetchLoading, data: segmentContactsData } = useQuery(
    GET_SEGMENT_CONTACTS,
    {
      skip: userRole?.role === ROLE_KEYS.USER,
      variables: {
        sort: {
          field: listSort?.sortOn,
          order: listSort?.sortBy,
        },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
          search: paginationConfig?.search,
        },
        where: { segmentId: id },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig((prev) => ({
      ...prev,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };
  const dataKey =
    userRole?.role === ROLE_KEYS.USER ? 'segment' : 'segmentAdmin';

  const { name, description } = adminListingData?.[dataKey] || {};

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, allValues) => {
        const namePrefix = startCase(camelCase(allValues?.namePrefix));
        const fullName =
          namePrefix && allValues?.name
            ? `${namePrefix}. ${allValues?.name}`
            : allValues?.name;

        return fullName ?? '-';
      },
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Primary Contact',
      dataIndex: 'primaryContactNoPostfix',
      key: 'primaryContactNoPostfix',
      render: (_, values) =>
        `${values?.countryCode} ****** ${values?.primaryContactNoPostfix}`,
    },
  ];

  return (
    <LoaderComponent spinning={adminFetchLoading || contactsFetchLoading}>
      <MasterFormTitle
        navigation={ROUTES.SEGMENTS}
        title="Segment Info"
        isView
      />
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Descriptions column={1}>
            <Descriptions.Item label="Name">{name}</Descriptions.Item>
            <Descriptions.Item label="description">
              {description || '-'}
            </Descriptions.Item>
          </Descriptions>
        </div>

        <Divider className="m-0" />

        <div className="pl-24 pr-24">
          <Title level={5}>
            <div className="d-flex align-center gap-8">
              Contact list
              <Badge
                count={segmentContactsData?.segmentContacts?.count
                  ?.toString()
                  ?.padStart(2, '0')}
              />
            </div>
          </Title>
          <CommonTable
            scroll={{ y: 400 }}
            columns={columns}
            data={segmentContactsData?.segmentContacts?.data}
            onChange={handleTableChange}
            paginationConfig={{
              ...paginationConfig,
              total: segmentContactsData?.segmentContacts?.count,
              showSizeChanger: false,
            }}
          />
        </div>
      </Card>
    </LoaderComponent>
  );
}

export default ViewSegment;
