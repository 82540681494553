import { gql } from '@apollo/client';

export const CREATE_ADMIN_CAMPAIGN = gql`
  mutation CreateCampaign($data: CreateCampaignInput!) {
    createCampaign(data: $data) {
      message
    }
  }
`;

export const DELETE_ADMIN_CAMPAIGN = gql`
  mutation DeleteCampaignAdmin($where: CampaignWhereUniqueInput!) {
    deleteCampaignAdmin(where: $where) {
      message
    }
  }
`;

export const EDIT_ADMIN_CAMPAIGN = gql`
  mutation UpdateCampaignAdmin(
    $data: UpdateCampaignInput!
    $where: CampaignWhereUniqueInput!
  ) {
    updateCampaignAdmin(data: $data, where: $where) {
      message
    }
  }
`;

export const EDIT_USER_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $data: UpdateCampaignInput!
    $where: CampaignWhereUniqueInput!
  ) {
    updateCampaign(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_USER_CAMPAIGN = gql`
  mutation DeleteCampaign($where: CampaignWhereUniqueInput!) {
    deleteCampaign(where: $where) {
      message
    }
  }
`;

export const ADD_ADMIN_MESSAGE = gql`
  mutation CreateMessageAdmin(
    $where: CampaignUniqueInput!
    $data: CreateMessageInputAdmin!
  ) {
    createMessageAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const ADD_MESSAGE = gql`
  mutation CreateMessage(
    $where: CampaignUniqueInput!
    $data: CreateMessageInput!
  ) {
    createMessage(where: $where, data: $data) {
      message
    }
  }
`;

export const SCHEDULE_MESSAGE_ADMIN = gql`
  mutation ScheduleMessageAdmin($where: MessageUniqueInput!) {
    scheduleMessageAdmin(where: $where) {
      message
    }
  }
`;

export const SCHEDULE_MESSAGE = gql`
  mutation ScheduleMessage($where: MessageUniqueInput!) {
    scheduleMessage(where: $where) {
      message
    }
  }
`;

export const SEND_MESSAGE_ADMIN = gql`
  mutation SendMessage($where: MessageUniqueInput!) {
    sendMessage(where: $where) {
      message
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($where: MessageUniqueInput!) {
    sendMessage(where: $where) {
      message
    }
  }
`;

export const DUPLICATE_MESSAGE_ADMIN = gql`
  mutation DuplicateMessageAdmin($where: MessageUniqueInput!) {
    duplicateMessageAdmin(where: $where) {
      message
      data {
        id
      }
    }
  }
`;

export const DUPLICATE_MESSAGE = gql`
  mutation DuplicateMessage($where: MessageUniqueInput!) {
    duplicateMessage(where: $where) {
      message
      data {
        id
      }
    }
  }
`;

export const DELETE_MESSAGE_ADMIN = gql`
  mutation DeleteMessageAdmin($where: MessageUniqueInput!) {
    deleteMessageAdmin(where: $where) {
      message
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($where: MessageUniqueInput!) {
    deleteMessage(where: $where) {
      message
    }
  }
`;

export const PAUSE_MESSAGE_ADMIN = gql`
  mutation PauseMessageAdmin($where: MessageUniqueInput!) {
    pauseMessageAdmin(where: $where) {
      message
    }
  }
`;

export const PAUSE_MESSAGE = gql`
  mutation PauseMessage($where: MessageUniqueInput!) {
    pauseMessage(where: $where) {
      message
    }
  }
`;

export const RESUME_MESSAGE_ADMIN = gql`
  mutation ResumeMessageAdmin($where: MessageUniqueInput!) {
    resumeMessageAdmin(where: $where) {
      message
    }
  }
`;

export const RESUME_MESSAGE = gql`
  mutation ResumeMessage($where: MessageUniqueInput!) {
    resumeMessage(where: $where) {
      message
    }
  }
`;

export const UPDATE_MESSAGE_ADMIN = gql`
  mutation UpdateMessageAdmin(
    $where: MessageUniqueInput!
    $data: UpdateMessageInput
  ) {
    updateMessageAdmin(where: $where, data: $data) {
      message
    }
  }
`;

export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($where: MessageUniqueInput!) {
    updateMessage(where: $where) {
      message
    }
  }
`;
export const ACTIVE_LOCATIONS_LIST = gql`
  query ActiveLocations($filter: ActiveLocationsFilter) {
    activeLocations(filter: $filter) {
      locations {
        key
        id
        name
      }
      count
    }
  }
`;
