import { Input } from 'antd';
import React from 'react';
import useAutoComplete from '../../../hooks/useAutoComplete';

const HeaderInput = ({ onChange, value, disabled }) => {
  const autoComplited = useAutoComplete();
  return (
    <Input
      showCount
      maxLength={60}
      placeholder="Enter header text"
      value={value}
      disabled={disabled}
      onChange={(e) => {
        const header = e?.target?.value;
        if (autoComplited) {
          const regexFindHed = /\{\{(?!\d+\}\})/g;
          const replacementHedString = `{{1}}`;
          const remove = header?.includes(replacementHedString);
          const resulthead = header?.replace(
            regexFindHed,
            remove ? '' : replacementHedString,
          );
          onChange(resulthead);
        } else {
          onChange(header);
        }
      }}
    />
  );
};

export default HeaderInput;
