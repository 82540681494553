import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import { GET_USER_CONTACTS } from '../graphql/Queries';

const variableSelector = ({ skip, limit, search }) => ({
  filter: { skip, limit, search },
});
const dataSelector = (data) =>
  data?.map(({ firstName, lastName, id }) => ({
    label: `${firstName || ''} ${lastName || ''}`,
    value: id,
  }));

const dataKeys = {
  data: 'users',
  count: 'count',
  records: 'users',
};

const UserFilter = (props) => (
  <InfiniteSelect
    query={GET_USER_CONTACTS}
    variableSelector={variableSelector}
    dataKeys={dataKeys}
    dataSelector={dataSelector}
    placeholder="Select User"
    {...props}
  />
);

export default UserFilter;
