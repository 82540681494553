import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import React from 'react';
import useRouter from '../../../hooks/useRouter';

const { Title } = Typography;
function MasterFormTitle({
  navigation = {},
  title = '',
  isEdit = false,
  isView = false,
}) {
  const { navigate } = useRouter();
  const handleBack = () => {
    navigate(navigation);
  };

  return (
    <>
      <Title className="form-title-header d-flex align-center gap-8" level={3}>
        <Button
          type="text"
          shape="circle"
          icon={<ArrowLeftOutlined />}
          onClick={handleBack}
        />
        {isEdit ? 'Edit' : `${isView ? '' : 'Add'}`} {title}
      </Title>
    </>
  );
}

export default MasterFormTitle;
