import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { MODULES_KEY, PERMISSION_OBJECT, ROUTES } from './common/constants';
import AccessControl from './components/AccessControl';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import ResetPassword from './modules/auth/ResetPassword';
import { GET_CURRENT_USER } from './modules/auth/graphql/Queries';
import AddCampaign from './modules/campaign/AddCampaign';
import AddMessage from './modules/campaign/AddMessage';
import CampaignListing from './modules/campaign/CampaignListing';
import EditCampaign from './modules/campaign/EditCampaign';
import EditMessage from './modules/campaign/EditMessage';
import Message from './modules/campaign/Message';

import MessageContacts from './modules/campaign/MessageContacts';
import AddContact from './modules/contacts/AddContact';
import Contacts from './modules/contacts/Contacts';
import ContactLogs from './modules/contacts/ContactsLogs';
import EditContact from './modules/contacts/EditContact';
import './modules/contacts/contacts.less';
import Dashboard from './modules/dashboard/Dashboard';
import ImportExport from './modules/import-export/ImportExport';
import CreateLocation from './modules/master-list/locations/CreateLocation';
import EditLocation from './modules/master-list/locations/EditLocation';
import LocationsListing from './modules/master-list/locations/LocationsListing';
import ViewLocation from './modules/master-list/locations/ViewLocation';
import CreateSpecialization from './modules/master-list/specializations/CreateSpecialization';
import EditSpecialization from './modules/master-list/specializations/EditSpecialization';
import SpecializationListing from './modules/master-list/specializations/SpecializationListing';
import ViewSpecialization from './modules/master-list/specializations/ViewSpecialization';
import AddTag from './modules/master-list/tags/AddTag';
import EditTag from './modules/master-list/tags/EditTag';
import TagsListing from './modules/master-list/tags/TagsListing';
import ViewTag from './modules/master-list/tags/ViewTag';
import Profile from './modules/profile/Profile';
import CreateRole from './modules/role-permissions/CreateRole';
import EditRole from './modules/role-permissions/EditRole';
import RoleListing from './modules/role-permissions/RoleListing';
import SegmentListing from './modules/segments/SegmentListing';
import ViewSegment from './modules/segments/ViewSegment';
import AddUser from './modules/superadmin/AddUser';
import EditUser from './modules/superadmin/EditUser';
import SuperAdminContactList from './modules/superadmin/SuperAdmin';
import CretaeTemplate from './modules/template/CretaeTemplate';
import EditTemplate from './modules/template/EditTemplate';
import Template from './modules/template/Template';
// commenting for future use - SENTRY_INIT
// const MyFallbackComponent = ({ error, componentStack }) => (
//   <Result
//     status="error"
//     title={ERROR_PAGE_TITLE}
//     subTitle={ERROR_PAGE_SUBTITLE}
//   >
//     <div className="desc">
//       <Paragraph>
//         <Typography.Title level={4}> Error:</Typography.Title>
//       </Paragraph>
//       <Paragraph>
//         <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
//         {error?.message?.toString()}
//       </Paragraph>
//       <Paragraph>
//         <Typography.Title level={4}> Stacktrace:</Typography.Title>
//       </Paragraph>
//       <Paragraph>
//         <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
//         {componentStack}
//       </Paragraph>
//     </div>
//   </Result>
// );

const RoutesCollection = () => {
  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      // Nested routes use a children property
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.RESET,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.RESET, element: <ResetPassword /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES?.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> }],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.MAIN,
              element: (
                <AccessControl
                  modulekey={MODULES_KEY?.DASHBOARD_ANALYTICS}
                  allowedPermissions={[PERMISSION_OBJECT?.READ]}
                  showNoAccess
                >
                  <Dashboard />
                </AccessControl>
              ),
            },
          ],
        },
      ],
    },
  ];

  const CONTACT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.CONTACTS,
              children: [
                {
                  path: ROUTES?.CONTACTS,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CONTACT_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <Contacts />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.ADD_CONTACT,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CONTACT_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <AddContact />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.EDIT_CONTACT,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CONTACT_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditContact />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.CONTACT_LOGS,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CONTACT_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <ContactLogs />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PROFILE,
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ];

  const IMPORT_EXPORT = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.IMPORT_EXPORT,
              element: <ImportExport />,
            },
          ],
        },
      ],
    },
  ];

  const ROLE_PERMISSION = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.ROLE_PERMISSION,
              children: [
                { path: ROUTES?.ROLE_PERMISSION, element: <RoleListing /> },
                {
                  path: ROUTES?.CREATE_ROLE,
                  element: <CreateRole />,
                },
                { path: ROUTES?.EDIT_ROLE, element: <EditRole /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const SPECIALIZATION = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.SPECIALIZATION,
              children: [
                {
                  path: ROUTES?.SPECIALIZATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <SpecializationListing />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.CREATE_SPECIALIZATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <CreateSpecialization />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.VIEW_SPECIALIZATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <ViewSpecialization />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.EDIT_SPECIALIZATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditSpecialization />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];
  const LOCATIONS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.LOCATIONS,
              children: [
                {
                  path: ROUTES?.LOCATIONS,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <LocationsListing />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.CREATE_LOCATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <CreateLocation />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.EDIT_LOCATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditLocation />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.VIEW_LOCATION,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <ViewLocation />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const TAGS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.TAGS,
              children: [
                {
                  path: ROUTES?.TAGS,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <TagsListing />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.CREATE_TAG,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <AddTag />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.EDIT_TAG,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditTag />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.VIEW_TAG,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <ViewTag />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const SEGMENTS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.SEGMENTS,
              children: [
                {
                  path: ROUTES?.SEGMENTS,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.SEGMENT_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <SegmentListing />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.VIEW_SEGMENT,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.SEGMENT_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <ViewSegment />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const SUPER_ADMIN__MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.SUPER_ADMIN,
              children: [
                {
                  path: ROUTES?.SUPER_ADMIN,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <SuperAdminContactList />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.ADD_SUPER_ADMIN_CONTACT,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <AddUser />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.EDIT_SUPER_ADMIN_CONTACT,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.ADMIN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditUser />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const TEMPLATE__MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.TEMPLATE,
              children: [
                {
                  path: ROUTES?.TEMPLATE,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.TEMPLATE_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <Template />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES.ADD_TEMPLATE,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.TEMPLATE_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <CretaeTemplate />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES.EDIT_TEMPLATE,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.TEMPLATE_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditTemplate />
                    </AccessControl>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CAMPAIGN_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES.CAMPAIGN,
              children: [
                {
                  path: ROUTES?.CAMPAIGN,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CAMPAIGN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <CampaignListing />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.ADD_CAMPAIGN,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CAMPAIGN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <AddCampaign />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.EDIT_CAMPAIGN,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CAMPAIGN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditCampaign />
                    </AccessControl>
                  ),
                },

                {
                  path: ROUTES.CAMPAIGN_MESSAGE,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CAMPAIGN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.READ]}
                      showNoAccess
                    >
                      <Message />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES.CAMPAIGN_ADD_MESSAGE,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CAMPAIGN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.CREATE]}
                      showNoAccess
                    >
                      <AddMessage />
                    </AccessControl>
                  ),
                },
                {
                  path: ROUTES?.CAMPAIGN_EDIT_MESSAGE,
                  element: (
                    <AccessControl
                      modulekey={MODULES_KEY?.CAMPAIGN_MANAGEMENT}
                      allowedPermissions={[PERMISSION_OBJECT?.UPDATE]}
                      showNoAccess
                    >
                      <EditMessage />
                    </AccessControl>
                  ),
                },
                {
                  path: `${ROUTES?.CAMPAIGN_MESSAGE}${ROUTES?.CONTACTS_LIST}`,
                  element: <MessageContacts />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const element = useRoutes([
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...CONTACT_MODULES,
    ...USER_MODULES,
    ...IMPORT_EXPORT,
    ...ROLE_PERMISSION,
    ...SPECIALIZATION,
    ...LOCATIONS,
    ...SEGMENTS,
    ...TAGS,
    ...SUPER_ADMIN__MODULES,
    ...TEMPLATE__MODULES,
    ...CAMPAIGN_MODULES,
    ...OTHER_MODULES,
  ]);
  return element;
};

const RoutesWrapper = () => {
  const {
    initializeAuth,
    getToken,
    getCurrentUser: localUserData,
  } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const idToken = getToken();
  const userData = localUserData();
  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.userProfile?.forcePasswordReset) {
        history?.push(ROUTES.RESET);
      }
      initializeAuth(idToken, {
        ...res?.userProfile,
        defaultLocation: userData?.defaultLocation || null,
      });
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    // commenting for future use - SENTRY_INIT
    // <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
    // </Sentry.ErrorBoundary>
    <Router>
      {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
    </Router>
  );
};
export default RoutesWrapper;
