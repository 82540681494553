import React from 'react';

const Send = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33993 2.82L12.0466 5.67333C14.6066 6.95333 14.6066 9.04666 12.0466 10.3267L6.33993 13.18C2.49993 15.1 0.933265 13.5267 2.85327 9.69333L3.43326 8.54C3.57993 8.24667 3.57993 7.76 3.43326 7.46666L2.85327 6.30666C0.933265 2.47333 2.5066 0.899998 6.33993 2.82Z"
      stroke="#14110F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.62671 8H7.22671"
      stroke="#14110F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Send;
