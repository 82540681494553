import { Button, Result } from 'antd';
import React from 'react';
import { ROUTES } from '../common/constants';
import useRouter from '../hooks/useRouter';
import useVerifyPermissions from '../hooks/useVerifyPermissions';

const AccessControl = ({
  children,
  modulekey,
  allowedPermissions,
  showNoAccess = false,
}) => {
  const { navigate } = useRouter();
  const hasPermission = useVerifyPermissions({
    modulekey,
    allowedPermissions,
  });

  if (!hasPermission) {
    if (showNoAccess) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate(ROUTES?.CONTACTS)}>
              Back Home
            </Button>
          }
        />
      );
    }
    return;
  }

  return <>{children}</>;
};

export default AccessControl;
