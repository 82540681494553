import React from 'react';
import { useParams } from 'react-router-dom';
import TemplateForm from './components/TemplateForm';

const EditTemplate = () => {
  const { id } = useParams();

  return (
    <div>
      <TemplateForm edit={id} />
    </div>
  );
};

export default EditTemplate;
