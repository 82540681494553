import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Typography,
} from 'antd';
import { camelCase, startCase } from 'lodash';
import React, { useState } from 'react';
import { ADD_OPTIONS_DATA, OPTIONS, ROUTES } from '../../../common/constants';
import {
  containsSpecialCharacters,
  handleProtectedNavigation,
} from '../../../common/utils';
import CommonTable from '../../../components/CommonTable';
import RouterPrompt from '../../../components/RouterPrompt';
import useRouter from '../../../hooks/useRouter';
import { CREATE_ROLE, UPDATE_ROLE } from '../graphql/Mutations';
import { GET_ROLE, ROLE_MODULES } from '../graphql/Queries';

const { TextArea } = Input;

const { Title } = Typography;

function RolePermissionForm({ slug = '' }) {
  const { navigate } = useRouter();
  const [form] = Form.useForm();
  const [isPrompt, setIsPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [permissions, setPermissions] = useState(ADD_OPTIONS_DATA);

  const { loading: editDataLoading } = useQuery(GET_ROLE, {
    skip: !slug,
    variables: { where: { id: slug } },
    onCompleted: (res) => {
      form.setFieldsValue({
        name: res?.role?.name,
        description: res?.role?.description,
      });
      setPermissions(
        Object.fromEntries(
          res?.role?.permissions?.map(
            ({ module, access: { __typename, ...access } = {} }) => [
              module,
              access,
            ],
          ) || [],
        ),
      );
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [createRoleMutation, { loading: addLoading }] = useMutation(
    CREATE_ROLE,
    {
      onCompleted(res) {
        if (res?.createRole?.role?.id) {
          navigate(ROUTES.ROLE_PERMISSION);
        }
      },
      onError() {},
    },
  );

  const [updateRole] = useMutation(UPDATE_ROLE, {
    onCompleted() {
      navigate(ROUTES.ROLE_PERMISSION);
    },
  });
  const { loading: modulesLoading, data: moduleApiData } = useQuery(
    ROLE_MODULES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const ADD_OPTIONS = moduleApiData?.modules?.modules?.map(({ key }) => ({
    module: key,
    access: OPTIONS,
  }));

  const handleCheckboxChange = (module, key, checked) => {
    setPermissions((prev) => ({
      ...prev,
      [module]: {
        ...prev?.[module],
        [key]: checked,
      },
    }));
  };

  const onFinish = async (value) => {
    try {
      const mutationKey = slug ? updateRole : createRoleMutation;
      mutationKey({
        variables: {
          ...(slug ? { where: { id: slug } } : {}),
          data: {
            name: value?.name,
            description: value?.description,
            permissions: Object.keys(permissions).map((module) => ({
              access: permissions[module],
              module,
            })),
          },
        },
      });
    } catch (error) {
      //
    }
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!showPrompt, navigate, -1));
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };

  const validateRoleName = (_, value) => {
    if (value && containsSpecialCharacters(value)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('Role Name should not contain special characters');
    }
    return Promise.resolve();
  };

  const columns = [
    {
      key: 'module',
      title: 'Module',
      dataIndex: 'module',
      render: (_, record) => startCase(camelCase(record?.module)),
    },
    {
      key: 'access',
      title: 'Permissions',
      dataIndex: 'access',
      render: (_, res) => (
        <Flex gap={8}>
          {OPTIONS.map(({ label, value: key, deps, disabledDeps }) => (
            <Checkbox
              key={key}
              checked={permissions?.[res?.module]?.[key]}
              disabled={disabledDeps?.some(
                (moduleKey) => permissions?.[res?.module]?.[moduleKey],
              )}
              onChange={(event) => {
                const { checked } = event.target;
                handleCheckboxChange(res?.module, key, checked);
                if (checked)
                  deps?.forEach((keyValue) =>
                    handleCheckboxChange(res?.module, keyValue, checked),
                  );
              }}
            >
              {label}
            </Checkbox>
          ))}
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Card
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={handleBack}
            />
            {slug ? 'Edit Role' : 'Add Role'}
          </Title>
        }
      >
        <div className="card-body-wrapper">
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="full-width mb-32"
            onValuesChange={handleShowPrompt}
            disabled={addLoading}
          >
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: 'Please enter Role Name' },
                  { validator: validateRoleName },
                ]}
              >
                <Input placeholder="Enter Name" allowClear />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Description" name="description">
                <TextArea
                  cols={30}
                  rows={5}
                  placeholder="Enter Role Description"
                  allowClear
                />
              </Form.Item>
            </Col>
          </Form>
          <CommonTable
            loadingData={editDataLoading || modulesLoading}
            columns={columns}
            data={ADD_OPTIONS}
          />
          <div className="d-flex justify-start mt-32">
            <Button disabled={addLoading} onClick={handleBack} className="mr-8">
              Cancel
            </Button>

            <Button
              loading={addLoading}
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.submit();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Card>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}

export default RolePermissionForm;
