import { gql } from '@apollo/client';

export const CREATE_SPECIALIZATION = gql`
  mutation CreateSpecialization($data: CreateSpecializationInput!) {
    createSpecialization(data: $data) {
      message
    }
  }
`;

export const UPDATE_SPECIALIZATION = gql`
  mutation UpdateSpecialization(
    $data: UpdateSpecializationInput!
    $where: SpecializationWhereUniqueInput!
  ) {
    updateSpecialization(data: $data, where: $where) {
      message
    }
  }
`;

export const TOGGLE_SPECIALIZATION_STATUS = gql`
  mutation ToggleSpecializationStatus(
    $data: ToggleSpecializationStatus!
    $where: SpecializationWhereUniqueInput!
  ) {
    toggleSpecializationStatus(data: $data, where: $where) {
      message
    }
  }
`;
