import { EditOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Form, Input, Modal, Space, Tooltip } from 'antd';
import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';
import { MODULES_KEY, PERMISSION_OBJECT, ROUTES } from '../../common/constants';
import { messageContext } from '../../components/AppContextHolder';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import useVerifyPermissions from '../../hooks/useVerifyPermissions';
import MessageCard from './components/MessageCard';
import {
  DELETE_ADMIN_CAMPAIGN,
  DELETE_MESSAGE,
  DELETE_MESSAGE_ADMIN,
  DELETE_USER_CAMPAIGN,
  EDIT_ADMIN_CAMPAIGN,
  EDIT_USER_CAMPAIGN,
} from './graphql/Mutations';
import {
  GET_MESSAGE_CAMP_NAME,
  GET_MESSAGE_CAMP_NAME_ADMIN,
} from './graphql/Queries';
import './styles/campaign.less';

function Message({
  loadingMore,
  loading,
  data,
  fetchMore,
  hasMore,
  refetch,
  permission,
}) {
  const [form] = Form.useForm();
  const { navigate } = useRouter();

  const { id } = useParams();
  const [isModal, setIsModal] = useState(false);
  const [isCampModal, setIsCampModal] = useState(false);
  const [deleteIdx, setDeleteIdx] = useState('');
  const [IsEditCampModal, setIsEditCampModal] = useState(false);
  const messageCreatePermission = useVerifyPermissions({
    modulekey: MODULES_KEY.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT.CREATE,
  });
  const messageEditPermission = useVerifyPermissions({
    modulekey: MODULES_KEY.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT.UPDATE,
  });
  const messageDeletePermission = useVerifyPermissions({
    modulekey: MODULES_KEY.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT.UPDATE,
  });

  const campNamedataKey = permission ? 'campaignAdmin' : 'campaign';
  const [deleteMessageAdmin] = useMutation(
    permission ? DELETE_MESSAGE_ADMIN : DELETE_MESSAGE,
    {
      onError() {},
    },
  );

  const { data: campName, refetch: campNamerefetch } = useQuery(
    permission ? GET_MESSAGE_CAMP_NAME_ADMIN : GET_MESSAGE_CAMP_NAME,
    {
      skip: !id,
      variables: {
        where: {
          id,
        },
      },
      onError() {},
      fetchPolicy: 'network-only',
    },
  );

  const [deleteCampaign] = useMutation(
    permission ? DELETE_ADMIN_CAMPAIGN : DELETE_USER_CAMPAIGN,
    {
      onError() {},
    },
  );

  const deleteMessageHandler = (idx) => {
    setDeleteIdx(idx);
    setIsModal(true);
  };

  const [updateCampaign, { loading: updateLoading }] = useMutation(
    permission ? EDIT_ADMIN_CAMPAIGN : EDIT_USER_CAMPAIGN,
    {
      onCompleted() {
        setIsEditCampModal(false);
        campNamerefetch();
      },
      onError() {},
    },
  );

  const onChangeCampName = async (value) => {
    await updateCampaign({
      variables: {
        data: {
          name: value?.campaignName,
        },
        ...(id
          ? {
              where: {
                id,
              },
            }
          : {}),
      },
    });
  };

  return (
    <>
      <Card
        loading={loading}
        className="ant-body-scroll"
        title={
          <div>
            {messageCreatePermission ? (
              <>
                <div className="contact-sidebar d-flex justify-between">
                  <h3>
                    {!updateLoading && (
                      <span>
                        {campName?.[campNamedataKey]?.name}{' '}
                        {messageEditPermission && (
                          <Tooltip
                            title="Edit campaign name"
                            placement="top"
                            zIndex={4}
                          >
                            <EditOutlined
                              onClick={() => {
                                form.setFieldValue(
                                  'campaignName',
                                  campName?.[campNamedataKey]?.name,
                                );
                                setIsEditCampModal(true);
                              }}
                              className="pointer"
                            />
                          </Tooltip>
                        )}
                      </span>
                    )}
                  </h3>
                  <Space>
                    <div className="contact-filter-right">
                      <div className="contact-filter">
                        <Button
                          className="ml-8"
                          key="1"
                          type="primary"
                          onClick={() => {
                            navigate(
                              `${ROUTES.CAMPAIGN}/${id}${ROUTES.MESSAGE}/add`,
                            );
                          }}
                        >
                          + Add New Message
                        </Button>
                        <Button
                          className="ml-8"
                          key="1"
                          onClick={() => setIsCampModal(true)}
                        >
                          Delete Campaign
                        </Button>
                      </div>
                    </div>
                  </Space>
                </div>
              </>
            ) : null}
          </div>
        }
      >
        <div className="card-body-wrapper">
          {!loading ? (
            data?.map((item) => (
              <div key={item?.id} className="msg-card-container">
                <MessageCard
                  messageList={item}
                  deleteMessageHandler={deleteMessageHandler}
                  id={id}
                  refetch={refetch}
                  messageEditPermission={messageEditPermission}
                  messageDeletePermission={messageDeletePermission}
                />
              </div>
            ))
          ) : (
            <LoaderComponent />
          )}
          {!loading && !loadingMore && hasMore && (
            <InView
              as="div"
              onChange={(inView) => {
                if (inView) {
                  fetchMore();
                }
              }}
              triggerOnce
            />
          )}
        </div>
      </Card>
      <Modal
        title="Delete message"
        okText="Yes"
        cancelText="No"
        open={isModal}
        onOk={async () => {
          try {
            await deleteMessageAdmin({
              variables: {
                where: {
                  id: deleteIdx,
                },
              },
            });
            setIsCampModal(false);
          } catch (error) {
            messageContext?.error(error?.Message);
          } finally {
            setIsModal(false);
            await refetch();
          }
        }}
        onCancel={() => {
          setIsModal(false);
        }}
      >
        Are you sure you want to delete this message?
      </Modal>
      <Modal
        open={isCampModal}
        onOk={async () => {
          try {
            await deleteCampaign({
              variables: {
                where: {
                  id,
                },
              },
            });
            setIsCampModal(false);
            navigate(-1);
          } catch (error) {
            //
          }
        }}
        closable
        onCancel={() => setIsCampModal(false)}
        title="Delete Campaign"
        okText="Delete"
      >
        Are you sure want to delete this campaign ?
      </Modal>
      <Modal
        open={IsEditCampModal}
        onOk={() => form.submit()}
        closable
        onCancel={() => setIsEditCampModal(false)}
        title="Campaign name"
        okText="Update"
      >
        <Form
          onFinish={onChangeCampName}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Form.Item
            name="campaignName"
            label="Name"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Enter campaign name',
              },
            ]}
          >
            <Input placeholder="Enter campaign name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Message;
