import React from 'react';

const Calendar = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 9.66667C5.58 9.66667 5.49333 9.64668 5.41333 9.61335C5.32667 9.58001 5.26 9.53333 5.19333 9.47333C5.07333 9.34667 5 9.18 5 9C5 8.91334 5.02 8.82667 5.05333 8.74667C5.08667 8.66667 5.13333 8.59334 5.19333 8.52668C5.26 8.46668 5.32667 8.42 5.41333 8.38666C5.65333 8.28666 5.95333 8.34001 6.14 8.52668C6.26 8.65334 6.33333 8.82667 6.33333 9C6.33333 9.04 6.32667 9.08668 6.32 9.13335C6.31333 9.17335 6.3 9.21334 6.28 9.25334C6.26667 9.29334 6.24667 9.33334 6.22 9.37334C6.2 9.40667 6.16667 9.44 6.14 9.47333C6.01333 9.59333 5.84 9.66667 5.66667 9.66667Z"
      fill="#14110F"
    />
    <path
      d="M7.99992 9.66667C7.91325 9.66667 7.82658 9.64668 7.74658 9.61335C7.65992 9.58002 7.59325 9.53333 7.52659 9.47333C7.40659 9.34667 7.33325 9.18001 7.33325 9.00001C7.33325 8.91334 7.35325 8.82667 7.38659 8.74667C7.41992 8.66667 7.46659 8.59335 7.52659 8.52668C7.59325 8.46668 7.65992 8.42 7.74658 8.38666C7.98658 8.28 8.28658 8.34001 8.47325 8.52668C8.59325 8.65335 8.66659 8.82667 8.66659 9.00001C8.66659 9.04001 8.65992 9.08668 8.65325 9.13335C8.64659 9.17335 8.63325 9.21334 8.61325 9.25334C8.59992 9.29334 8.57992 9.33334 8.55325 9.37334C8.53325 9.40667 8.49992 9.44 8.47325 9.47333C8.34658 9.59333 8.17325 9.66667 7.99992 9.66667Z"
      fill="#14110F"
    />
    <path
      d="M10.3334 9.66667C10.2467 9.66667 10.1601 9.64668 10.0801 9.61335C9.99341 9.58002 9.92675 9.53333 9.86008 9.47333C9.83342 9.44 9.80675 9.40667 9.78008 9.37334C9.75341 9.33334 9.73342 9.29334 9.72008 9.25334C9.70008 9.21334 9.68675 9.17335 9.68008 9.13335C9.67342 9.08668 9.66675 9.04001 9.66675 9.00001C9.66675 8.82667 9.74008 8.65335 9.86008 8.52668C9.92675 8.46668 9.99341 8.42 10.0801 8.38666C10.3267 8.28 10.6201 8.34001 10.8067 8.52668C10.9267 8.65335 11.0001 8.82667 11.0001 9.00001C11.0001 9.04001 10.9934 9.08668 10.9867 9.13335C10.9801 9.17335 10.9667 9.21334 10.9467 9.25334C10.9334 9.29334 10.9134 9.33334 10.8867 9.37334C10.8667 9.40667 10.8334 9.44 10.8067 9.47333C10.6801 9.59333 10.5067 9.66667 10.3334 9.66667Z"
      fill="#14110F"
    />
    <path
      d="M5.66667 12C5.58 12 5.49333 11.98 5.41333 11.9467C5.33333 11.9134 5.26 11.8667 5.19333 11.8067C5.07333 11.68 5 11.5067 5 11.3333C5 11.2467 5.02 11.16 5.05333 11.08C5.08667 10.9933 5.13333 10.92 5.19333 10.86C5.44 10.6134 5.89333 10.6134 6.14 10.86C6.26 10.9867 6.33333 11.16 6.33333 11.3333C6.33333 11.5067 6.26 11.68 6.14 11.8067C6.01333 11.9267 5.84 12 5.66667 12Z"
      fill="#14110F"
    />
    <path
      d="M7.99992 12C7.82659 12 7.65325 11.9267 7.52659 11.8067C7.40659 11.68 7.33325 11.5067 7.33325 11.3333C7.33325 11.2467 7.35325 11.16 7.38659 11.08C7.41992 10.9933 7.46659 10.92 7.52659 10.86C7.77325 10.6134 8.22658 10.6134 8.47325 10.86C8.53325 10.92 8.57992 10.9933 8.61325 11.08C8.64658 11.16 8.66659 11.2467 8.66659 11.3333C8.66659 11.5067 8.59325 11.68 8.47325 11.8067C8.34658 11.9267 8.17325 12 7.99992 12Z"
      fill="#14110F"
    />
    <path
      d="M10.3334 12C10.1601 12 9.98675 11.9267 9.86008 11.8067C9.80008 11.7467 9.75342 11.6734 9.72008 11.5867C9.68675 11.5067 9.66675 11.42 9.66675 11.3334C9.66675 11.2467 9.68675 11.16 9.72008 11.08C9.75342 10.9934 9.80008 10.92 9.86008 10.86C10.0134 10.7067 10.2467 10.6334 10.4601 10.68C10.5067 10.6867 10.5467 10.7 10.5867 10.72C10.6267 10.7334 10.6667 10.7534 10.7067 10.78C10.7401 10.8 10.7734 10.8334 10.8067 10.86C10.9267 10.9867 11.0001 11.16 11.0001 11.3334C11.0001 11.5067 10.9267 11.68 10.8067 11.8067C10.6801 11.9267 10.5067 12 10.3334 12Z"
      fill="#14110F"
    />
    <path
      d="M13.6666 6.55997H2.33325C2.05992 6.55997 1.83325 6.3333 1.83325 6.05997C1.83325 5.78663 2.05992 5.55997 2.33325 5.55997H13.6666C13.9399 5.55997 14.1666 5.78663 14.1666 6.05997C14.1666 6.3333 13.9399 6.55997 13.6666 6.55997Z"
      fill="#14110F"
    />
    <path
      d="M10.6667 15.1667H5.33333C2.9 15.1667 1.5 13.7667 1.5 11.3333V5.66668C1.5 3.23334 2.9 1.83334 5.33333 1.83334H10.6667C13.1 1.83334 14.5 3.23334 14.5 5.66668V11.3333C14.5 13.7667 13.1 15.1667 10.6667 15.1667ZM5.33333 2.83334C3.42667 2.83334 2.5 3.76001 2.5 5.66668V11.3333C2.5 13.24 3.42667 14.1667 5.33333 14.1667H10.6667C12.5733 14.1667 13.5 13.24 13.5 11.3333V5.66668C13.5 3.76001 12.5733 2.83334 10.6667 2.83334H5.33333Z"
      fill="#14110F"
    />
  </svg>
);
export default Calendar;
