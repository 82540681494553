import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Button, Card, Col, Form, Row, Tooltip } from 'antd';
import React from 'react';
import DeleteIcon from '../../../../assets/icon/DeleteIcon';
import { BUTTON_TYPE } from '../../../../common/constants';
import '../../styles/sortableList.css';
import { CopyCode, PhoneNumber, QuickReply, WebLink } from './Buttons';
import { DragHandle, SortableItem } from './SortableItem';
import SortableOverlay from './SortableOverlay';

const INPUT_RENDERER = {
  PHONE_NUMBER: PhoneNumber,
  URL: WebLink,
  COPY_CODE: CopyCode,
  QUICK_REPLY: QuickReply,
};

function Subfield({
  field,
  name,
  form,
  subRemove,
  subFields,
  disabled = false,
}) {
  const item = Form.useWatch(['buttons', field?.name, 'list', name], form);
  const id = item?.id;
  const RenderInputs = INPUT_RENDERER[id];
  const ShowAddButton =
    id === BUTTON_TYPE.PHONE_NUMBER.id || id === BUTTON_TYPE.COPY_CODE.id;

  return (
    <div>
      {RenderInputs && (
        <Row className="d-flex ml-8">
          <Col span={22}>
            <RenderInputs name={name} id={id} disabled={disabled} />
          </Col>
          {!ShowAddButton && subFields.length > 1 && (
            <Col span={2} className="d-flex justify-around align-center">
              {!disabled && (
                <Tooltip title="Delete" placement="top" zIndex={4}>
                  <Button
                    type="text"
                    danger
                    onClick={() => {
                      subRemove([name, 'name']);
                    }}
                    icon={<DeleteIcon />}
                  />
                </Tooltip>
              )}
            </Col>
          )}
        </Row>
      )}
    </div>
  );
}

const InnerCardList = ({
  field = null,
  remove,
  form,
  items = [],
  setItems,
  list = [],
  totalButton = 0,
  disabled = false,
}) => {
  const obj = list[field?.name]?.list?.[0]?.id;
  const ShowAddButton =
    obj === BUTTON_TYPE.PHONE_NUMBER.id || obj === BUTTON_TYPE.COPY_CODE.id;
  return (
    <Card
      size="small"
      title={`ADD ${list[field?.name]?.list?.[0]?.id} BUTTON`}
      key={field.key}
      extra={
        !disabled && (
          <Tooltip title="Delete" placement="top" zIndex={4}>
            <Button
              type="text"
              danger
              onClick={() => {
                remove(field?.name);
                const nObj = items?.map((item) =>
                  item?.key !== obj ? item : { ...item, disabled: false },
                );
                setItems(nObj);
              }}
              icon={<DeleteIcon />}
            />
          </Tooltip>
        )
      }
    >
      <Form.Item>
        <Form.List name={[field?.name, 'list']}>
          {(subFields, { add: subAdd, remove: subRemove }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 16,
              }}
            >
              {subFields?.map(({ key, ...restField }) => (
                <Subfield
                  field={field}
                  key={key}
                  form={form}
                  subRemove={subRemove}
                  subFields={subFields}
                  disabled={disabled}
                  {...restField}
                />
              ))}
              {!(ShowAddButton || totalButton === 10) && (
                <Button
                  type="dashed"
                  onClick={() => subAdd({ id: obj })}
                  block
                  disabled={disabled}
                >
                  + Add
                </Button>
              )}
            </div>
          )}
        </Form.List>
      </Form.Item>
    </Card>
  );
};

// eslint-disable-next-line import/prefer-default-export
export function SortableList({
  fields,
  remove,
  form,
  setItems,
  move,
  items = [],
  list = [],
  totalButton = 0,
  disabled = false,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={({ active, over }) => {
        if (over && active?.id !== over?.id) {
          const activeIndex = fields?.findIndex(
            ({ name }) => name === active?.id,
          );
          const overIndex = fields?.findIndex(({ name }) => name === over?.id);
          arrayMove(fields, activeIndex, overIndex);
          move(activeIndex, overIndex);
        }
      }}
    >
      <SortableContext items={fields}>
        <ul className="sortable-list" role="application">
          {fields?.map((field) => (
            <SortableList.Item key={field?.name} id={field?.name}>
              {!disabled && <SortableList.DragHandle />}
              <React.Fragment key={field?.name}>
                <InnerCardList
                  field={field}
                  remove={remove}
                  form={form}
                  setItems={setItems}
                  items={items}
                  key={field.name}
                  list={list}
                  totalButton={totalButton}
                  disabled={disabled}
                />
              </React.Fragment>
            </SortableList.Item>
          ))}
        </ul>
      </SortableContext>
      <SortableOverlay />
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
