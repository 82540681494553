import { ConfigProvider, DatePicker, Space } from 'antd';
import en from 'antd/es/date-picker/locale/en_US';
import enUS from 'antd/es/locale/en_US';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import React from 'react';

dayjs.extend(buddhistEra);

// Component level locale
const buddhistLocale = {
  ...en,
  lang: {
    ...en.lang,
    fieldDateFormat: 'YYYY-MM-DD',
    fieldDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    yearFormat: 'YYYY',
    cellYearFormat: 'YYYY',
  },
};

// ConfigProvider level locale
const globalBuddhistLocale = {
  ...enUS,
  DatePicker: {
    ...enUS.DatePicker,
    lang: buddhistLocale.lang,
  },
};

function range(start, end) {
  const result = [];
  // eslint-disable-next-line no-plusplus
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

const DateTimePicker = ({ ...rest }) => {
  const disabledDateTime = (selectedDate) => {
    const currentHour = dayjs().hour();
    const currentMinute = dayjs().minute();

    if (!selectedDate) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    }

    const selectedHour = selectedDate.hour();

    if (selectedDate.isSame(dayjs(), 'day')) {
      // If the selected date is today, disable the hours and minutes that are in the past
      return {
        disabledHours: () => range(0, currentHour),
        disabledMinutes: () =>
          currentHour === selectedHour ? range(0, currentMinute) : [],
      };
    }

    // For future dates, enable all hours and minutes
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
    };
  };

  return (
    <Space direction="vertical" className="date-time-wrapper">
      <ConfigProvider locale={globalBuddhistLocale}>
        <Space direction="vertical">
          <DatePicker
            showNow={false}
            showTime={{ format: 'HH:mm' }}
            disabledTime={disabledDateTime}
            disabledDate={(current) =>
              current && dayjs()?.isAfter(dayjs(current)?.add(1, 'day'))
            }
            // onChange={handleChange}
            {...rest}
          />
        </Space>
      </ConfigProvider>
    </Space>
  );
};

export default DateTimePicker;
