import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Badge, Button, Card } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LIMIT, TEMPLATE_LATEST_OLDEST_LIST } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import useGetRole from '../../hooks/useGetRole';
import useRouter from '../../hooks/useRouter';
import {
  GET_MESSAGE_ADMIN_SEGMET_DETAILS,
  GET_SEGMENT_MESSAGE_CONTACT_LIST,
  GET_USER_MESSAGE,
} from './graphql/Queries';

const MessageContacts = () => {
  const { idc } = useParams();
  const { navigate } = useRouter();
  const [isListSort, setIsListSort] = useState({
    order: 'DESC',
    field: 'createdAt',
  });
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    current: 1,
  });
  const permission = useGetRole();
  const dataKeyMessage = permission ? 'messageAdmin' : 'message';
  const { data: getMessageData } = useQuery(
    permission ? GET_MESSAGE_ADMIN_SEGMET_DETAILS : GET_USER_MESSAGE,
    {
      variables: {
        where: {
          id: idc,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [getContacts, { data, loading }] = useLazyQuery(
    GET_SEGMENT_MESSAGE_CONTACT_LIST,
    {
      variables: {
        where: {
          ...(getMessageData?.[dataKeyMessage]?.data?.segment?.id && {
            segmentId: getMessageData?.[dataKeyMessage]?.data?.segment?.id,
          }),

          ...(getMessageData?.[dataKeyMessage]?.data?.contactConditions
            ?.length > 0 && {
            conditions: getMessageData?.[
              dataKeyMessage
            ]?.data?.contactConditions?.map((item) => ({
              conditions: item?.conditions?.map(
                ({ attribute: spId, operator, value: conditionValue }) => ({
                  attribute: spId,
                  operator,
                  value: conditionValue,
                }),
              ),
            })),
          }),
        },
        sort: {
          order: isListSort.order,
          field: isListSort.field,
        },
        filter: {
          skip: (paginationConfig?.current - 1) * LIMIT,
          limit: LIMIT,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    if (getMessageData) {
      getContacts();
    }
  }, [getMessageData]);

  const handleTableChange = (pagination, filter, sorter) => {
    setIsListSort((prev) => ({
      ...prev,
      ...(filter?.name && {
        field: filter?.name?.[0],
      }),
      order: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig({ ...paginationConfig, ...pagination });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value ?? '-',
      filterMultiple: false,
      filters: TEMPLATE_LATEST_OLDEST_LIST,
      sorter: true,
    },
    {
      title: 'Contact No.',
      dataIndex: 'primaryContactNoPostfix',
      key: 'primaryContactNoPostfix',
      ellipsis: true,
      align: 'left',
      render: (value) => `******${value}` ?? '-',
    },
  ];
  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              onClick={() => navigate(-1)}
              icon={<ArrowLeftOutlined />}
            />
            <div className="d-flex align-center gap-8">
              Contact List
              <Badge
                count={data?.segmentContacts?.count
                  ?.toString()
                  ?.padStart(2, '0')}
              />
            </div>
          </Title>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            columns={columns}
            data={data?.segmentContacts?.data}
            onChange={handleTableChange}
            loadingData={loading}
            paginationConfig={{
              ...paginationConfig,
              total: data?.segmentContacts?.count,
              showSizeChanger: false,
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default MessageContacts;
