import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Card, Switch, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import EditPen from '../../../assets/icon/EditPen';
import { DATE_FORMATS, MODULES, ROUTES } from '../../../common/constants';
import CommonTable from '../../../components/CommonTable';
import SearchComponent from '../../../components/SearchComponent';
import useRouter from '../../../hooks/useRouter';
import { TOGGLE_TAG_STATUS } from './graphql/Mutations';
import { GET_TAGS } from './graphql/Queries';

const { Title } = Typography;

function TagsListing() {
  const { navigate } = useRouter();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    search: '',
    currentPage: 0,
  });
  const [listSort, setListSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });

  const {
    loading: listingLoading,
    refetch: refetchListing,
    data: listingData,
  } = useQuery(
    GET_TAGS,

    {
      fetchPolicy: 'network-only',
      variables: {
        sort: {
          sortOn: listSort?.sortOn,
          sortBy: listSort?.sortBy,
        },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
          search: paginationConfig?.search,
        },
      },

      onError() {},
    },
  );

  const [toggleTagStatus] = useMutation(TOGGLE_TAG_STATUS, {
    onCompleted() {
      refetchListing();
    },
    onError() {},
  });

  const handleSearchChange = (text) => {
    setPaginationConfig({ search: text });
  };
  const handleEdit = (id) => {
    navigate(`${ROUTES.TAGS}/edit/${id}`);
  };
  const handleToggleChange = async (id, status) => {
    await toggleTagStatus({
      variables: {
        data: {
          active: !status,
        },
        where: {
          id,
        },
      },
    });
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },

    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) =>
        dayjs(record?.createdAt).format(DATE_FORMATS.WITH_TIME),
    },

    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value) => (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },

    {
      title: 'Action',
      key: 'action',
      fixed: 'center',
      align: 'center',
      width: '12%',
      render: (_, record) => (
        <div className="action-button d-flex justify-start">
          <Tooltip title="Edit Tag">
            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(record?.id);
              }}
              icon={<EditPen />}
            />
          </Tooltip>
          <Tooltip title={record?.isActive ? 'Inactive' : 'Active'}>
            <div key={record?.id} onClick={(e) => e?.stopPropagation()}>
              <Switch
                value={record?.isActive}
                onChange={() =>
                  handleToggleChange(record?.id, record?.isActive)
                }
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));

    setPaginationConfig((prev) => ({
      ...prev,
      limit: pagination?.limit,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const handleRowClick = (record) => {
    const id = record?.id;
    const handleRow = () => {
      navigate(`${ROUTES.TAGS}/view/${id}`);
    };
    return {
      onClick: handleRow,
    };
  };

  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.TAGS}
          <Badge
            count={listingData?.tags?.count?.toString()?.padStart(2, '0')}
          />
        </div>
        <Button
          className="ml-8"
          key="1"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate(ROUTES?.CREATE_TAG);
          }}
        >
          Add Tag
        </Button>
      </Title>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="contact-sidebar">
              <div className="contact-filter-left" />
              <div className="contact-filter-right">
                <div className="contact-filter">
                  <SearchComponent getData={handleSearchChange} />
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            columns={columns}
            data={listingData?.tags?.tags}
            loading={listingLoading}
            onRow={handleRowClick}
            rowClassName="row-cursor-pointer"
            onChange={handleTableChange}
            paginationConfig={{
              ...paginationConfig,
              total: listingData?.tags?.count,
              showSizeChanger: false,
            }}
          />
        </div>
      </Card>
    </>
  );
}

export default TagsListing;
