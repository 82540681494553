import React from 'react';
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  CONTACTS: '/contacts',
  CONTACT_DETAIL: '/contacts/:id',
  ADD_CONTACT: '/contacts/add',
  EDIT_CONTACT: '/contacts/edit/:id',
  CONTACT_LOGS: '/contacts/logs/:id',
  PROFILE: '/profile',
  IMPORT_EXPORT: '/import-export',
  ROLE_PERMISSION: '/role-permission',
  CREATE_ROLE: '/role-permission/add',
  EDIT_ROLE: '/role-permission/edit/:id',
  SPECIALIZATION: '/specialization',
  CREATE_SPECIALIZATION: '/specialization/add',
  EDIT_SPECIALIZATION: '/specialization/edit/:id',
  VIEW_SPECIALIZATION: '/specialization/view/:id',
  LOCATIONS: '/locations',
  CREATE_LOCATION: '/locations/add',
  EDIT_LOCATION: '/locations/edit/:id',
  VIEW_LOCATION: '/locations/view/:id',
  TAGS: '/tags',
  CREATE_TAG: '/tags/add',
  EDIT_TAG: '/tags/edit/:id',
  VIEW_TAG: '/tags/view/:id',
  SEGMENTS: '/segments',
  VIEW_SEGMENT: '/segments/view/:id',
  SUPER_ADMIN: '/super-admin',
  ADD_SUPER_ADMIN_CONTACT: '/super-admin/add',
  EDIT_SUPER_ADMIN_CONTACT: '/super-admin/edit/:id',
  TEMPLATE: '/template',
  ADD_TEMPLATE: '/template/add',
  EDIT_TEMPLATE: '/template/edit/:id',
  CAMPAIGN: '/campaign',
  ADD_CAMPAIGN: '/campaign/add',
  EDIT_CAMPAIGN: '/campaign/edit/:id',
  CAMPAIGN_MESSAGE: '/campaign/:id/message',
  CAMPAIGN_ADD_MESSAGE: '/campaign/:id/message/add',
  CAMPAIGN_EDIT_MESSAGE: '/campaign/:id/message/edit',
  MESSAGE: '/message',
  CONTACTS_LIST: '/:idc/contacts',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  CONTACTS: 'Contacts',
  SUPER_ADMIN: 'Users',
  IMPORT_EXPORT: 'Import/Export',
  ROLE_PERMISSION: 'Roles & Permissions',
  MASTER_LIST: 'Master',
  SPECIALIZATION: 'Specializations',
  LOCATIONS: 'Locations',
  TAGS: 'Tags',
  SEGMENTS: 'Segments',
  TEMPLATE: 'Templates',
  MESSAGE: 'Message',
  CAMPAIGN: 'Campaigns',
};

export const MODULES_KEY = {
  TEMPLATE_MANAGEMENT: 'TEMPLATE_MANAGEMENT',
  CONTACT_MANAGEMENT: 'CONTACT_MANAGEMENT',
  SEGMENT_MANAGEMENT: 'SEGMENT_MANAGEMENT',
  CAMPAIGN_MANAGEMENT: 'CAMPAIGN_MANAGEMENT',
  ADMIN_MANAGEMENT: 'ADMIN_MANAGEMENT',
  DASHBOARD_ANALYTICS: 'DASHBOARD_ANALYTICS',
};

export const PERMISSION_OBJECT = {
  READ: 'read',
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const DATE_FORMATS = {
  REGULAR: 'DD/MM/YYYY',
  WITH_TIME: 'DD/MM/YYYY | h:mm A',
};

export const SORT = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DEFAULT_SORT_CONFIG = {
  order: SORT?.DESC,
  field: 'createdAt',
};

export const MESSAGE_STATUS = {
  SCHEDULE: 'SCHEDULED',
  IN_DRAFT: 'IN_DRAFT',
  SENT: 'SENT',
  PAUSED: 'PAUSE',
  IN_PROCESS: 'IN_PROCESS',
};

export const MESSAGE_STATUS_INPUT = {
  [MESSAGE_STATUS.IN_DRAFT]: 'In-Draft',
  [MESSAGE_STATUS.IN_PROCESS]: 'In-Process',
  [MESSAGE_STATUS.PAUSED]: 'Paused',
  [MESSAGE_STATUS.SCHEDULE]: 'Schedule',
  [MESSAGE_STATUS.SENT]: 'Sent',
};

export const MESSAGE_ACTIONS_BUTTONS = {
  SCHEDULE: 'Schedule',
  SENT: 'Send now',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  EDIT: 'Edit',
  PAUSE: 'Pause',
  RESUME: 'Resume',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const TABLE_HEIGHT = 225;
export const TABLE_HEIGHT_SEARCH = 395;

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z]).*$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  SNAKCASETEXT: /^[a-z0-9_]*$/,
  EMOJI: /<span class="ql-emojiblot" data-name="([^"]*)">.*?<\/span>/g,
};

export const LIMIT = 10;
export const SCROLL_PERCENT = 0.85;

export const TEMPLATE_STATUS_LIST = [
  { text: 'Draft', value: 'DRAFT' },
  { text: 'Submitted', value: 'SUBMITTED' },
  { text: 'Rejected', value: 'REJECTED' },
  { text: 'Approved', value: 'APPROVED' },
  { text: 'In Appeal', value: 'IN_APPEAL' },
  { text: 'Pending Deletion', value: 'PENDING_DELETION' },
  { text: 'Disabled', value: 'DISABLED' },
  { text: 'Deleted', value: 'DELETED' },
  { text: 'Paused', value: 'PAUSED' },
  { text: 'Limit Exceeded', value: 'LIMIT_EXCEEDED' },
];
export const ORDER = [
  { label: 'Ascending', value: 'ASC' },
  { label: 'Descending', value: 'DESC' },
];

export const TEMPLATE_CATEGORY_LIST = [
  { text: 'Marketing', value: 'MARKETING' },
  { text: 'Utility', value: 'UTILITY' },
  { text: 'Authentication', value: 'AUTHENTICATION' },
];

export const TEMPLATE_LATEST_OLDEST_LIST = [
  { text: 'Updated At', value: 'updatedAt' },
  { text: 'Created At', value: 'createdAt' },
  { text: 'Name', value: 'name' },
  { text: 'Status', value: 'status' },
];

export const GENDER = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
  { label: 'Other', value: 'OTHER' },
];

export const SOURCE = [
  { label: 'Manually Added', value: 'MANUAL_ADD' },
  { label: 'Import', value: 'IMPORT' },
  { label: 'QR code scanned', value: 'QR_CODE_SCAN' },
];

export const STATUS = [
  { label: 'Active', value: true },
  { label: 'DeActive', value: false },
];

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const MODAL_TYPE = {
  IMPORT: 'Import',
  DELETE: 'Delete',
  DEACTIVATE: 'De-activate',
};

export const DELETE_MODAL_TEMPLATE = {
  description:
    'Are you sure you want to delete this role? This action cannot be undone.',
  title: 'Delete Role',
  okButtonText: 'Delete',
};

export const MODULE_TEMPLATE = {
  location: 'location',
  specialization: 'specialization',
  role: 'role',
  tag: 'tag',
};

export const FORM_TYPE = {
  specialization: 'Specialization',
  location: 'Location',
  tag: 'Tag',
  view_specialization: 'View specialization',
  view_location: 'View location',
  view_tag: 'View tag',
};

export const FILE_TYPES = {
  TEXT: {
    id: 'TEXT',
    title: 'Text',
  },
  QR_CODE: {
    id: 'QR_CODE',
    title: 'QR-Code',
  },
  VIDEO: {
    id: 'VIDEO',
    title: 'Video',
    accept: 'video/*',
    // 64 MB
    sizeLimit: 64,
  },
  IMAGE: {
    id: 'IMAGE',
    title: 'Image',
    accept: 'image/*',
    // 5 MB
    sizeLimit: 5,
  },
  DOCUMENT: {
    id: 'DOCUMENT',
    title: 'Document',
    accept: '.pdf',
    // 2 GB
    sizeLimit: 1024 * 2,
  },
  NONE: {
    id: 'NONE',
    title: 'None',
  },
};

export const SEGMENT_ATTRIBUTES = {
  CREATED_AT: 'CREATED_AT',
  STATE: 'STATE',
};

export const segmentRules = [
  {
    title: 'Opt In Consent',
    description: 'Yes/No/All',
    attribute: 'OPT_IN_CONSENT',
  },
  {
    title: 'Primary Country Code',
    description: 'Country code from all the existing contacts',
    attribute: 'PRIMARY_COUNTRY_CODE',
  },
  {
    title: 'Secondary Country Code',
    description: 'Country code from all the existing contacts',
    attribute: 'SECONDARY_COUNTRY_CODE',
  },
  {
    title: 'Location',
    description: 'All the predefined locations added by admin',
    attribute: 'LOCATION',
  },
  {
    title: 'Gender',
    description: 'Male, Female, Other',
    attribute: 'GENDER',
  },
  {
    title: 'State/Province/Region',
    description:
      'Dropdown options consisting of state values from all the contacts',
    attribute: 'STATE',
  },
  {
    title: 'Specialization',
    description: 'Manual input',
    attribute: 'SPECIALIZATION',
  },
  {
    title: 'ZIP Postal Code',
    description: 'Manual input',
    attribute: 'ZIP_POSTAL_CODE',
  },
  {
    title: 'Tags',
    description: 'All the existing tags',
    attribute: 'TAGS',
  },
  {
    title: 'Created At',
    description: 'Date, Date Range',
    attribute: 'CREATED_AT',
  },
  {
    title: 'Status',
    description: 'Active, Inactive',
    attribute: 'IS_ACTIVE',
  },
  {
    title: 'Source',
    description: 'Manual Add / List Import/ QR Code Scan',
    attribute: 'SOURCE',
  },
  {
    title: 'Prefix',
    description: 'Suitable prefix for the respective contacts',
    attribute: 'NAME_PREFIX',
  },
];

export const selectOptions = [
  { label: 'Equals to', value: 'equalsTo' },
  { label: 'Not Equals to', value: 'notEqualsTo' },
  { label: 'In', value: 'in' },
  { label: 'Not In', value: 'notIn' },
  { label: 'Before', value: 'before' },
  { label: 'After', value: 'after' },
];

export const CATEGORY = [
  {
    label: 'MARKETING',
    value: 'MARKETING',
  },
  {
    label: 'UTILITY',
    value: 'UTILITY',
  },
  {
    label: 'AUTHENTICATION',
    value: 'AUTHENTICATION',
  },
];

export const OPTIONS = [
  {
    label: 'Read',
    value: 'read',
    disabledDeps: ['create', 'update', 'delete'],
  },
  { label: 'Create', value: 'create', deps: ['read'] },
  { label: 'Update', value: 'update', deps: ['read'] },
  { label: 'Delete', value: 'delete', deps: ['read'] },
];

export const ADD_OPTIONS_DATA = {
  TEMPLATE_MANAGEMENT: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  CONTACT_MANAGEMENT: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  SEGMENT_MANAGEMENT: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  CAMPAIGN_MANAGEMENT: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
  DASHBOARD_ANALYTICS: {
    read: false,
    update: false,
    delete: false,
    create: false,
  },
};

export const STATUS_FILTER = [
  { text: 'Completed', value: 'COMPLETED' },
  { text: 'Pending', value: 'PENDING' },
  { text: 'Processing', value: 'PROCESSING' },
  { text: 'Partially Failed', value: 'PARTIALLY_FAILED' },
  { text: 'Failed', value: 'FAILED' },
];

export const SAMPLE_FILE_LINK =
  'https://smt-minio.logicwind.tv/smt-dev/contacts/sample_contacts.csv';

export const COUNTRY_CODE = [{ label: '+91', value: '+91' }];

export const OPERATOR = {
  COMPARISON: [
    { label: 'Equals', value: 'EQUALS' },
    { label: 'Not Equals', value: 'NOT_EQUALS' },
  ],
  BEFORE_AFTER: [
    { label: 'Before', value: 'BEFORE' },
    { label: 'After', value: 'AFTER' },
  ],
  CONTAINS_NOT_CONTAINS: [
    { label: 'Not Contains', value: 'NOT_CONTAINS' },
    { label: 'Contains', value: 'CONTAINS' },
  ],
};
export const VALUES_MAP = {
  URL: 'url',
  COPY_CODE: 'example.0',
  PHONE_NUMBER: 'phoneNumber',
  QUICK_REPLY: 'text',
};

export const KEYS_MAP = {
  URL: 'text',
  PHONE_NUMBER: 'text',
};

export const QR_CODE_URL = 'https://i.stack.imgur.com/vbhys.png';

export const TEMPLATE_STATUS = {
  DRAFT: {
    ID: 'DRAFT',
    EDIT: true,
    IN_DRAFT: true,
    NO_EDIT_FIELD: {
      NAME: false,
      CATEGORY: false,
      LANGUAGE: false,
      COMPONENTS: false,
    },
  },
  SUBMITTED: {
    ID: 'SUBMITTED',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
  PENDING: {
    ID: 'PENDING',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
  REJECTED: {
    ID: 'REJECTED',
    EDIT: true,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: false,
      LANGUAGE: true,
      COMPONENTS: false,
    },
  },
  APPROVED: {
    ID: 'APPROVED',
    EDIT: true,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: false,
    },
  },
  FAILED: {
    ID: 'FAILED',
    EDIT: true,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: false,
    },
  },
  IN_APPEAL: {
    ID: 'IN_APPEAL',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
  PENDING_DELETION: {
    ID: 'PENDING_DELETION',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
  DISABLED: {
    ID: 'DISABLED',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
  DELETED: {
    ID: 'DELETED',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
  PAUSED: {
    ID: 'PAUSED',
    EDIT: true,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: false,
      CATEGORY: false,
      LANGUAGE: false,
      COMPONENTS: false,
    },
  },
  LIMIT_EXCEEDED: {
    ID: 'SUBMITTED',
    EDIT: false,
    IN_DRAFT: false,
    NO_EDIT_FIELD: {
      NAME: true,
      CATEGORY: true,
      LANGUAGE: true,
      COMPONENTS: true,
    },
  },
};

export const BUTTON_TYPE = {
  NONE: {
    id: 'NONE',
    title: 'None',
    value: 'none',
  },
  PHONE_NUMBER: {
    id: 'PHONE_NUMBER',
    title: 'Number',
    value: 'number',
  },
  URL: {
    id: 'URL',
    title: 'Weblink',
    value: 'weblink',
  },
  QUICK_REPLY: {
    id: 'QUICK_REPLY',
    title: 'Quick reply',
    value: 'quick reply',
  },
  COPY_CODE: {
    id: 'COPY_CODE',
    title: 'Copy code',
    value: 'copy code',
  },
};

export const TEMPLATE_STATUS_RESPONSE = {
  REJECTED: 'REJECTED',
  FAILED: 'FAILED',
};

export const TEMPLATE_DYNAMIC_FILED_VALUE = [
  {
    label: 'Name',
    value: 'NAME',
  },
  {
    label: 'Primary Contact Number',
    value: 'PRIMARY_CONTACT_NO',
  },
  {
    label: 'Secondary Contact Number',
    value: 'SECONDARY_CONTACT_NO',
  },
  {
    label: 'Primary Email',
    value: 'PRIMARY_EMAIL',
  },
  {
    label: 'Gender',
    value: 'GENDER',
  },
  {
    label: 'City',
    value: 'CITY',
  },
  {
    label: 'State',
    value: 'STATE',
  },
  {
    label: 'Country',
    value: 'COUNTRY',
  },
  {
    label: 'Specialization',
    value: 'SPECIALIZATION',
  },
  {
    label: 'Location',
    value: 'LOCATION',
  },
  {
    label: 'Name Prefix',
    value: 'NAME_PREFIX',
  },
];

export const NAME_PREFIX = [
  {
    label: 'Dr.',
    value: 'DR',
  },
  {
    label: 'Ms.',
    value: 'MS',
  },
  {
    label: 'Mr.',
    value: 'MR',
  },
  {
    label: 'Mrs.',
    value: 'MRS',
  },
  {
    label: 'Miss.',
    value: 'MISS',
  },
];

export const TOOLTIP_INFO = {
  attempted:
    'These are the total number of messages which were attempted to be sent in all the campaigns.',
  delivered:
    'These are the total number of messages which successfully reached the customer.',
  failed:
    'These are the number of notifications (messages) in the campaign which failed to be sent to the customer.',
  read:
    'These are the  total number of messages from all the campaigns which were read by the customer.',
  replied:
    'These are the total number of messages which received a reply from the customer within 72 hours.',
  sent: (
    <p>
      These are the total number of messages which were successfully sent in all
      the campaigns. The difference between the Attempted & Sent counts will be
      of notifications which failed to get sent. <br /> [Note: All Sent messages
      might not necessarily reach the customer.]
    </p>
  ),
};
