import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Card,
  Modal,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import DeleteIcon from '../../assets/icon/DeleteIcon';
import EditPen from '../../assets/icon/EditPen';
import { DATE_FORMATS, MODULES, ROUTES } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import { DELETE_ROLE, TOGGLE_ROLE_STATUS } from './graphql/Mutations';
import { ROLE_LISTING } from './graphql/Queries';

const { Title } = Typography;

function RoleListing() {
  const { navigate } = useRouter();
  const [isPrompt, setIsPrompt] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    search: '',
    currentPage: 0,
  });
  const [listSort, setListSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });

  const {
    loading: listingLoading,
    refetch: refetchListing,
    data: listingData,
  } = useQuery(
    ROLE_LISTING,

    {
      fetchPolicy: 'network-only',
      variables: {
        sort: {
          sortOn: listSort?.sortOn,
          sortBy: listSort?.sortBy,
        },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
          search: paginationConfig?.search,
        },
      },
      onError() {},
    },
  );

  const [deleteRole] = useMutation(DELETE_ROLE, { onError() {} });
  const [toggleSpecializationStatus] = useMutation(TOGGLE_ROLE_STATUS, {
    onCompleted() {
      refetchListing();
    },
    onError() {},
  });
  const handleToggleChange = async (id, status) => {
    await toggleSpecializationStatus({
      variables: {
        data: {
          active: !status,
        },
        where: {
          id,
        },
      },
    });
  };

  const RoleListingColumns = [
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (value) => dayjs(value).format(DATE_FORMATS.WITH_TIME),
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (value) => (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'Active' : 'In-active'}
        </Tag>
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      className: 'cursor-auto',
      fixed: 'center',
      align: 'center',
      width: 160,
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => (
        <div className="action-button d-flex justify-start">
          <Tooltip title="Edit Role">
            <Button
              type="text"
              onClick={() =>
                navigate(`${ROUTES.ROLE_PERMISSION}/edit/${value?.id}`)
              }
              icon={<EditPen />}
            />
          </Tooltip>
          <Tooltip title={value?.isActive ? 'Inactive' : 'Active'}>
            <div key={value?.id} onClick={(e) => e?.stopPropagation()}>
              <Switch
                value={value?.isActive}
                onChange={() => handleToggleChange(value?.id, value?.isActive)}
              />
            </div>
          </Tooltip>
          <Tooltip title="Delete Role">
            <Tooltip title="Delete" placement="top" zIndex={4}>
              <Button
                type="text"
                danger
                onClick={() => {
                  setIsPrompt(true);
                  setDeleteId(value?.id);
                }}
                icon={<DeleteIcon />}
              />
            </Tooltip>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));

    setPaginationConfig((prev) => ({
      ...prev,
      limit: pagination?.limit,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const handleSearchChange = (text) => {
    setPaginationConfig({ search: text });
  };

  const handleOk = async () => {
    try {
      await deleteRole({
        variables: {
          where: {
            id: deleteId,
          },
        },
      });
    } catch (error) {
      return error;
    } finally {
      setIsPrompt(false);
      refetchListing();
    }
  };
  const handleClose = () => {
    setIsPrompt(false);
  };
  return (
    <>
      <Title
        className="site-page-header p-0 d-flex justify-between mt-0"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.ROLE_PERMISSION}
          <Badge
            count={listingData?.roles?.count?.toString()?.padStart(2, '0')}
          />
        </div>
        <Button
          onClick={() => navigate(ROUTES.CREATE_ROLE)}
          type="primary"
          icon={<PlusOutlined />}
        >
          Add Role
        </Button>
      </Title>

      <Card
        className="ant-body-scroll"
        title={
          <div className="contact-sidebar">
            <div className="contact-filter-left" />
            <div className="contact-filter-right">
              <div className="contact-filter">
                <SearchComponent
                  name="Role"
                  getData={handleSearchChange}
                  className="mr-8"
                />
              </div>
            </div>
          </div>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            columns={RoleListingColumns}
            data={listingData?.roles?.roles}
            loadingData={listingLoading}
            onChange={handleTableChange}
            paginationConfig={{
              ...paginationConfig,
              total: listingData?.roles?.count,
              showSizeChanger: false,
            }}
          />
        </div>
      </Card>
      <Modal
        open={isPrompt}
        onOk={handleOk}
        onCancel={handleClose}
        title="Delete Role"
      >
        Are you sure want to delete this role ?
      </Modal>
    </>
  );
}

export default RoleListing;
