import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserAdmin(
    $data: UpdateUserAdminInput!
    $where: UserWhereUniqueInput!
  ) {
    updateUserAdmin(data: $data, where: $where) {
      message
    }
  }
`;
