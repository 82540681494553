import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import useGetRole from '../../../hooks/useGetRole';
import {
  GET_ADMIN_TEMPLATES,
  GET_USER_TEMPLATES,
} from '../../campaign/graphql/Queries';

const variableSelector = ({ skip, limit, search }) => ({
  filter: { skip, limit, search },
});
const dataSelector = (data) =>
  data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const TemplateSelect = (props) => {
  const permission = useGetRole();
  const dataKeys = {
    data: permission ? 'templatesAdmin' : 'templates',
    count: 'count',
    records: 'templates',
  };
  return (
    <InfiniteSelect
      query={permission ? GET_ADMIN_TEMPLATES : GET_USER_TEMPLATES}
      variableSelector={variableSelector}
      dataKeys={dataKeys}
      dataSelector={dataSelector}
      placeholder="Select Template"
      {...props}
    />
  );
};

export default TemplateSelect;
