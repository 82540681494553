import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query Tags($filter: TagsFilter, $sort: TagsSort) {
    tags(filter: $filter, sort: $sort) {
      count
      tags {
        createdAt
        createdBy
        creator {
          email
          firstName
          id
          lastName
          role
        }
        isActive
        name
        key
        id
        updatedAt
      }
    }
  }
`;

export const GET_TAG = gql`
  query Tag($where: TagWhereUniqueInput!) {
    tag(where: $where) {
      createdAt
      createdBy
      id
      key
      name
      updatedAt
      creator {
        email
        firstName
        id
        lastName
        role
      }
    }
  }
`;
