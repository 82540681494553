import { InfoCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row, Space, Tooltip, Typography } from 'antd';
import { startCase } from 'lodash';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import {
  MODULES_KEY,
  PERMISSION_OBJECT,
  TOOLTIP_INFO,
} from '../../common/constants';
import { combineData } from '../../common/utils';
import useGetRole from '../../hooks/useGetRole';
import useVerifyPermissions from '../../hooks/useVerifyPermissions';
import CampaignSelect from './components/CampaignSelect';
import TemplateSelect from './components/TemplateSelect';
import {
  ADMIN_DASHBOARD_MESSAGE_ANALYTICS,
  ADMIN_GENERAL_STATISTICS,
  USER_DASHBOARD_MESSAGE_ANALYTICS,
  USER_GENERAL_STATISTICS,
} from './graphql/Queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const Dashboard = () => {
  const dashboardTemplatePermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.TEMPLATE_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });

  const dashboardCampaignPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.READ,
  });

  const permission = useGetRole();
  const { getCurrentUser } = useContext(AppContext);
  const { defaultLocation } = getCurrentUser();
  const [messageStatsFilter, setMessageStatsFilter] = useState({
    campaignId: null,
    dateRange: {
      from: '',
      to: '',
    },
    templateId: null,
  });

  const { data: generalAnalytics, loading: generalAnalyticsLoading } = useQuery(
    permission ? ADMIN_GENERAL_STATISTICS : USER_GENERAL_STATISTICS,
    {
      variables: {
        filter: {
          locationIds: defaultLocation?.id || null,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const {
    data: messageAnalyticsData,
    loading: messageAnalyticsLoading,
  } = useQuery(
    permission
      ? ADMIN_DASHBOARD_MESSAGE_ANALYTICS
      : USER_DASHBOARD_MESSAGE_ANALYTICS,
    {
      variables: {
        filter: {
          campaignId: messageStatsFilter?.campaignId?.value ?? null,
          dateRange: {
            from: messageStatsFilter?.dateRange?.from || '',
            to: messageStatsFilter?.dateRange?.to || '',
          },
          templateId: messageStatsFilter?.templateId?.value ?? null,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const generalAnalyticsDataKey = permission
    ? 'dashboardGeneralAnalyticsAdmin'
    : 'dashboardGeneralAnalytics';

  const { totalCampaigns, totalContacts, totalMessageTemplates } =
    generalAnalytics?.[generalAnalyticsDataKey] || {};

  const messageAnalyticsDataKey = permission
    ? 'dashboardMessageAnalyticsAdmin'
    : 'dashboardMessageAnalytics';

  const { __typename, pending, ...rest } =
    messageAnalyticsData?.[messageAnalyticsDataKey] || {};

  const formattedData = combineData(TOOLTIP_INFO, rest);

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        Dashboard
      </Title>
      <Space direction="vertical">
        <Row gutter={8}>
          <Col span={6}>
            <Card
              title={
                <Row>
                  <Col span={24}>Total number of contacts</Col>
                  <Col span={24}>(Active / Total)</Col>
                </Row>
              }
              loading={generalAnalyticsLoading}
            >
              <Title level={1}>
                {totalContacts &&
                  `${totalContacts?.active}/${totalContacts?.total}`}
              </Title>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <Row>
                  <Col span={24}>Total number of campaigns</Col>
                  <Col span={24}>(Active / Total)</Col>
                </Row>
              }
              loading={generalAnalyticsLoading}
            >
              <Title level={1}>
                {totalCampaigns &&
                  `${totalCampaigns?.active}/${totalCampaigns?.total}`}
              </Title>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title="Total number of message templates"
              loading={generalAnalyticsLoading}
            >
              <Title level={1}>{totalMessageTemplates}</Title>
            </Card>
          </Col>
          <Col span={6}>
            <Card
              title="Total number of opt-out contacts"
              loading={generalAnalyticsLoading}
            >
              <Title level={1}>{totalContacts?.optOut}</Title>
            </Card>
          </Col>
        </Row>
        <Card
          title={
            <Row gutter={8}>
              <Col
                span={
                  dashboardTemplatePermission && dashboardCampaignPermission
                    ? 12
                    : `${
                        dashboardTemplatePermission ||
                        dashboardCampaignPermission
                          ? 16
                          : 20
                      }`
                }
              >
                <Title level={3}>Message Based Statistics</Title>
              </Col>
              <Col span={4} className="d-flex justify-center align-center">
                <RangePicker
                  className="full-width"
                  onChange={(date) =>
                    setMessageStatsFilter((prev) => ({
                      ...prev,
                      dateRange: { from: date?.[0], to: date?.[1] },
                    }))
                  }
                />
              </Col>
              {dashboardTemplatePermission && (
                <Col span={4} className="d-flex justify-center align-center">
                  <TemplateSelect
                    className="full-width"
                    allowClear
                    searchable={false}
                    onChange={(templateId) =>
                      setMessageStatsFilter((prev) => ({ ...prev, templateId }))
                    }
                  />
                </Col>
              )}
              {dashboardCampaignPermission && (
                <Col span={4} className="d-flex justify-center align-center">
                  <CampaignSelect
                    className="full-width"
                    allowClear
                    searchable={false}
                    onChange={(campaignId) =>
                      setMessageStatsFilter((prev) => ({ ...prev, campaignId }))
                    }
                  />
                </Col>
              )}
            </Row>
          }
          loading={messageAnalyticsLoading}
        >
          <Row gutter={[8, 8]}>
            {Object?.entries(formattedData)?.map(
              ([key, { count, message }]) => (
                <Col key={key} sm={8} md={6} lg={8} xl={4}>
                  <Card
                    title={
                      <Space>
                        {startCase(key)}
                        <Tooltip title={message}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Space>
                    }
                  >
                    <Title level={1}>{count}</Title>
                  </Card>
                </Col>
              ),
            )}
          </Row>
        </Card>
      </Space>
    </>
  );
};

export default Dashboard;
