import React from 'react';
import { useParams } from 'react-router-dom';
import RolePermissionForm from './components/RolePermissionForm';

function EditRole() {
  const { id } = useParams();

  return <RolePermissionForm slug={id} />;
}

export default EditRole;
