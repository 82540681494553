import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Row } from 'antd';
import React, { useState } from 'react';
import {
  CreatedAt,
  Gender,
  Location,
  NamePrefix,
  OptInConsent,
  PrimaryCode,
  SecondaryCode,
  Source,
  Specialization,
  State,
  Status,
  Tag,
  ZipCode,
} from './RenderSegmentInputcampaing';
import RuleListing from './RulesList';

// render inputs according to selection from list
const INPUT_RENDERER = {
  PRIMARY_COUNTRY_CODE: PrimaryCode,
  SECONDARY_COUNTRY_CODE: SecondaryCode,
  LOCATION: Location,
  GENDER: Gender,
  STATE: State,
  SPECIALIZATION: Specialization,
  ZIP_POSTAL_CODE: ZipCode,
  TAGS: Tag,
  CREATED_AT: CreatedAt,
  IS_ACTIVE: Status,
  SOURCE: Source,
  OPT_IN_CONSENT: OptInConsent,
  NAME_PREFIX: NamePrefix,
};

function Subfield({ field, name, form, subRemove }) {
  const item = Form.useWatch(['items', field.name, 'list', name], form);
  const id = item?.id;

  const RenderInputs = INPUT_RENDERER[id];

  return (
    <div>
      {RenderInputs && (
        <Row className="d-flex">
          <Col span={22}>
            <RenderInputs name={name} id={id} form={form} field={field} />
          </Col>
          <Col span={2} className="d-flex justify-around align-center">
            <CloseOutlined
              onClick={() => {
                subRemove([name, 'name']);
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

const InnerCardList = ({ field, remove, form }) => {
  const [showInnerList, setShowForm] = useState(false);
  return (
    <Card
      size="small"
      title={`And Filter ${field.name + 1}`}
      key={field.key}
      extra={
        <CloseOutlined
          onClick={() => {
            remove(field.name);
          }}
        />
      }
    >
      <Form.Item>
        <Form.List name={[field.name, 'list']}>
          {(subFields, { add: subAdd, remove: subRemove }) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: 16,
              }}
            >
              {subFields.map(({ key, ...restField }) => (
                <>
                  <Subfield
                    field={field}
                    key={key}
                    form={form}
                    {...restField}
                    subRemove={subRemove}
                  />
                </>
              ))}

              {showInnerList ? (
                <Card size="small">
                  <div className="mb-16 d-flex justify-end">
                    <CloseOutlined onClick={() => setShowForm(false)} />
                  </div>

                  <RuleListing
                    onClick={(id, item) => {
                      subAdd({ item, id });
                      setShowForm(false);
                    }}
                  />
                </Card>
              ) : (
                <Button type="dashed" onClick={() => setShowForm(true)} block>
                  + Add Sub Item
                </Button>
              )}
            </div>
          )}
        </Form.List>
      </Form.Item>
    </Card>
  );
};

const SegmentForm = ({ form, edit }) => {
  const [showOuterList, setOuterShowForm] = useState(!edit);

  const list = Form.useWatch(['items'], form);

  return (
    <div className="message-segment-container">
      <Form.List name="items">
        {(fields, { add, remove }) => (
          <>
            <div
              style={{
                display: 'flex',
                rowGap: 16,
                flexDirection: 'column',
              }}
            >
              {fields.map((field, index) => (
                <>
                  <InnerCardList field={field} remove={remove} form={form} />

                  {index !== fields?.length - 1 && (
                    <Divider orientation="left" className="divider">
                      Or
                    </Divider>
                  )}
                </>
              ))}

              {showOuterList || !list?.length > 0 ? (
                <RuleListing
                  onClick={(id, item) => {
                    add({ list: [{ item, id }] });
                    setOuterShowForm(false);
                  }}
                />
              ) : (
                <Button
                  type="dashed"
                  onClick={() => setOuterShowForm(true)}
                  block
                >
                  + Add Item
                </Button>
              )}
            </div>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default SegmentForm;
