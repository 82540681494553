import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { camelCase, startCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import Clock from '../../assets/icon/Clock';
import DeleteIcon from '../../assets/icon/DeleteIcon';
import EditPen from '../../assets/icon/EditPen';
import {
  DATE_FORMATS,
  LIMIT,
  MODULES,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
  TEMPLATE_LATEST_OLDEST_LIST,
} from '../../common/constants';
import { fileUpload } from '../../common/utils';
import CommonDropdown from '../../components/CommonDropdown';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import useGetRole from '../../hooks/useGetRole';
import useRouter from '../../hooks/useRouter';
import useVerifyPermissions from '../../hooks/useVerifyPermissions';
import {
  DELETE_CONTACT,
  DELETE_CONTACT_ADMIN,
  IMPORT_CONTACT,
  IMPORT_CONTACT_ADMIN,
  UPDATE_BULK_CONTACT_ADMIN,
} from './graphql/Mutations';
import {
  ACTIVE_SPECIALIZATIONS_LIST,
  ACTIVE_TAG_LIST,
  CONTACTS_ADMIN,
  CONTACTS_USER,
  GET_SIGNED_URL,
} from './graphql/Queries';

const { Title } = Typography;

function Contacts() {
  const { navigate } = useRouter();
  const { getCurrentUser, setIsLocation } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [isModal, setIsModal] = useState(false);
  const [isBulkActionModal, setIsBulkActionModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [isImportContact, setIsImportContact] = useState(false);
  const permission = useGetRole();
  const { defaultLocation } = getCurrentUser();
  const [uploadLoader, setUploadLoader] = useState(false);
  const contactEditPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CONTACT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.UPDATE,
  });

  const contactAddPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CONTACT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.CREATE,
  });

  const contactDeletePermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CONTACT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.DELETE,
  });

  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    total: 0,
    current: 1,
  });

  const [fileList, setFileList] = useState([]);

  const [checked, setChecked] = useState(true);

  const [isListSort, setIsListSort] = useState({
    search: '',
    order: 'DESC',
    field: 'updatedAt',
    specializations: [],
    location: defaultLocation ? [defaultLocation?.id] : [],
    optConsent: null,
    tagIds: [],
  });
  const [selectedKeys, setSelectedKeys] = useState([]);
  const dataType = permission ? 'contactsAdmin' : 'contacts';

  const [deleteContact, { loading: deleteLoading }] = useMutation(
    permission ? DELETE_CONTACT_ADMIN : DELETE_CONTACT,
    {
      onError() {},
    },
  );

  const { data: tagListData, loading: getTagLoading } = useQuery(
    ACTIVE_TAG_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { data: specializationsListData } = useQuery(
    ACTIVE_SPECIALIZATIONS_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [updateBulkContactAdmin] = useMutation(UPDATE_BULK_CONTACT_ADMIN);

  const { loading, data, refetch } = useQuery(
    permission ? CONTACTS_ADMIN : CONTACTS_USER,
    {
      variables: {
        sort: {
          order: isListSort?.order,
          field: isListSort?.field || 'updatedAt',
        },
        filter: {
          skip: (paginationConfig?.current - 1) * LIMIT,
          limit: LIMIT,
          search: isListSort?.search,
          specializationIds: isListSort?.specializations,
          locationIds: isListSort?.location,
          optConsent: isListSort?.optConsent,
          tagIds: isListSort?.tagIds?.[0],
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );
  const [signUploadContact] = useLazyQuery(GET_SIGNED_URL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [uploadContacts] = useMutation(
    permission ? IMPORT_CONTACT_ADMIN : IMPORT_CONTACT,
    {
      onCompleted() {
        setIsImportContact(false);
        setUploadLoader(false);
        setFileList([]);
      },
      onError() {
        setUploadLoader(false);
      },
    },
  );

  const onFinish = async (value) => {
    try {
      await updateBulkContactAdmin({
        variables: {
          where: {
            ids: []?.concat(...Object?.values(selectedKeys)),
          },
          data: {
            optConsent: value?.opt?.value,
            ...(value?.tag && { tags: value?.tag?.value }),
          },
        },
      });
    } catch (error) {
      return error;
    } finally {
      form.resetFields();
      refetch();
      setSelectedKeys([]);
      setIsBulkActionModal(false);
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteContact({
      variables: { where: { id } },
    });
    if (res.data) {
      setIsModal(false);
      await refetch();
    }
  };

  const handleTableChange = (pagination, filter, sorter) => {
    setPaginationConfig({ ...paginationConfig, ...pagination });
    setIsListSort((prev) => ({
      ...prev,
      order: sorter?.order !== 'descend' ? 'DESC' : 'ASC',
      field: filter?.name?.[0] || 'updatedAt',
      specializations: filter?.specializationIds || [],
      optConsent:
        filter?.optConsent?.[0] === undefined ? null : filter?.optConsent?.[0],
      tagIds: filter?.tagIds || [],
    }));
  };

  const handleDeleteModalOpen = (value) => {
    setCurrentData(value);
    setIsModal(true);
  };

  useEffect(() => {
    setIsListSort((prev) => ({
      ...prev,
      location: defaultLocation ? [defaultLocation?.id] : [],
    }));
  }, [defaultLocation?.id]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedKeys((prev) => ({
      ...prev,
      [paginationConfig?.current]: newSelectedRowKeys,
    }));
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys?.[paginationConfig?.current],
    onChange: onSelectChange,
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left',
      width: 200,
      className: 'max-width-column',
      render: (_, allValues) => {
        const namePrefix = startCase(camelCase(allValues?.namePrefix));
        const fullName =
          namePrefix && allValues?.name
            ? `${namePrefix}. ${allValues?.name}`
            : allValues?.name;

        return fullName ?? '-';
      },

      filterMultiple: false,
      filters: TEMPLATE_LATEST_OLDEST_LIST,
      sorter: true,
      defualtSortOrder: 'descend',
    },
    {
      title: 'Contact No.',
      dataIndex: 'primaryContactNoPostfix',
      key: 'primaryContactNoPostfix',
      ellipsis: true,
      align: 'left',
      render: (value) => (value ? `******${value}` : '-'),
    },
    {
      title: 'Email',
      dataIndex: 'primaryEmailPrefix',
      key: 'primaryEmailPrefix',
      ellipsis: true,
      align: 'left',
      render: (value) => value ?? '-',
    },
    {
      title: 'Specialization',
      dataIndex: 'specialization',
      key: 'specializationIds',
      ellipsis: true,
      width: 150,
      align: 'left',
      render: (value) => value?.[0]?.name ?? '-',
      filterMultiple: false,
      filters: specializationsListData?.activeSpecializations?.specializations?.map(
        ({ id, name }) => ({
          text: name,
          value: id,
        }),
      ),
    },
    {
      title: 'Opt Consent.',
      dataIndex: 'optConsent',
      key: 'optConsent',
      width: 150,
      ellipsis: true,
      align: 'left',
      filterMultiple: false,
      render: (value) => (value ? 'Opt-in' : 'Opt-out'),
      filters: [
        {
          text: 'Opt-in',
          value: true,
        },
        {
          text: 'Opt-out',
          value: false,
        },
      ],
    },
    {
      title: 'Tag',
      dataIndex: 'tags',
      key: 'tagIds',
      ellipsis: true,
      align: 'left',
      render: (value) => value?.map(({ name }) => name)?.join(', ') || '-',
      filterMultiple: false,
      filters: tagListData?.activeTags?.tags?.map(({ id, name }) => ({
        text: name,
        value: id,
      })),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      align: 'left',
      render: (value) => dayjs(value)?.format(DATE_FORMATS?.WITH_TIME),
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      className: 'cursor-auto',
      fixed: 'center',
      width: 160,
      align: 'center',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, value) => (
        <div className="action-button d-flex justify-end">
          {contactEditPermission && (
            <Tooltip title="Edit" placement="top" zIndex={4}>
              <Button
                type="text"
                onClick={() =>
                  navigate(`${ROUTES?.CONTACTS}/edit/${value?.id}`)
                }
                icon={<EditPen />}
              />
            </Tooltip>
          )}
          <Tooltip title="Logs" placement="top" zIndex={4}>
            <Button
              type="text"
              onClick={() => navigate(`${ROUTES.CONTACTS}/logs/${value?.id}`)}
              icon={<Clock />}
            />
          </Tooltip>
          {contactDeletePermission && (
            <Tooltip title="Delete" placement="top" zIndex={4}>
              <Button
                type="text"
                danger
                onClick={() => handleDeleteModalOpen(value)}
                icon={<DeleteIcon />}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];
  const handleImport = () => {
    if (defaultLocation) {
      setIsImportContact(true);
    } else {
      message.error('Please select location!');
      setIsLocation(!!defaultLocation);
    }
  };

  const handleModalCancel = () => {
    setIsImportContact(false);
    setFileList([]);
  };

  const handleImportConfirm = async () => {
    setUploadLoader(true);
    const fileName = fileList?.[0]?.name;

    if (fileList?.length > 0) {
      if (fileList?.[0]?.type === 'text/csv') {
        const res = await signUploadContact({
          variables: { data: { fileName } },
        });
        const { url = '', key = '' } = await res?.data?.getContactSignedPutUrl;

        try {
          try {
            await fileUpload(url, fileList?.[0]?.originFileObj)?.then(() => {
              uploadContacts({
                variables: {
                  data: {
                    key,
                    checkMandatoryFields: checked,
                    locationId: defaultLocation?.id,
                  },
                },
              });
            });
          } catch (error) {
            message.error('Contacts upload failed!');
            setUploadLoader(false);
            return false;
          }
        } catch (error) {
          return error;
        }
      } else {
        message.error('Only .CSV files are allowed');
      }
    }
  };

  const handleUploadChange = (info) => {
    const fileType = info?.fileList?.[0]?.type;
    if (info?.fileList?.length > 0) {
      if (fileType === 'text/csv') {
        setFileList(info?.fileList);
      } else {
        message.error('Only .CSV files are allowed');
      }
    }
  };

  const onChange = (e) => {
    setChecked(e?.target?.checked);
  };

  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.CONTACTS}
          <Badge
            count={data?.[dataType]?.count?.toString()?.padStart(2, '0')}
          />
        </div>
        <div className="d-flex gap-8">
          {contactAddPermission && (
            <Button
              className="ml-8"
              icon={<UploadOutlined />}
              onClick={handleImport}
            >
              Import
            </Button>
          )}
          {contactAddPermission && (
            <Button
              icon={<PlusOutlined />}
              className="ml-8"
              key="1"
              type="primary"
              onClick={() => {
                navigate(`${ROUTES?.ADD_CONTACT}`);
              }}
            >
              Add Contact
            </Button>
          )}
        </div>
      </Title>

      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="contact-sidebar">
              <div className="contact-filter-left">
                {contactEditPermission && (
                  <Button
                    className="ml-8"
                    key="1"
                    type="primary"
                    onClick={() => setIsBulkActionModal(true)}
                    disabled={!Object?.values(selectedKeys)?.flat()?.length > 0}
                  >
                    Edit Contacts (
                    {Object?.values(selectedKeys)?.flat()?.length})
                  </Button>
                )}
              </div>
              <div className="contact-filter-right">
                <div className="contact-filter">
                  <Space>
                    <SearchComponent
                      getData={(text) => {
                        setIsListSort((prev) => ({
                          ...prev,
                          search: text,
                        }));
                        if (paginationConfig.current !== 1) {
                          setPaginationConfig((prev) => ({
                            ...prev,
                            current: 1,
                          }));
                        }
                      }}
                    />
                  </Space>
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            columns={columns}
            data={data?.[dataType]?.data}
            onChange={handleTableChange}
            loadingData={loading}
            tableClassName=""
            paginationConfig={{
              ...paginationConfig,
              total: data?.[dataType]?.count,
              showSizeChanger: false,
            }}
            {...(contactEditPermission && { rowSelection })}
            rowKey={(obj) => obj?.id}
          />
        </div>
      </Card>
      <Modal
        title="Delete contact"
        okText="Yes"
        cancelText="No"
        open={isModal}
        onOk={() => handleDelete(currentData?.id)}
        okButtonProps={{ loading: deleteLoading }}
        onCancel={() => {
          setIsModal(false);
          setCurrentData(null);
        }}
      >
        Are you sure you want to delete this contact?
      </Modal>

      <Modal
        maskClosable={false}
        destroyOnClose
        closable={false}
        title={<Title level={3}>Import Contacts</Title>}
        okText="Start Upload"
        open={isImportContact}
        onOk={handleImportConfirm}
        cancelButtonProps={{
          disabled: uploadLoader,
        }}
        okButtonProps={{
          disabled: fileList?.length <= 0,
          loading: uploadLoader,
        }}
        onCancel={handleModalCancel}
      >
        <Upload
          maxCount={1}
          accept=".csv"
          beforeUpload={() => false}
          onChange={handleUploadChange}
          fileList={fileList}
          onRemove={() => setFileList([])}
          disabled={uploadLoader}
        >
          <Button type="primary" disabled={uploadLoader}>
            Select File
          </Button>
        </Upload>
        <p>
          <b>
            Use the{' '}
            <a href={process.env.REACT_APP_SAMPLE_FILE_LINK}>sample file</a> to
            fill the data. Upload the file after verifying the format of your
            file with the sample file.
          </b>
        </p>
        <Checkbox checked={checked} onChange={onChange} disabled={uploadLoader}>
          Check Mandatory Fields
        </Checkbox>
        <Divider />
      </Modal>

      <Modal
        title="Edit bulk contact"
        okText="Edit"
        cancelText="Cancel"
        open={isBulkActionModal}
        onOk={() => {
          form?.submit();
        }}
        onCancel={() => {
          setIsBulkActionModal(false);
        }}
      >
        <Form
          name="detailsForm"
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="tag" label="Select tags">
                <CommonDropdown
                  showSearch
                  mode="tags"
                  placeholder="Select tags"
                  optionFilterProp="children"
                  loading={getTagLoading}
                  list={tagListData?.activeTags?.tags?.map(({ name }) => ({
                    label: name,
                    value: name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="opt" label="Select Opt-In Consent">
                <CommonDropdown
                  allowClear
                  placeholder="Select Opt Consent"
                  optionFilterProp="children"
                  list={[
                    {
                      label: 'Opt-in',
                      value: true,
                    },
                    {
                      label: 'Opt-out',
                      value: false,
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default Contacts;
