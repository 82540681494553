import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { REGEX, ROUTES } from '../../../common/constants';
import { handleProtectedNavigation } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RouterPrompt from '../../../components/RouterPrompt';
import useRouter from '../../../hooks/useRouter';
import { CREATE_USER, UPDATE_USER } from '../graphql/Mutations';
import { GET_ACTIVE_ROLES, GET_LOCATIONS, GET_USER } from '../graphql/Queries';

function UserForm({ edit = '', dataLoading }) {
  const { navigate } = useRouter();
  const [form] = Form?.useForm();
  const [isPrompt, setIsPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isSubmitLoading, setisSibmitLoading] = useState(false);
  const { data: rolesData, loading: getRolesLoading } = useQuery(
    GET_ACTIVE_ROLES,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );
  const { data: locationsData, loading: getLocationsLoading } = useQuery(
    GET_LOCATIONS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const { loading } = useQuery(GET_USER, {
    skip: !edit,
    onCompleted: (res) => {
      form.setFieldsValue({
        email: res?.user?.email,
        fullname: `${res?.user?.firstName ? res?.user?.firstName : ''} ${
          res?.user?.lastName ? res?.user?.lastName : ''
        }`,
        location: res?.user?.locations?.map(({ id }) => id),
        role: res?.user?.roles?.[0]?.key,
      });
    },
    variables: {
      where: { id: edit },
    },
  });

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const onFinish = async (values) => {
    const [firstName = '', lastName = ''] = values?.fullname?.split(' ');
    try {
      setisSibmitLoading(true);
      if (!edit) {
        const res = await createUser({
          variables: {
            data: {
              firstName,
              lastName: isEmpty(lastName) ? null : lastName,
              roleKeys: values?.role,
              locationIds: values?.location,
              email: values?.email,
            },
          },
        });
        if (res) {
          setisSibmitLoading(true);
          navigate(ROUTES.SUPER_ADMIN);
        }
      } else {
        const res = await updateUser({
          variables: {
            data: {
              firstName,
              lastName: isEmpty(lastName) ? null : lastName,
              roleKeys: values?.role,
              locationIds: values?.location,
            },
            where: { id: edit },
          },
        });
        if (res) {
          setisSibmitLoading(true);
          navigate(ROUTES.SUPER_ADMIN);
        }
      }
    } catch (error) {
      setisSibmitLoading(true);
      //
    }
  };

  function endsWithEmailDomain(email, domains) {
    const domainRegex = new RegExp(`@(${domains.join('|')})$`);
    return domainRegex.test(email);
  }

  const { Title } = Typography;
  if (dataLoading) return <LoaderComponent />;
  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              onClick={() =>
                setIsPrompt(
                  !handleProtectedNavigation(!showPrompt, navigate, -1),
                )
              }
              icon={<ArrowLeftOutlined />}
            />
            {edit ? 'Edit User' : 'Add User'}
          </Title>
        }
        actions={[
          <div key="action-button" className="text-right">
            <Space>
              <Button
                onClick={() =>
                  setIsPrompt(
                    !handleProtectedNavigation(!showPrompt, navigate, -1),
                  )
                }
                disabled={isSubmitLoading}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form?.submit();
                }}
                disabled={isSubmitLoading}
              >
                Submit
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper">
          {!loading && (
            <Form
              name="detailsForm"
              form={form}
              onValuesChange={() => {
                setisSibmitLoading(false);
                setShowPrompt(true);
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="fullname"
                    label="Full Name"
                    rules={[
                      { message: 'Please enter Full Name!', required: true },
                      {
                        pattern: REGEX?.NAME,
                        message:
                          'Please enter a valid Full Name (letters and spaces only)!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Full Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="email"
                    label="Email address"
                    rules={[
                      {
                        validator: (_, value) => {
                          const domains =
                            process.env.REACT_APP_ALLOWED_EMAIL_DOMAIN;
                          if (!value) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise.reject('Please enter email!');
                          }
                          if (endsWithEmailDomain(value, domains?.split(','))) {
                            return Promise.resolve();
                          }
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise.reject(
                            `Email end with ${domains?.split(',')?.join(', ')}`,
                          );
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter email address" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                      {
                        message: 'Please select role!',
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Role"
                      optionFilterProp="children"
                      options={rolesData?.activeRoles?.roles?.map(
                        ({ name, key }) => ({
                          value: key,
                          label: name,
                        }),
                      )}
                      loading={getRolesLoading}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[
                      {
                        message: 'Please select location!',
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      size="middle"
                      placeholder="Please Location"
                      style={{ width: '100%' }}
                      options={locationsData?.activeLocations?.locations?.map(
                        ({ name, id }) => ({
                          value: id,
                          label: name,
                        }),
                      )}
                      loading={getLocationsLoading}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </Card>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
    </>
  );
}

export default UserForm;
