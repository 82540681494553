import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation logout {
    logout {
      message
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken($data: RefreshTokenInput!) {
    refreshToken(data: $data) {
      accessToken
      user {
        id
        email
        firstName
        lastName
        profileImage
        locationIds
        locations {
          id
          key
          name
        }
        role
        locations {
          key
          name
          id
        }
        forcePasswordReset
        disabledAt
        lastActiveOn
      }
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query UserProfile {
    userProfile {
      disabledAt
      email
      firstName
      forcePasswordReset
      id
      lastActiveOn
      lastName
      locationIds
      locations {
        createdAt
        createdBy
        id
        key
        name

        updatedAt
      }
      profileImage
      role
      roles {
        id
        key
        name
        permissions {
          access {
            read
            delete
            create
            update
          }
          module
        }
      }
    }
  }
`;
