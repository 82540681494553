import { Image } from 'antd';
import React from 'react';
import '../styles/whatsappPreview.css';

const Preview = ({ obj = null }) => (
  <div className="marvel-device nexus5">
    <div className="top-bar" />
    <div className="sleep" />
    <div className="volume" />
    <div className="camera" />
    <div className="screen">
      <div className="screen-container">
        <div className="status-bar">
          <div className="time" />
          <div className="battery">
            <i className="zmdi zmdi-battery" />
          </div>
          <div className="network">
            <i className="zmdi zmdi-network" />
          </div>
          <div className="wifi">
            <i className="zmdi zmdi-wifi-alt-2" />
          </div>
          <div className="star">
            <i className="zmdi zmdi-star" />
          </div>
        </div>
        <div className="chat">
          <div className="chat-container">
            <div className="user-bar">
              <div className="back">
                <i className="zmdi zmdi-arrow-left" />
              </div>
              <div className="avatar">
                <Image
                  src="https://i.ibb.co/2Yg7tWv/Rumbiiha-Swaibu.jpg"
                  alt="Avatar"
                />
              </div>
              <div className="name">
                <span>XYZ</span>
                <span className="status">online</span>
              </div>
              <div className="actions more">
                <i className="zmdi zmdi-more-vert" />
              </div>
              <div className="actions attachment">
                <i className="zmdi zmdi-phone" />
              </div>
              <div className="actions">
                <Image
                  src="https://i.ibb.co/LdnbHSG/ic-action-videocall.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="conversation">
              <div className="conversation-container">
                <div className="message received">
                  <span id="random">{obj}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Preview;
