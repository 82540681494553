import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import React from 'react';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import useRouter from '../../../hooks/useRouter';

const { TextArea } = Input;

function MasterForm({
  formType = '',
  isEdit = false,
  formData = [],
  loading = false,
  mutation,
  title,
}) {
  const { navigate } = useRouter();
  // const [] = useState(false)
  const [form] = Form.useForm();

  const { specialization, location, tag } = formData || {};
  const isSpecialization = formType === FORM_TYPE.specialization;
  const isLocation = formType === FORM_TYPE.location;
  const isViewLocation = formType === FORM_TYPE.view_location;
  const isViewSpecialization = formType === FORM_TYPE.view_specialization;
  const isViewTag = formType === FORM_TYPE.view_tag;
  const isTag = formType === FORM_TYPE.tag;

  const formInitialValues = {
    ...(((isSpecialization || isViewSpecialization) && specialization) ||
      ((isLocation || isViewLocation) && location) ||
      ((isTag || isViewTag) && tag)),
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (isEdit) {
          mutation({
            variables: {
              data: {
                ...values,
              },
              where: {
                id:
                  (isSpecialization && specialization?.id) ||
                  (isLocation && location?.id) ||
                  (isTag && tag?.id),
              },
            },
          });
        } else {
          mutation({
            variables: {
              data: {
                ...values,
              },
            },
          });
        }
      })
      .catch((errorInfo) => {
        // eslint-disable-next-line no-console
        console.log('Validation failed:', errorInfo);
      });
  };

  return !loading ? (
    <>
      <Card title={title} actions={[]}>
        <Form
          layout="vertical"
          form={form}
          initialValues={formInitialValues}
          disabled={isViewSpecialization || isViewLocation || isViewTag}
        >
          <Row gutter={8}>
            <Col>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: `Please enter name!`,
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
          </Row>
          {(isSpecialization || isViewSpecialization) && (
            <Row>
              <Form.Item label="Description" name="description">
                <TextArea rows={5} cols={45} placeholder="Enter Description" />
              </Form.Item>
            </Row>
          )}
        </Form>
        <div key="action-button" className="text-left">
          <Space>
            {isViewSpecialization || isViewLocation || isViewTag ? (
              <Button
                type="primary"
                onClick={() =>
                  navigate(
                    (isViewSpecialization &&
                      `${ROUTES.SPECIALIZATION}/edit/${specialization?.id}`) ||
                      (isViewLocation &&
                        `${ROUTES.LOCATIONS}/edit/${location?.id}`) ||
                      (isViewTag && `${ROUTES.TAGS}/edit/${tag?.id}`),
                  )
                }
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  onClick={() =>
                    navigate(
                      (isSpecialization && ROUTES.SPECIALIZATION) ||
                        (isLocation && ROUTES.LOCATIONS) ||
                        (isTag && ROUTES.TAGS),
                    )
                  }
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={handleFormSubmit}>
                  {isEdit ? 'Update' : 'Create'}
                </Button>
              </>
            )}
          </Space>
        </div>
      </Card>
    </>
  ) : (
    <LoaderComponent />
  );
}

export default MasterForm;
