import { useLazyQuery, useMutation } from '@apollo/client';
import { Card, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import { truncate } from 'lodash';
import React, { useState } from 'react';
import Calendar from '../../../assets/icon/Calendar';
import Copy from '../../../assets/icon/Copy';
import Delete from '../../../assets/icon/Delete';
import Edit from '../../../assets/icon/Edit';
import Pause from '../../../assets/icon/Pause';
import Resume from '../../../assets/icon/Resume';
import Send from '../../../assets/icon/Send';
import {
  MESSAGE_ACTIONS_BUTTONS,
  MESSAGE_STATUS,
  MESSAGE_STATUS_INPUT,
  ROUTES,
} from '../../../common/constants';
import useGetRole from '../../../hooks/useGetRole';
import useRouter from '../../../hooks/useRouter';
import WarningModel from '../../template/components/WarningModel';
import {
  DUPLICATE_MESSAGE,
  DUPLICATE_MESSAGE_ADMIN,
  PAUSE_MESSAGE,
  PAUSE_MESSAGE_ADMIN,
  RESUME_MESSAGE,
  RESUME_MESSAGE_ADMIN,
  SCHEDULE_MESSAGE,
  SCHEDULE_MESSAGE_ADMIN,
  SEND_MESSAGE,
  SEND_MESSAGE_ADMIN,
} from '../graphql/Mutations';
import { GET_MESSAGE_SENT_COUNT } from '../graphql/Queries';
import CommonButton from './CommonButton';

const MESSAGE_STATUS_ACTIONS = {
  [MESSAGE_STATUS.IN_DRAFT]: [
    MESSAGE_ACTIONS_BUTTONS.SCHEDULE,
    MESSAGE_ACTIONS_BUTTONS.SENT,
    MESSAGE_ACTIONS_BUTTONS.DUPLICATE,
    MESSAGE_ACTIONS_BUTTONS.DELETE,
    MESSAGE_ACTIONS_BUTTONS.EDIT,
  ],
  [MESSAGE_STATUS.SCHEDULE]: [
    MESSAGE_ACTIONS_BUTTONS.PAUSE,
    MESSAGE_ACTIONS_BUTTONS.DUPLICATE,
    MESSAGE_ACTIONS_BUTTONS.DELETE,
    MESSAGE_ACTIONS_BUTTONS.EDIT,
  ],
  [MESSAGE_STATUS.SENT]: [
    MESSAGE_ACTIONS_BUTTONS.DUPLICATE,
    MESSAGE_ACTIONS_BUTTONS.DELETE,
  ],
  [MESSAGE_STATUS.PAUSED]: [
    MESSAGE_ACTIONS_BUTTONS.RESUME,
    MESSAGE_ACTIONS_BUTTONS.DUPLICATE,
    MESSAGE_ACTIONS_BUTTONS.DELETE,
    MESSAGE_ACTIONS_BUTTONS.EDIT,
  ],
  [MESSAGE_STATUS.IN_PROCESS]: [
    MESSAGE_ACTIONS_BUTTONS.PAUSE,
    MESSAGE_ACTIONS_BUTTONS.DUPLICATE,
  ],
};

const STATUS_FONT_COLOR = {
  [MESSAGE_STATUS.IN_DRAFT]: { text: '#EA1818', bg: '#F7CECE' },
  [MESSAGE_STATUS.SENT]: { text: '#1e540c', bg: '#8aba86' },
  [MESSAGE_STATUS.SCHEDULE]: { text: '#F45612', bg: '#fb710d45' },
  [MESSAGE_STATUS.PAUSED]: { text: '#6D1CFF', bg: '#D3CDEC' },
};

const MessageCard = ({
  messageList = [],
  deleteMessageHandler,
  id = '',
  refetch,
  messageEditPermission = false,
  messageDeletePermission = false,
}) => {
  const permission = useGetRole();
  const { navigate } = useRouter();
  const availableButtons = MESSAGE_STATUS_ACTIONS?.[messageList?.status] || [];
  const statusColor = STATUS_FONT_COLOR[messageList?.status];
  const [isSchedulePastModel, setIsSchedulePastModal] = useState(false);
  const [isZeroCountWarning, setIsZeroCountWarning] = useState(false);
  const [isScheduleModel, setIsScheduleModal] = useState(false);
  const [isSendNowModel, setisisSendNowModel] = useState(false);
  const [isPauseModel, setisisPauseModel] = useState(false);
  const [sentMessageCount, setSentMessageCount] = useState(null);
  const hasNoContacts = messageList?.totalContacts === 0;
  const [getMesegeSentCount] = useLazyQuery(GET_MESSAGE_SENT_COUNT, {
    onError() {},
    fetchPolicy: 'network-only',
  });

  const [scheduleMessageAdmin] = useMutation(
    permission ? SCHEDULE_MESSAGE_ADMIN : SCHEDULE_MESSAGE,
    {
      onError() {},
    },
  );
  const [sendMessageAdmin] = useMutation(
    permission ? SEND_MESSAGE_ADMIN : SEND_MESSAGE,
    {
      onError() {},
    },
  );
  const [copyMessageAdmin] = useMutation(
    permission ? DUPLICATE_MESSAGE_ADMIN : DUPLICATE_MESSAGE,
    {
      onError() {},
    },
  );
  const [pauseMessageAdmin] = useMutation(
    permission ? PAUSE_MESSAGE_ADMIN : PAUSE_MESSAGE,
    {
      onError() {},
    },
  );
  const [resumeMessageAdmin] = useMutation(
    permission ? RESUME_MESSAGE_ADMIN : RESUME_MESSAGE,
    {
      onError() {},
    },
  );

  const renderButton = (button, icon, onClick) =>
    availableButtons.includes(button) && (
      <CommonButton icon={icon} label={button} onClick={onClick} />
    );

  return (
    <Card>
      <div className="button-wrapper">
        <div className="tags">
          <Tag
            bordered={false}
            color={statusColor?.bg}
            style={{ color: statusColor?.text }}
          >
            {MESSAGE_STATUS_INPUT[messageList?.status]}
          </Tag>
        </div>
        <Space className="buttons">
          {messageEditPermission &&
            renderButton(MESSAGE_ACTIONS_BUTTONS.PAUSE, <Pause />, async () => {
              const { data } = await getMesegeSentCount({
                variables: {
                  where: {
                    id: messageList?.id,
                  },
                },
              });
              if (data?.getMessageStatusCount) {
                setSentMessageCount(data?.getMessageStatusCount);
                setisisPauseModel(true);
              }
            })}
          {messageEditPermission &&
            renderButton(
              MESSAGE_ACTIONS_BUTTONS.RESUME,
              <Resume />,
              async () => {
                if (hasNoContacts) {
                  setIsZeroCountWarning(true);
                } else {
                  await resumeMessageAdmin({
                    variables: {
                      where: {
                        id: messageList?.id,
                      },
                    },
                  });
                  refetch();
                }
              },
            )}
          {messageEditPermission &&
            renderButton(
              MESSAGE_ACTIONS_BUTTONS.SCHEDULE,
              <Calendar />,
              async () => {
                const isScheduled = dayjs(
                  messageList?.scheduledDate,
                )?.isAfter();
                if (isScheduled) {
                  setIsZeroCountWarning(hasNoContacts);
                  setIsScheduleModal(!hasNoContacts);
                } else {
                  setIsZeroCountWarning(hasNoContacts);
                  setIsSchedulePastModal(!hasNoContacts);
                }
              },
            )}
          {messageEditPermission &&
            renderButton(MESSAGE_ACTIONS_BUTTONS.SENT, <Send />, async () => {
              if (messageList?.totalContacts === 0) {
                setIsZeroCountWarning(true);
              } else {
                setisisSendNowModel(true);
              }
            })}
          {messageEditPermission &&
            renderButton(
              MESSAGE_ACTIONS_BUTTONS.DUPLICATE,
              <Copy />,
              async () => {
                const { data } = await copyMessageAdmin({
                  variables: {
                    where: {
                      id: messageList?.id,
                    },
                  },
                });
                if (data) {
                  navigate(
                    `${ROUTES.CAMPAIGN}/${data?.duplicateMessageAdmin?.data?.id}${ROUTES.MESSAGE}/edit`,
                  );
                }
              },
            )}
          {messageEditPermission &&
            renderButton(MESSAGE_ACTIONS_BUTTONS.EDIT, <Edit />, () =>
              navigate(
                `${ROUTES.CAMPAIGN}/${messageList?.id}${ROUTES.MESSAGE}/edit`,
              ),
            )}
          {messageDeletePermission &&
            renderButton(MESSAGE_ACTIONS_BUTTONS.DELETE, <Delete />, () =>
              deleteMessageHandler(messageList?.id),
            )}
        </Space>
      </div>
      <div>
        <p className="card-header">
          {truncate(messageList?.template?.name, { length: 25 })}
        </p>
        <p className="contact_container">
          To:{' '}
          <span
            className="contact"
            onClick={() =>
              navigate(
                `${ROUTES.CAMPAIGN}/${id}${ROUTES.MESSAGE}/${messageList?.id}${ROUTES.CONTACTS}`,
              )
            }
          >
            {messageList?.totalContacts} Contacts
          </span>
        </p>
        <p className="tag-container">
          <Tag bordered={false} color="default" icon={<Calendar />}>
            {dayjs(messageList?.scheduledDate)?.format('Do MMMM, YYYY, h:mm A')}
          </Tag>
        </p>
      </div>
      <WarningModel
        title="Warning!"
        description="Your campaign will not run due to the absence of contacts in this segment. It is imperative that you review the details and confirm that you have accurately targeted the intended audience."
        open={isZeroCountWarning}
        onOk={() => setIsZeroCountWarning(false)}
        cancelButtonProps={{
          className: 'hidden',
        }}
        okText="OK"
        closable={false}
      />
      <WarningModel
        title="Please select future date"
        description="Sorry the scheduled date has already been passed. Please update the scheduled date and time"
        open={isSchedulePastModel}
        onCancel={() => setIsSchedulePastModal(false)}
        onOk={() =>
          navigate(
            `${ROUTES.CAMPAIGN}/${messageList?.id}${ROUTES.MESSAGE}/edit`,
          )
        }
      />
      <WarningModel
        title="Schedule"
        description="Please confirm that you have reviewed this message and it is ready to be scheduled."
        open={isScheduleModel}
        onCancel={() => setIsScheduleModal(false)}
        onOk={() =>
          scheduleMessageAdmin({
            variables: {
              where: {
                id: messageList?.id,
              },
            },
          }).then((res) => {
            if (res?.data) refetch();
          })
        }
      />
      <WarningModel
        title="Send now"
        description="Are you sure you wish to start sending this message right away?"
        open={isSendNowModel}
        onCancel={() => setisisSendNowModel(false)}
        onOk={() =>
          sendMessageAdmin({
            variables: {
              where: {
                id: messageList?.id,
              },
            },
          }).then((res) => {
            if (res?.data) refetch();
          })
        }
      />
      <WarningModel
        title="Pause"
        description={
          !sentMessageCount?.send > 0
            ? 'This message has already been scheduled. Are you sure you want to pause this message? You will need to manually schedule it again when required.'
            : `This message has already been scheduled and sent to ${
                sentMessageCount?.send || 0
              } contacts. Are you sure you want to pause this message? The message will not be sent to remaining contacts and you will need to schedule the message again when required.`
        }
        open={isPauseModel}
        onCancel={() => setisisPauseModel(false)}
        onOk={async () =>
          pauseMessageAdmin({
            variables: {
              where: {
                id: messageList?.id,
              },
            },
          }).then((res) => {
            if (res?.data) refetch();
          })
        }
      />
    </Card>
  );
};

export default MessageCard;
