import { Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import React, { useMemo } from 'react';

import {
  COUNTRY_CODE,
  GENDER,
  NAME_PREFIX,
  OPERATOR,
  SOURCE,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CountrySelect from '../../contacts/components/CountrySelect';
import SpecializationSelect from '../../contacts/components/SpecializationSelect';
import StateSelect from '../../contacts/components/StateSelect';
import SelecActivetLocation from './SelecActivetLocation';
import TagSelect from './TagSelect';

const { operator, valueBySelect, valueByInput } = formValidatorRules;

export const PrimaryCode = ({ name, dynamicInputLoader = false }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Primary Code</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select country code"
                options={COUNTRY_CODE}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const SecondaryCode = ({ name, dynamicInputLoader }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Secondary Code</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select country code"
                options={COUNTRY_CODE}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
export const Location = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Location</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <SelecActivetLocation />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Gender = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Gender</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select placeholder="Select gender" options={GENDER} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
export const State = ({ name, form, field }) => {
  const countryId = Form.useWatch(['items', field.name, 'list', name], form)
    ?.country?.value;
  const stateVariables = useMemo(() => ({ countryId }), [countryId]);

  return (
    <>
      <Row className="segment-rule-container mb-8" key="id">
        {/* attribute column */}

        <Col
          span={6}
          className="d-flex align-center segment-rule-sub-container p-16"
        >
          <div>State</div>
        </Col>

        <Col className="p-16" span={18}>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name={[name, 'operator']}
                className="form-filed-custom"
                rules={[{ ...operator }]}
              >
                <Select
                  placeholder="Select operator"
                  options={OPERATOR.COMPARISON}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              {/* value column */}
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    name={[name, 'country']}
                    className="form-filed-custom"
                    rules={[{ ...valueBySelect }]}
                  >
                    <CountrySelect
                      onChange={(Country) => {
                        form.setFieldValue([name, 'country'], Country);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={[name, 'value']}
                    className="form-filed-custom"
                    rules={[{ ...valueBySelect }]}
                  >
                    <StateSelect
                      disabled={!countryId}
                      variables={stateVariables}
                      queryOptions={{
                        skip: !countryId,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export const Specialization = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Specialization</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.CONTAINS_NOT_CONTAINS}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <SpecializationSelect />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const ZipCode = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Zip Code</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[
                { ...valueByInput },
                {
                  validator: (_, value) => {
                    if (value && value.length > 6) {
                      return Promise.reject(new Error('Invalid Zip Code'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter zip code"
                className="full-width"
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Tag = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Tag</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.CONTAINS_NOT_CONTAINS}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <TagSelect />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const CreatedAt = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Created At</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.BEFORE_AFTER}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <DatePicker placeholder="Enter date" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Status = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Status</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Radio.Group name="optInConsent">
                <Radio value="true">Active</Radio>
                <Radio value="false">In-active</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Source = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Source</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select placeholder="Select source" options={SOURCE} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const OptInConsent = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Opt In Consent</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              initialValue="EQUALS"
              name={[name, 'operator']}
              className="form-filed-custom"
            >
              <Input disabled variant="borderless" />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Radio.Group name="optInConsent">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const NamePrefix = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Prefix</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              initialValue="EQUALS"
              name={[name, 'operator']}
              className="form-filed-custom"
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select placeholder="Select source" options={NAME_PREFIX} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
