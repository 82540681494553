import { ArrowLeftOutlined } from '@ant-design/icons';
import { Badge, Button, Tabs, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useGetRole from '../../hooks/useGetRole';
import useInfiniteQuery from '../../hooks/useInfiniteQuery';
import useRouter from '../../hooks/useRouter';
import MessageAnalytics from './MessageAnalytics';
import MessageListing from './MessageListing';
import {
  GET_CAMPAIGN_MESSAGE_ADMIN,
  GET_CAMPAIGN_MESSAGE_USER,
} from './graphql/Queries';
import './styles/campaign.less';

const { Title } = Typography;

const variableSelector = ({ skip, limit, id, field, order }) => ({
  filter: {
    skip,
    limit,
  },
  where: {
    id,
  },
  sort: {
    field,
    order,
  },
});

const dataKeysForAdmin = {
  data: 'messagesAdmin',
  records: 'data',
  count: 'count',
};
const dataKeys = {
  data: 'messages',
  records: 'data',
  count: 'count',
};

function Message() {
  const { navigate } = useRouter();
  const permission = useGetRole();
  const { id } = useParams();

  const variables = useMemo(
    () => ({
      field: 'createdAt',
      order: 'DESC',
      id,
    }),
    [id],
  );

  const {
    loadingMore,
    loading,
    data,
    fetchMore,
    count,
    hasMore,
    refetch,
  } = useInfiniteQuery(
    permission ? GET_CAMPAIGN_MESSAGE_ADMIN : GET_CAMPAIGN_MESSAGE_USER,
    {
      fetchPolicy: 'network-only',
      variables,
      variableSelector,
      dataKeys: permission ? dataKeysForAdmin : dataKeys,
    },
  );

  const items = [
    {
      key: 'message-list',
      label: (
        <Title className="site-page-header p-0 mb-8 mt-0 d-flex" level={3}>
          <Button
            type="text"
            shape="circle"
            onClick={() => navigate(-1)}
            icon={<ArrowLeftOutlined />}
          />
          <div className="d-flex align-center gap-8">
            Messages
            <Badge count={count?.toString()?.padStart(2, '0')} />
          </div>
        </Title>
      ),
      children: (
        <MessageListing
          permission={permission}
          loadingMore={loadingMore}
          loading={loading}
          data={data}
          fetchMore={fetchMore}
          count={count}
          hasMore={hasMore}
          refetch={refetch}
        />
      ),
    },
    {
      key: '2',
      label: (
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          Analytics
        </Title>
      ),
      children: <MessageAnalytics permission={permission} />,
    },
  ];

  return (
    <>
      <Tabs defaultActiveKey="message-list" items={items} />
    </>
  );
}

export default Message;
