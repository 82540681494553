import { Col, Form, Input, Select } from 'antd';
import React from 'react';
import { TEMPLATE_DYNAMIC_FILED_VALUE } from '../../../common/constants';

const DynamicValue = ({ matches, disabled, name, hidden }) => (
  <Form.List name={name}>
    {() => (
      <>
        {matches?.map((_, index) => (
          <>
            <Col xs={24} sm={24} md={12}>
              <Form.Item name={[index, 'key']} hidden={hidden}>
                <Select
                  options={TEMPLATE_DYNAMIC_FILED_VALUE}
                  placeholder={`Select dynamic value defualt option ${
                    index + 1
                  }`}
                  allowClear
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={[index, 'value']}
                rules={[
                  {
                    required: true,
                    message: 'Missing value',
                  },
                ]}
                hidden={hidden}
              >
                <Input
                  placeholder={`Enter dynamic Input ${index + 1}`}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          </>
        ))}
      </>
    )}
  </Form.List>
);
export default DynamicValue;
