import { LockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { RESET_PASSWORD } from './graphql/Mutations';

const { required, password } = formValidatorRules;

const ChangePassword = () => {
  const { navigate } = useRouter();
  const { dispatch } = useContext(AppContext);
  const [resetPassword, { loading: resetLoading }] = useMutation(
    RESET_PASSWORD,
  );

  const onFinish = async ({ newPassword, oldPassword }) => {
    try {
      const response = await resetPassword({
        variables: {
          data: {
            newPassword,
            oldPassword,
          },
        },
      });
      if (response) {
        navigate(ROUTES.LOGOUT, { replace: true });
        dispatch({ type: 'LOGOUT' });
        // eslint-disable-next-line no-undef
        window.location = ROUTES.LOGIN;
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <div className="login-wrap d-flex align-center justify-center">
      <Card className="full-width">
        <div className="text-center">
          <img src="/logo-with-text.png" alt="logo" />
          <h2 className="text-center">Reset Password</h2>
          <p className="text-center">Enter a new password for your account</p>
        </div>
        <Spin spinning={resetLoading}>
          <Form
            name="reset-password"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
            layout="vertical"
          >
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[{ required, message: 'Please enter your old password' }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter your old password"
                allowClear
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                { required, message: 'Please enter your new password' },
                password,
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter your new password"
                allowClear
              />
            </Form.Item>
            <Form.Item className="full-width mb-8">
              <Button type="primary" className="full-width" htmlType="submit">
                Reset Password
              </Button>
            </Form.Item>
            <Form.Item className="text-center mb-0">
              <Button
                type="link"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};
export default ChangePassword;
