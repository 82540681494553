import React from 'react';
import UserForm from './components/UserForm';

const AddUser = () => (
  <div>
    <UserForm />
  </div>
);

export default AddUser;
