import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Badge, Button, Card, Descriptions, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { DATE_FORMATS, ROLE_KEYS, ROUTES } from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import useRouter from '../../hooks/useRouter';
import { GET_CONTACT_LOGS, GET_USER_CONTACT_LOGS } from './graphql/Queries';

const { Title } = Typography;
const ContactLogs = () => {
  const { getCurrentUser } = useContext(AppContext);
  const userData = getCurrentUser();
  const { navigate, params } = useRouter();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    currentPage: 0,
  });
  const [listSort, setListSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });

  const { loading: logsLoading, data: logsData } = useQuery(
    userData?.role === ROLE_KEYS.USER
      ? GET_USER_CONTACT_LOGS
      : GET_CONTACT_LOGS,
    {
      fetchPolicy: 'network-only',
      variables: {
        where: {
          id: params?.id,
        },
        sort: {
          sortOn: listSort?.sortOn,
          sortBy: listSort?.sortBy,
        },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
        },
      },
      onError() {},
    },
  );

  const dataKey =
    userData?.role === ROLE_KEYS.USER
      ? 'contactEditHistories'
      : 'contactEditHistoriesAdmin';

  const formattedData = logsData?.[dataKey]?.contactEditHistories?.map(
    ({ id, ...rest }) => ({
      ...rest,
      key: id,
    }),
  );

  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig((prev) => ({
      ...prev,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const columns = [
    {
      title: 'Contact Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.contact?.name ?? '-',
    },

    {
      title: 'Updated At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (value) => dayjs(value).format(DATE_FORMATS.WITH_TIME),
    },

    {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: (_, record) => {
        const userName = record?.editor?.lastName
          ? `${record?.editor?.firstName} ${record?.editor?.lastName}`
          : `${record?.editor?.firstName ?? '-'}`;
        return userName;
      },
    },
  ];
  return (
    <>
      <Card
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(ROUTES.CONTACTS)}
            />

            <div className="d-flex align-center gap-8">
              Logs
              <Badge
                count={logsData?.contactEditHistoriesAdmin?.count
                  ?.toString()
                  ?.padStart(2, '0')}
              />
            </div>
          </Title>
        }
        className="ant-body-scroll"
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            columns={columns}
            data={formattedData}
            onChange={handleTableChange}
            loadingData={logsLoading}
            scroll={{ y: 550 }}
            paginationConfig={{
              ...paginationConfig,
              total: logsData?.contactEditHistoriesAdmin?.count,
              showSizeChanger: false,
            }}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <Descriptions bordered>
                    <Descriptions.Item label="New Value" span={1}>
                      <Descriptions>
                        {Object.entries(record?.newValue).map(
                          ([key, value]) => (
                            <Descriptions.Item span={24} key={key} label={key}>
                              {value === null ? 'null' : value.toString()}
                            </Descriptions.Item>
                          ),
                        )}
                      </Descriptions>
                    </Descriptions.Item>

                    <Descriptions.Item label="Old Value" span={2}>
                      <Descriptions>
                        {Object.entries(record?.oldValue).map(
                          ([key, value]) => (
                            <Descriptions.Item span={24} key={key} label={key}>
                              {value === null ? 'null' : value.toString()}
                            </Descriptions.Item>
                          ),
                        )}
                      </Descriptions>
                    </Descriptions.Item>
                  </Descriptions>
                </>
              ),
            }}
          />
        </div>
      </Card>
    </>
  );
};
export default ContactLogs;
