import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { FORM_TYPE, ROUTES } from '../../../common/constants';
import MasterForm from '../components/MasterForm';
import MasterFormTitle from '../components/MasterFormTitle';
import { GET_TAG } from './graphql/Queries';

function ViewTag() {
  const { id } = useParams();
  const { data: specializationData, loading: fetchLoading } = useQuery(
    GET_TAG,
    {
      variables: { where: { id } },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  return (
    <>
      <MasterForm
        title={
          <MasterFormTitle
            navigation={ROUTES.TAGS}
            title={FORM_TYPE.view_tag}
            isView
          />
        }
        formType={FORM_TYPE.view_tag}
        isView
        formData={specializationData}
        loading={fetchLoading}
      />
    </>
  );
}

export default ViewTag;
