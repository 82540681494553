import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query Locations($filter: LocationsFilter, $sort: LocationsSort) {
    locations(filter: $filter, sort: $sort) {
      count
      locations {
        createdAt
        createdBy
        id
        key
        name
        isActive
        updatedAt
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query Location($where: LocationWhereUniqueInput!) {
    location(where: $where) {
      createdAt
      createdBy
      id
      key
      name
      updatedAt
    }
  }
`;
