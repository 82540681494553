import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import {
  MESSAGE_STATUS,
  MODULES_KEY,
  PERMISSION_OBJECT,
} from '../../../common/constants';
import { handleProtectedNavigation } from '../../../common/utils';
import { messageContext } from '../../../components/AppContextHolder';
import CommonDropdown from '../../../components/CommonDropdown';
import RouterPrompt from '../../../components/RouterPrompt';
import useGetRole from '../../../hooks/useGetRole';
import useRouter from '../../../hooks/useRouter';
import useVerifyPermissions from '../../../hooks/useVerifyPermissions';
import { GET_COUNTRY_BY_STATE } from '../../segments/graphql/Queries';
import WarningModel from '../../template/components/WarningModel';
import {
  ADD_ADMIN_MESSAGE,
  ADD_MESSAGE,
  UPDATE_MESSAGE,
  UPDATE_MESSAGE_ADMIN,
} from '../graphql/Mutations';
import {
  GET_ADMIN_MESSAGE,
  GET_ADMIN_SEGMENTS,
  GET_LOCATION,
  GET_MESSAGE_ADMIN_TEMPLATE,
  GET_MESSAGE_TEMPLATE,
  GET_SEGMENTS,
  GET_SEGMENT_MESSAGE_CONTACT_COUNT,
  GET_SPECIALIZATION,
  GET_TAG,
  GET_TIMEZONE,
  GET_USER_MESSAGE,
} from '../graphql/Queries';
import '../styles/message.less';
import DateTimePicker from './DateTimePicker';
import SegmentForm from './SegmentForm';

function MessageForm({ edit }) {
  const { navigate } = useRouter();
  const { getCurrentUser } = useContext(AppContext);
  const { defaultLocation } = getCurrentUser() || {};
  const permission = useGetRole();
  const [form] = Form?.useForm();
  const IsModelFiledValue = Form.useWatch(['segmentName'], form);
  const { id } = useParams();

  const [isSelectedSegment, setIsSelectedSegment] = useState({
    check: false,
    segment: false,
  });
  const [isPrompt, setIsPrompt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isSegmentnameModel, setIsSegmentnameModel] = useState();
  const [activeTimezones, setActiveTimeZones] = useState([]);
  const [isNoSchedule, setIsNoSchedule] = useState(false);
  const segmentAddPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.SEGMENT_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.CREATE,
  });

  const dataKeyMessage = permission ? 'messageAdmin' : 'message';
  const dataKeyTemplate = permission
    ? 'messageTemplatesAdmin'
    : 'messageTemplates';
  const dataKeySegment = permission ? 'segmentsAdmin' : 'segments';
  const { data: tamplateList, loading: templateListLoading } = useQuery(
    permission ? GET_MESSAGE_ADMIN_TEMPLATE : GET_MESSAGE_TEMPLATE,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { loading: timezoneLoading } = useQuery(GET_TIMEZONE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setActiveTimeZones(res);
      const time =
        res?.activeTimezones?.data?.filter((item) => {
          if (item?.city === 'Kolkata') {
            return item;
          }
          return null;
        })?.[0] ?? {};
      form.setFieldValue('timezoneId', {
        label: `${time?.city}(${time?.shortCode}: ${time?.remark}, ${time?.offset})`,
        value: time?.id,
      });
    },
    onError() {},
  });

  const [getCountryByState] = useLazyQuery(GET_COUNTRY_BY_STATE, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getSpecialization] = useLazyQuery(GET_SPECIALIZATION, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getCountryById] = useLazyQuery(GET_LOCATION, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [getTag] = useLazyQuery(GET_TAG, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { data: segmentData, loading: segmentLoading } = useQuery(
    permission ? GET_ADMIN_SEGMENTS : GET_SEGMENTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { loading: messageLoading } = useQuery(
    permission ? GET_ADMIN_MESSAGE : GET_USER_MESSAGE,
    {
      skip: !edit,
      onCompleted: async (res) => {
        const messageData = res?.[dataKeyMessage]?.data;
        const time = messageData?.timezone;

        const getLocation = async (locationId) => {
          try {
            const { data, error } = await getCountryById({
              variables: {
                where: {
                  id: locationId,
                },
              },
            });
            if (error || !data) return null;
            return data?.location;
          } catch (err) {
            return null;
          }
        };

        const getCountries = async (stateId) => {
          try {
            const { data, error } = await getCountryByState({
              variables: {
                where: {
                  stateId,
                },
              },
            });
            if (error || !data) return null;
            return data?.state;
          } catch (err) {
            return null;
          }
        };

        const getSpecializations = async (specializationId) => {
          try {
            const { data, error } = await getSpecialization({
              variables: {
                where: {
                  id: specializationId,
                },
              },
            });
            if (error || !data) return null;
            return data?.specialization;
          } catch (err) {
            return null;
          }
        };

        const getTags = async (tagId) => {
          try {
            const { data, error } = await getTag({
              variables: {
                where: {
                  id: tagId,
                },
              },
            });
            if (error || !data) return null;
            return data?.tag;
          } catch (err) {
            return null;
          }
        };

        const stateIds = messageData?.contactConditions
          ?.map((segment) =>
            segment?.conditions
              ?.filter((condition) => condition?.attribute === 'STATE')
              ?.map((conditions) => conditions?.value),
          )
          ?.flat();

        const states = await Promise.all(stateIds?.map(getCountries));

        const stateObj = Object.fromEntries(
          stateIds?.map((stateId, index) => [stateId, states[index]]),
        );

        const locationIds = messageData?.contactConditions
          ?.map((segment) =>
            segment?.conditions
              ?.filter((condition) => condition?.attribute === 'LOCATION')
              ?.map((conditions) => conditions?.value),
          )
          ?.flat();

        const locations = await Promise.all(locationIds.map(getLocation));

        const locationObj = Object.fromEntries(
          locationIds?.map((locationId, index) => [
            locationId,
            locations[index],
          ]),
        );

        const SpecializationIds = messageData?.contactConditions
          ?.map((specialization) =>
            specialization?.conditions
              ?.filter((condition) => condition?.attribute === 'SPECIALIZATION')
              ?.map((conditions) => conditions?.value),
          )
          ?.flat();

        const Specializations = await Promise.all(
          SpecializationIds?.map(getSpecializations),
        );

        const SpecializationObj = Object.fromEntries(
          SpecializationIds?.map((SpecializationsId, index) => [
            SpecializationsId,
            Specializations[index],
          ]),
        );

        const tagIds = messageData?.contactConditions
          ?.map((specialization) =>
            specialization?.conditions
              ?.filter((condition) => condition?.attribute === 'TAGS')
              ?.map((conditions) => conditions?.value),
          )
          ?.flat();

        const tags = await Promise.all(tagIds?.map(getTags));

        const tagObj = Object.fromEntries(
          tagIds?.map((tagId, index) => [tagId, tags[index]]),
        );
        const getValue = (condition) => {
          switch (condition?.attribute) {
            case 'STATE':
              return {
                value: {
                  label: stateObj[condition?.value]?.name ?? '',
                  value: condition?.value ?? '',
                },
                country: {
                  value: stateObj[condition?.value]?.country?.id ?? '',
                  label: stateObj[condition?.value]?.country?.name ?? '',
                },
              };
            case 'LOCATION':
              return {
                value: {
                  label: locationObj[condition?.value]?.name ?? '',
                  value: condition?.value ?? '',
                },
              };
            case 'SPECIALIZATION':
              return {
                value: {
                  label: SpecializationObj[condition?.value]?.name ?? '',
                  value: condition?.value ?? '',
                },
              };
            case 'TAGS':
              return {
                value: {
                  label: tagObj[condition?.value]?.name ?? '',
                  value: condition?.value ?? '',
                },
              };

            default:
              return {
                value: condition?.value,
              };
          }
        };

        const items = messageData?.contactConditions?.map((segment) => ({
          list: segment?.conditions?.map((condition) => ({
            id: condition?.attribute,
            operator: condition?.operator,
            ...getValue(condition),
          })),
        }));

        setIsSelectedSegment((prev) => ({
          ...prev,
          segment: messageData?.contactConditions?.length > 0,
        }));
        form?.setFieldsValue({
          ...messageData,
          scheduledDate: dayjs(messageData?.scheduledDate),
          templateId: {
            label: messageData?.template?.name,
            value: messageData?.template?.id,
          },
          timezoneId: {
            label: `${time?.city}(${time?.shortCode}: ${time?.remark}, ${time?.offset})`,
            value: time?.id,
          },
          segmentId: {
            label: messageData?.segment?.name,
            value: messageData?.segment?.id,
          },
          segment: messageData?.contactConditions?.length > 0,
          items,
        });
      },
      variables: {
        where: {
          id: edit,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [creatAdminMessage] = useMutation(
    permission ? ADD_ADMIN_MESSAGE : ADD_MESSAGE,
    {
      onError() {},
    },
  );
  const [updateAdminMessage] = useMutation(
    permission ? UPDATE_MESSAGE_ADMIN : UPDATE_MESSAGE,
    {
      onError() {},
    },
  );

  const [getContactsCount] = useLazyQuery(GET_SEGMENT_MESSAGE_CONTACT_COUNT, {
    onError() {},
  });

  const onFinish = async (value) => {
    const { data } = await getContactsCount({
      variables: {
        where: {
          ...(value?.segmentId?.value && {
            segmentId: value?.segmentId?.value,
          }),

          ...(value?.items?.length > 0 && {
            conditions: value?.items?.map((item) => ({
              conditions: item?.list?.map(
                ({ id: spId, operator, value: conditionValue }) => ({
                  attribute: spId,
                  operator,
                  value: conditionValue?.value || conditionValue,
                }),
              ),
            })),
          }),
        },
      },
    });
    if (
      data?.segmentContacts?.count > 0 ||
      value?.status?.value === MESSAGE_STATUS?.IN_DRAFT
    ) {
      try {
        if (!edit) {
          await creatAdminMessage({
            variables: {
              where: {
                id,
              },
              data: {
                ...(!permission && { locationId: defaultLocation?.id }),
                createAsSegment: isSelectedSegment?.check,
                ...(value?.items?.length > 0 && {
                  segmentConditions: value?.items?.map((item) => ({
                    conditions: item?.list?.map(
                      ({ id: spId, operator, value: conditionValue }) => ({
                        attribute: spId,
                        operator,
                        value: conditionValue?.value || conditionValue,
                      }),
                    ),
                  })),
                }),
                ...(value?.segmentId && { segmentId: value?.segmentId?.value }),
                segmentName: value?.segmentName,
                ...(value?.scheduledDate && {
                  scheduledDate: value?.scheduledDate?.format(),
                }),
                ...(value?.status && { status: value?.status?.value }),
                templateId: value?.templateId?.value,
                timezoneId: value?.timezoneId?.value,
              },
            },
          });
        } else {
          updateAdminMessage({
            variables: {
              where: {
                id: edit,
              },
              data: {
                ...(value?.items?.length > 0 && {
                  segmentConditions: value?.items?.map((item) => ({
                    conditions: item?.list?.map(
                      ({ id: spId, operator, value: conditionValue }) => ({
                        attribute: spId,
                        operator,
                        value: conditionValue?.value || conditionValue,
                      }),
                    ),
                  })),
                }),
                ...(value?.segmentId && { segmentId: value?.segmentId?.value }),
                segmentName: value?.segmentName?.value || value?.segmentName,
                ...(value?.scheduledDate && {
                  scheduledDate: value?.scheduledDate?.format(),
                }),
                createAsSegment: isSelectedSegment?.check,
                templateId: value?.templateId?.value,
                timezoneId: value?.timezoneId?.value,
              },
            },
          });
        }
      } catch (error) {
        messageContext?.error(error?.message);
      } finally {
        navigate(-1);
      }
    } else {
      setIsNoSchedule(true);
    }
  };

  const handleOk = () => {
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsPrompt(false);
  };

  const handleShowPrompt = () => {
    setShowPrompt(true);
  };
  const { Title } = Typography;
  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <Title
            className="form-title-header d-flex align-center gap-8"
            level={3}
          >
            <Button
              type="text"
              shape="circle"
              onClick={() =>
                setIsPrompt(
                  !handleProtectedNavigation(!showPrompt, navigate, -1),
                )
              }
              icon={<ArrowLeftOutlined />}
            />
            {edit ? 'Edit Message' : 'Add Message'}
          </Title>
        }
        loading={messageLoading}
        actions={[
          <div key="action-button" className="text-right">
            <Space>
              <Button
                onClick={() =>
                  setIsPrompt(
                    !handleProtectedNavigation(!showPrompt, navigate, -1),
                  )
                }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  form?.submit();
                }}
              >
                Submit
              </Button>
            </Space>
          </div>,
        ]}
      >
        <div className="card-body-wrapper">
          {!messageLoading && (
            <Form
              name="detailsForm"
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onValuesChange={handleShowPrompt}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item name="status" label="Status">
                    <CommonDropdown
                      disabled={edit}
                      list={[
                        {
                          label: 'Schedule',
                          value: MESSAGE_STATUS.SCHEDULE,
                        },
                        {
                          label: 'Draft',
                          value: MESSAGE_STATUS.IN_DRAFT,
                        },
                      ]}
                      placeholder="Select status"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="templateId"
                    label="Template"
                    rules={[
                      {
                        message: 'Please select template!',
                        required: true,
                      },
                    ]}
                  >
                    <CommonDropdown
                      list={tamplateList?.[dataKeyTemplate]?.templates?.map(
                        ({ id: templateId, name }) => ({
                          label: name,
                          value: templateId,
                        }),
                      )}
                      placeholder="Select template"
                      loading={templateListLoading}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="timezoneId"
                    label="Time Zone"
                    rules={[
                      {
                        message: 'Please select time zone!',
                        required: true,
                      },
                    ]}
                  >
                    <CommonDropdown
                      list={activeTimezones?.activeTimezones?.data?.map(
                        ({
                          id: timeZoneId,
                          city,
                          shortCode,
                          offset,
                          remark,
                        }) => ({
                          label: `${city}(${shortCode}: ${remark}, ${offset})`,
                          value: timeZoneId,
                        }),
                      )}
                      placeholder="Select time zone"
                      loading={timezoneLoading}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    name="scheduledDate"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: 'Please select date and time',
                      },
                      {
                        validator(_, value) {
                          if (!value) return Promise?.resolve();
                          if (value && dayjs(value)?.isAfter()) {
                            return Promise?.resolve();
                          }
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'This selected date is not valid!',
                          );
                        },
                      },
                    ]}
                  >
                    <DateTimePicker />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                  <Form.Item
                    name="segment"
                    label={<b>Segment</b>}
                    hidden={!segmentAddPermission}
                  >
                    <Space>
                      <Typography>Select</Typography>
                      <Switch
                        onChange={(value) =>
                          setIsSelectedSegment((prev) => ({
                            ...prev,
                            segment: value,
                          }))
                        }
                        value={isSelectedSegment?.segment}
                        disabled={!segmentAddPermission}
                      />
                      <Typography>Create</Typography>
                    </Space>
                  </Form.Item>
                </Col>
                {!isSelectedSegment.segment ? (
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      label={!segmentAddPermission && 'Segment'}
                      name="segmentId"
                      rules={[
                        {
                          message: 'Please select segment!',
                          required: true,
                        },
                      ]}
                    >
                      <CommonDropdown
                        list={segmentData?.[dataKeySegment]?.segments?.map(
                          ({ id: timeZoneId, name }) => ({
                            label: name,
                            value: timeZoneId,
                          }),
                        )}
                        placeholder="Select segment"
                        loading={segmentLoading}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <SegmentForm form={form} edit={edit} />
                  </Col>
                )}
                {isSelectedSegment.segment && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Form.Item name="checked" valuePropName="checked">
                      <Checkbox
                        onChange={(e) => {
                          setIsSelectedSegment((prev) => ({
                            ...prev,
                            check: e?.target?.checked,
                          }));
                          if (e?.target?.checked) {
                            setIsSegmentnameModel(true);
                          }
                        }}
                      >
                        Do you want to create new segment?
                      </Checkbox>
                    </Form.Item>
                  </Col>
                )}
                {isSelectedSegment?.check && (
                  <Modal
                    title="Create New Segment"
                    okText="Yes"
                    open={isSegmentnameModel}
                    onOk={() => setIsSegmentnameModel(false)}
                    okButtonProps={{
                      disabled: !IsModelFiledValue,
                    }}
                    onCancel={async () => {
                      form?.setFieldValue('checked', false);
                      setIsSelectedSegment((prev) => ({
                        ...prev,
                        check: false,
                      }));
                      setIsSegmentnameModel(false);
                    }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Form.Item
                          colon={false}
                          name="segmentName"
                          label="Segment Name"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Enter segment name" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Modal>
                )}
              </Row>
            </Form>
          )}
        </div>
      </Card>
      <RouterPrompt
        isPrompt={isPrompt}
        handleOK={handleOk}
        handleCancel={handleClose}
      />
      <WarningModel
        title="Warning!"
        description="Your campaign will not run due to the absence of contacts in this segment. It is imperative that you review the details and confirm that you have accurately targeted the intended audience."
        open={isNoSchedule}
        onOk={() => setIsNoSchedule(false)}
        cancelButtonProps={{
          className: 'hidden',
        }}
        okText="OK"
        closable={false}
      />
    </>
  );
}

export default MessageForm;
