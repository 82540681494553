import React from 'react';
import MessageForm from './components/MessageForm';

const AddMessage = () => (
  <div>
    <MessageForm />
  </div>
);

export default AddMessage;
