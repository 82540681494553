import React from 'react';

const Copy = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6667 8.60001V11.4C10.6667 13.7333 9.73337 14.6667 7.40004 14.6667H4.60004C2.26671 14.6667 1.33337 13.7333 1.33337 11.4V8.60001C1.33337 6.26668 2.26671 5.33334 4.60004 5.33334H7.40004C9.73337 5.33334 10.6667 6.26668 10.6667 8.60001Z"
      stroke="#14110F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 4.60001V7.40001C14.6667 9.73334 13.7334 10.6667 11.4 10.6667H10.6667V8.60001C10.6667 6.26668 9.73337 5.33334 7.40004 5.33334H5.33337V4.60001C5.33337 2.26668 6.26671 1.33334 8.60004 1.33334H11.4C13.7334 1.33334 14.6667 2.26668 14.6667 4.60001Z"
      stroke="#14110F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Copy;
