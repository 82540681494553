import { Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import React from 'react';

import {
  COUNTRY_CODE,
  GENDER,
  NAME_PREFIX,
  OPERATOR,
  SOURCE,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';

const { operator, valueBySelect, valueByInput } = formValidatorRules;

export const PrimaryCode = ({ name, dynamicInputLoader = false }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Primary Code</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select country code"
                options={COUNTRY_CODE}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const SecondaryCode = ({ name, dynamicInputLoader }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Secondary Code</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select country code"
                options={COUNTRY_CODE}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
export const Location = ({
  name,
  dynamicInputData = {},
  dynamicInputLoader,
}) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Location</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select location"
                options={dynamicInputData?.locations}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Gender = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Gender</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select placeholder="Select gender" options={GENDER} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
export const State = ({
  name,
  dynamicInputData,
  dynamicInputLoader,
  setSelectedCountry,
  selectedCountry = null,
  selectedState,
  setSelectedState,
  listState,
  form,
  field,
}) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>State</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name={[name, 'country']}
                  className="form-filed-custom"
                  rules={[{ ...valueBySelect }]}
                >
                  <Select
                    placeholder="Select country"
                    options={dynamicInputData?.countries}
                    loading={dynamicInputLoader}
                    onChange={(value) => {
                      setSelectedState(null);
                      setSelectedCountry(value);
                      form.setFieldValue(
                        ['conditions', field.name, 'conditions', name, 'value'],
                        null,
                      );
                    }}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={[name, 'value']}
                  className="form-filed-custom"
                  rules={[{ ...valueBySelect }]}
                >
                  <Select
                    placeholder="Select state"
                    options={listState?.map((items) => ({
                      value: items?.id,
                      label: items?.name,
                    }))}
                    value={selectedState}
                    onChange={(value) => setSelectedState(value)}
                    disabled={!selectedCountry}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Specialization = ({
  name,
  dynamicInputData = {},
  dynamicInputLoader = false,
}) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Specialization</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.CONTAINS_NOT_CONTAINS}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select specialization"
                options={dynamicInputData?.specializations}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const ZipCode = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Zip Code</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[
                { ...valueByInput },
                {
                  validator: (_, value) => {
                    if (value && value.length > 6) {
                      return Promise.reject(new Error('Invalid Zip Code'));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Enter zip code"
                className="full-width"
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Tag = ({ name, dynamicInputData, dynamicInputLoader }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Tag</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.CONTAINS_NOT_CONTAINS}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select
                placeholder="Select tag"
                options={dynamicInputData?.tags}
                loading={dynamicInputLoader}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const CreatedAt = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Created At</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.BEFORE_AFTER}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <DatePicker placeholder="Enter date" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Status = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Status</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Radio.Group name="optInConsent">
                <Radio value="true">Active</Radio>
                <Radio value="false">In-active</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const Source = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Source</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              name={[name, 'operator']}
              className="form-filed-custom"
              rules={[{ ...operator }]}
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select placeholder="Select source" options={SOURCE} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const OptInConsent = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Opt In Consent</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              initialValue="EQUALS"
              name={[name, 'operator']}
              className="form-filed-custom"
            >
              <Input disabled variant="borderless" />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Radio.Group name="optInConsent">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);

export const NamePrefix = ({ name }) => (
  <>
    <Row className="segment-rule-container mb-8" key="id">
      {/* attribute column */}

      <Col
        span={6}
        className="d-flex align-center segment-rule-sub-container p-16"
      >
        <div>Prefix</div>
      </Col>

      <Col className="p-16" span={18}>
        <Row gutter={8}>
          <Col span={12}>
            {/* operator column */}

            <Form.Item
              initialValue="EQUALS"
              name={[name, 'operator']}
              className="form-filed-custom"
            >
              <Select
                placeholder="Select operator"
                options={OPERATOR.COMPARISON}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            {/* value column */}

            <Form.Item
              name={[name, 'value']}
              className="form-filed-custom"
              rules={[{ ...valueBySelect }]}
            >
              <Select placeholder="Select source" options={NAME_PREFIX} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
