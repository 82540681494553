import React from 'react';
import { useParams } from 'react-router-dom';
import ContactForm from './ContactForm';

function EditContact() {
  const { id } = useParams();
  return <ContactForm edit={id} />;
}

export default EditContact;
