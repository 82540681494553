import { gql } from '@apollo/client';

export const ADMIN_DASHBOARD_MESSAGE_ANALYTICS = gql`
  query DashboardMessageAnalyticsAdmin(
    $filter: dashboardMessageAnalyticsFilter
  ) {
    dashboardMessageAnalyticsAdmin(filter: $filter) {
      attempted
      sent
      delivered
      read
      replied
      failed
      pending
    }
  }
`;

export const USER_DASHBOARD_MESSAGE_ANALYTICS = gql`
  query DashboardMessageAnalytics($filter: dashboardMessageAnalyticsFilter) {
    dashboardMessageAnalytics(filter: $filter) {
      attempted
      sent
      delivered
      read
      replied
      failed
      pending
    }
  }
`;

export const ADMIN_GENERAL_STATISTICS = gql`
  query DashboardGeneralAnalyticsAdmin(
    $filter: DashboardGeneralAnalyticsFilter
  ) {
    dashboardGeneralAnalyticsAdmin(filter: $filter) {
      totalContacts {
        total
        active
        optOut
      }
      totalCampaigns {
        total
        active
      }
      totalMessageTemplates
    }
  }
`;
export const USER_GENERAL_STATISTICS = gql`
  query DashboardGeneralAnalytics($filter: DashboardGeneralAnalyticsFilter) {
    dashboardGeneralAnalytics(filter: $filter) {
      totalCampaigns {
        total
        active
      }
      totalContacts {
        total
        active
        optOut
      }
      totalMessageTemplates
    }
  }
`;
