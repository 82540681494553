import { gql } from '@apollo/client';

export const CREATE_ROLE = gql`
  mutation CreateRole($data: CreateRoleInput!) {
    createRole(data: $data) {
      message
      role {
        name
        id
        description
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($data: UpdateRoleInput!, $where: RoleWhereUniqueInput!) {
    updateRole(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($where: RoleWhereUniqueInput!) {
    deleteRole(where: $where) {
      message
    }
  }
`;

export const TOGGLE_ROLE_STATUS = gql`
  mutation ToggleRoleStatus(
    $data: ToggleRoleStatus!
    $where: RoleWhereUniqueInput!
  ) {
    toggleRoleStatus(data: $data, where: $where) {
      message
    }
  }
`;
