import { CloseOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Card, Col, Divider, Form, Input, Row, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import LoaderComponent from '../../components/LoaderComponent';
import { STATES_LIST } from '../contacts/graphql/Queries';
import RuleListing from './RuleListing';
import {
  CreatedAt,
  Gender,
  Location,
  NamePrefix,
  OptInConsent,
  PrimaryCode,
  SecondaryCode,
  Source,
  Specialization,
  State,
  Status,
  Tag,
  ZipCode,
} from './components/RenderSegmentInput';
import { GET_SEGMENT_CONTACTS } from './graphql/Queries';

const { Title } = Typography;

// render inputs according to selection from list
const INPUT_RENDERER = {
  PRIMARY_COUNTRY_CODE: PrimaryCode,
  SECONDARY_COUNTRY_CODE: SecondaryCode,
  LOCATION: Location,
  GENDER: Gender,
  STATE: State,
  SPECIALIZATION: Specialization,
  ZIP_POSTAL_CODE: ZipCode,
  TAGS: Tag,
  CREATED_AT: CreatedAt,
  IS_ACTIVE: Status,
  SOURCE: Source,
  OPT_IN_CONSENT: OptInConsent,
  NAME_PREFIX: NamePrefix,
};

function Subfield({
  field,
  name,
  form,
  subRemove,
  dynamicInputData = {},
  dynamicInputLoader = false,
  setSelectedCountry,
  selectedCountry,
  selectedState,
  setSelectedState,
}) {
  const item = Form.useWatch(
    ['conditions', field.name, 'conditions', name],
    form,
  );

  const id = item?.id;

  const RenderInputs = INPUT_RENDERER[id];

  const [listState, setListState] = useState(null);

  const [getStates] = useLazyQuery(STATES_LIST, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setListState(res?.states?.data);
    },
    onError() {},
  });

  useEffect(() => {
    if (item?.country) {
      getStates({ variables: { where: { countryId: item?.country } } });
    }
  }, [item?.country]);

  return (
    <div>
      {RenderInputs && (
        <Row className="d-flex">
          <Col span={22}>
            <RenderInputs
              field={field}
              form={form}
              listState={listState}
              item={item}
              name={name}
              id={id}
              dynamicInputData={dynamicInputData}
              dynamicInputLoader={dynamicInputLoader}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
            />
          </Col>
          <Col span={2} className="d-flex justify-around align-center">
            <CloseOutlined
              onClick={() => {
                subRemove([name, 'name']);
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

const InnerCardList = ({
  field,
  remove,
  form,
  dynamicInputData = {},
  dynamicInputLoader = false,
  setSelectedCountry,
  selectedCountry,
  selectedState,
  setSelectedState,
  setLoading,
  loading,
}) => {
  const [showInnerList, setShowForm] = useState(false);

  return (
    <Card
      size="small"
      title={`And Filter ${field.name + 1}`}
      key={field.key}
      extra={
        <CloseOutlined
          onClick={() => {
            remove(field.name);
          }}
        />
      }
    >
      <Form.Item>
        <Form.List name={[field.name, 'conditions']}>
          {(subFields, { add: subAdd, remove: subRemove }) => (
            <div className="segment-fom-sub-container">
              {subFields.map(({ key, ...restField }) => (
                <>
                  <Subfield
                    loading={loading}
                    setLoading={setLoading}
                    field={field}
                    key={key}
                    form={form}
                    {...restField}
                    subRemove={subRemove}
                    dynamicInputData={dynamicInputData}
                    dynamicInputLoader={dynamicInputLoader}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                  />
                </>
              ))}

              {showInnerList ? (
                <Card size="small">
                  <div className="mb-16 d-flex justify-end">
                    <CloseOutlined onClick={() => setShowForm(false)} />
                  </div>

                  <RuleListing
                    onClick={(id) => {
                      subAdd({ id });
                      setShowForm(false);
                    }}
                  />
                </Card>
              ) : (
                <Col span={4}>
                  <Button type="dashed" onClick={() => setShowForm(true)} block>
                    + And Item
                  </Button>
                </Col>
              )}
            </div>
          )}
        </Form.List>
      </Form.Item>
    </Card>
  );
};

const SegmentForm = ({
  form,
  setOuterShowForm,
  showOuterList,
  dynamicInputData = {},
  dynamicInputLoader = false,
  setSelectedCountry,
  selectedCountry,
  selectedState,
  setSelectedState,
  setLoading,
  loading,
}) => {
  const conditions = Form.useWatch(['conditions'], form);

  const queryConditions = useMemo(
    () =>
      conditions
        ? conditions?.map(({ conditions: subConditions }) => ({
            conditions:
              subConditions
                ?.filter(({ id, value, operator }) => id && value && operator)
                ?.map(({ id, country, ...rest }) => ({
                  attribute: id,
                  ...rest,
                })) || [],
          }))
        : [],
    [conditions],
  );

  const { data: segmentData, loading: segmentCountLoading } = useQuery(
    GET_SEGMENT_CONTACTS,
    {
      skip: queryConditions?.length <= 0,
      variables: {
        where: {
          conditions: queryConditions,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  return (
    <div className="segment-modal-container">
      <Form
        layout="vertical"
        form={form}
        name="dynamic_form_complex"
        autoComplete="off"
        initialValues={{
          name: '',
          description: '',
          conditions: [],
        }}
      >
        <Row>
          {/* name & description */}
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter segment name' }]}
            >
              <Input placeholder="Enter Segment Name" allowClear />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea
                rows={5}
                cols={10}
                placeholder="Enter Segment Description"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={12} className="justify-end d-flex">
            <div className="total-contact-card">
              <Title level={2} className="justify-start d-flex">
                {segmentCountLoading ? (
                  <LoaderComponent setHeight="10" />
                ) : (
                  segmentData?.segmentContacts?.count ?? 0
                )}
              </Title>
              <Title level={5}>Total Contacts</Title>
            </div>
          </Col>
        </Row>

        {/* dynamic form */}

        <Form.List name="conditions">
          {(fields, { add, remove }) => (
            <>
              <div className="segment-fom-sub-container">
                {fields.map((field, index) => (
                  <>
                    <InnerCardList
                      loading={loading}
                      setLoading={setLoading}
                      field={field}
                      remove={remove}
                      form={form}
                      dynamicInputData={dynamicInputData}
                      dynamicInputLoader={dynamicInputLoader}
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                      selectedState={selectedState}
                      setSelectedState={setSelectedState}
                    />

                    {index !== fields?.length - 1 && (
                      <Divider className="divider" orientation="left">
                        Or
                      </Divider>
                    )}
                  </>
                ))}

                {showOuterList || !conditions?.length > 0 ? (
                  <RuleListing
                    onClick={(id) => {
                      add({
                        conditions: [{ id }],
                      });
                      setOuterShowForm(false);
                    }}
                  />
                ) : (
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() => setOuterShowForm(true)}
                      block
                    >
                      + Add Or Item
                    </Button>
                  </Col>
                )}
              </div>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  );
};
export default SegmentForm;
