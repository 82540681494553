import React from 'react';
import { useParams } from 'react-router-dom';
import MessageForm from './components/MessageForm';

const EditMessage = () => {
  const { id } = useParams();
  return (
    <div>
      <MessageForm edit={id} />
    </div>
  );
};

export default EditMessage;
