import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Modal, Row } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { ROLE_KEYS } from '../../common/constants';
import SegmentForm from './SegmentForm';
import {
  CREATE_SEGMENT,
  UPDATE_ADMIN_SEGMENT,
  UPDATE_USER_SEGMENT,
} from './graphql/Mutations';
import {
  GET_ACTIVE_LOCATIONS,
  GET_ACTIVE_SPECIALIZATIONS,
  GET_ACTIVE_TAGS,
  GET_COUNTRY_CODE,
} from './graphql/Queries';

function SegmentModalForm({
  isPrompt,
  handleClose,
  isEdit,
  setOuterShowForm,
  showOuterList,
  setIsPrompt,
  adminListingRefetch,
  setDynamicInputData,
  dynamicInputData,
  setSelectedCountry,
  selectedCountry,
  stateList,
  selectedState,
  setSelectedState,
  form,
  getState,
  setStateList,
  setLoading,
  loading,
}) {
  const { getCurrentUser } = useContext(AppContext);
  const userData = getCurrentUser();

  const { loading: countryCodesLoading } = useQuery(GET_COUNTRY_CODE, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicInputData((prev) => ({
        ...prev,
        countryCodes: res?.countries?.data.map((items) => ({
          label: items?.phoneCode,
          value: items?.id,
        })),
      }));
    },
    onError() {},
  });

  const { loading: locationsLoading } = useQuery(GET_ACTIVE_LOCATIONS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicInputData((prev) => ({
        ...prev,
        locations: res?.activeLocations?.locations.map((items) => ({
          label: items?.name,
          value: items?.id,
        })),
      }));
    },
    onError() {},
  });

  const { loading: specializationsLoading } = useQuery(
    GET_ACTIVE_SPECIALIZATIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setDynamicInputData((prev) => ({
          ...prev,
          specializations: res?.activeSpecializations?.specializations.map(
            (items) => ({
              label: items?.name,
              value: items?.id,
            }),
          ),
        }));
      },
      onError() {},
    },
  );
  const { loading: tagsLoading } = useQuery(GET_ACTIVE_TAGS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setDynamicInputData((prev) => ({
        ...prev,
        tags: res?.activeTags?.tags.map((items) => ({
          label: items?.name,
          value: items?.id,
        })),
      }));
    },
    onError() {},
  });

  const dynamicInputLoader =
    countryCodesLoading ||
    locationsLoading ||
    specializationsLoading ||
    tagsLoading;

  const [createSegment, { loading: createLoading }] = useMutation(
    CREATE_SEGMENT,
    {
      onCompleted() {
        adminListingRefetch();
        setIsPrompt(false);
      },
      onError() {},
    },
  );
  const [updateAdminSegment, { loading: updateLoading }] = useMutation(
    userData?.role === ROLE_KEYS.USER
      ? UPDATE_USER_SEGMENT
      : UPDATE_ADMIN_SEGMENT,
    {
      onCompleted() {
        adminListingRefetch();
        setIsPrompt(false);
      },
      onError() {},
    },
  );

  const handleFormSubmit = () => {
    form
      ?.validateFields()
      .then((values) => {
        const { name, description } = values || {};

        const conditions = values?.conditions?.map((items) => ({
          conditions: items?.conditions?.map(({ operator, id, value }) => ({
            attribute: id,
            operator,
            value,
          })),
        }));

        const submitValues = {
          name,
          description,
          conditions,
        };

        const dataKey = isEdit ? updateAdminSegment : createSegment;

        // create update mutation
        dataKey({
          variables: {
            ...(isEdit ? { where: { id: isEdit } } : {}),
            data: { ...submitValues },
          },
        });
      })
      .catch((error) => error);
  };
  return (
    <div>
      <>
        <Modal
          afterClose={() => form.resetFields()}
          destroyOnClose
          open={isPrompt}
          onCancel={handleClose}
          width={1000}
          maskClosable={false}
          footer={
            <Row className="d-flex justify-end" gutter={8}>
              <Col>
                <Button
                  onClick={handleClose}
                  disabled={createLoading || updateLoading}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleFormSubmit}
                  loading={createLoading || updateLoading}
                >
                  Save
                </Button>
              </Col>
            </Row>
          }
          title={`${isEdit ? 'Edit' : 'Create'} segment`}
        >
          <SegmentForm
            form={form}
            isEdit={isEdit}
            setOuterShowForm={setOuterShowForm}
            showOuterList={showOuterList}
            loading={loading}
            setLoading={setLoading}
            setStateList={setStateList}
            getState={getState}
            selectedState={selectedState}
            stateList={stateList}
            dynamicInputData={dynamicInputData}
            dynamicInputLoader={dynamicInputLoader}
            setSelectedCountry={setSelectedCountry}
            selectedCountry={selectedCountry}
            setSelectedState={setSelectedState}
          />
        </Modal>
      </>
    </div>
  );
}

export default SegmentModalForm;
