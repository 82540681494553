import React from 'react';
import InfiniteSelect from '../../../components/InfiniteSelect';
import { ACTIVE_LOCATIONS_LIST } from '../graphql/Queries';

const variableSelector = ({ skip, limit }) => ({
  filter: { skip, limit },
});
const dataSelector = (data) =>
  data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

const dataKeys = {
  data: 'activeLocations',
  count: 'count',
  records: 'locations',
};

const LocationSelect = (props) => (
  <InfiniteSelect
    searchable={false}
    query={ACTIVE_LOCATIONS_LIST}
    variableSelector={variableSelector}
    dataKeys={dataKeys}
    dataSelector={dataSelector}
    placeholder="Select Location"
    {...props}
  />
);

export default LocationSelect;
