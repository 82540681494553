import React from 'react';
import { useParams } from 'react-router-dom';
import UserForm from './components/UserForm';

const EditUser = () => {
  const params = useParams();
  return (
    <div>
      <UserForm edit={params?.id} />
    </div>
  );
};

export default EditUser;
