import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Card, Modal, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { camelCase, startCase } from 'lodash';
import React, { useState } from 'react';
import Chat from '../../assets/icon/Chat';
import DeleteIcon from '../../assets/icon/DeleteIcon';
import {
  DATE_FORMATS,
  MODULES,
  MODULES_KEY,
  PERMISSION_OBJECT,
  ROUTES,
} from '../../common/constants';
import CommonTable from '../../components/CommonTable';
import SearchComponent from '../../components/SearchComponent';
import useGetRole from '../../hooks/useGetRole';
import useRouter from '../../hooks/useRouter';
import useVerifyPermissions from '../../hooks/useVerifyPermissions';
import {
  DELETE_ADMIN_CAMPAIGN,
  DELETE_USER_CAMPAIGN,
} from './graphql/Mutations';
import {
  GET_ADMIN_CAMPAIGN_LISTING,
  GET_USER_CAMPAIGN_LISTING,
} from './graphql/Queries';

const { Title } = Typography;

function CampaignListing() {
  const { navigate } = useRouter();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    limit: 10,
    total: 0,
    search: '',
  });
  const [listSort, setListSort] = useState({
    sortOn: 'updatedAt',
    sortBy: 'DESC',
  });
  const [isModal, setIsModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const permission = useGetRole();

  const dataKey = permission ? 'campaignsAdmin' : 'campaigns';

  const campaignAddPermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.CREATE,
  });

  const campaignDeletePermission = useVerifyPermissions({
    modulekey: MODULES_KEY?.CAMPAIGN_MANAGEMENT,
    allowedPermissions: PERMISSION_OBJECT?.DELETE,
  });

  const { loading: campaignLoading, data, refetch } = useQuery(
    permission ? GET_ADMIN_CAMPAIGN_LISTING : GET_USER_CAMPAIGN_LISTING,
    {
      variables: {
        sort: {
          sortOn: listSort?.sortOn,
          sortBy: listSort?.sortBy,
        },
        filter: {
          skip: paginationConfig?.skip,
          limit: paginationConfig?.limit,
          search: paginationConfig?.search,
        },
      },
      fetchPolicy: 'network-only',

      onError() {},
    },
  );

  const [deleteCampaign, { loading: deleteLoading }] = useMutation(
    permission ? DELETE_ADMIN_CAMPAIGN : DELETE_USER_CAMPAIGN,
    {
      onCompleted() {
        setIsModal(false);
        refetch();
      },
      onError() {},
    },
  );

  const handleTableChange = (pagination, _, sorter) => {
    setListSort((prev) => ({
      ...prev,
      sortOn: sorter?.field,
      sortBy: sorter?.order === 'descend' ? 'DESC' : 'ASC',
    }));
    setPaginationConfig((prev) => ({
      ...prev,
      limit: pagination?.limit,
      currentPage: pagination?.current,
      skip: pagination?.current * pagination?.limit - pagination?.limit,
    }));
  };

  const handleSearchChange = (text) => {
    setPaginationConfig({ search: text });
  };
  const handleDelete = (id) => {
    setIsModal(true);
    setDeleteId(id);
  };
  const handleSubmit = () => {
    if (deleteId) {
      deleteCampaign({
        variables: {
          where: {
            id: deleteId,
          },
        },
      });
    }
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (value) => value ?? '-',
      sorter: true,
    },
    {
      title: 'Total Messages',
      dataIndex: 'totalMessages',
      key: 'totalMessages',
      ellipsis: true,
      align: 'left',
      render: (value) => value ?? '-',
    },

    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      align: 'left',
      render: (value) =>
        value ? dayjs(value)?.format(DATE_FORMATS?.REGULAR) : '-',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      ellipsis: true,
      align: 'left',
      render: (value) =>
        value ? dayjs(value)?.format(DATE_FORMATS?.WITH_TIME) : '-',
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      ellipsis: true,
      align: 'left',
      render: (value, allValues) =>
        allValues?.creator?.firstName
          ? startCase(camelCase(allValues?.creator?.firstName))
          : '-',
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      className: 'cursor-auto',
      fixed: 'center',
      align: 'center',
      width: '9%',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (test, value) => (
        <div className="action-button d-flex justify-end">
          <Tooltip title="Add Message" placement="top" zIndex={4}>
            <Button
              type="text"
              danger
              onClick={() =>
                navigate(`${ROUTES.CAMPAIGN}/${value?.id}${ROUTES.MESSAGE}/add`)
              }
              icon={<Chat />}
            />
          </Tooltip>
          {campaignDeletePermission && (
            <Tooltip title="Delete" placement="top" zIndex={4}>
              <Button
                type="text"
                danger
                onClick={() => handleDelete(value?.id)}
                icon={<DeleteIcon />}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleRowClick = (record) => {
    const id = record?.id;
    const handleRow = () => {
      navigate(`${ROUTES.CAMPAIGN}/${id}${ROUTES.MESSAGE}`);
    };
    return {
      onClick: handleRow,
    };
  };

  return (
    <>
      <Title
        className="site-page-header p-0 mt-0 d-flex justify-between"
        level={3}
      >
        <div className="d-flex align-center gap-8">
          {MODULES.CAMPAIGN}
          <Badge count={data?.[dataKey]?.count?.toString()?.padStart(2, '0')} />
        </div>

        {campaignAddPermission && (
          <Button
            className="ml-8"
            key="1"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate(ROUTES.ADD_CAMPAIGN)}
          >
            Add Campaign
          </Button>
        )}
      </Title>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="contact-sidebar">
              <div className="contact-filter-left" />
              <div className="contact-filter-right">
                <div className="contact-filter">
                  <SearchComponent getData={handleSearchChange} />
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="card-body-wrapper p-0">
          <CommonTable
            className="pointer"
            columns={columns}
            data={data?.[dataKey]?.campaigns}
            onChange={handleTableChange}
            loadingData={campaignLoading}
            tableClassName=""
            paginationConfig={{
              ...paginationConfig,
              total: data?.[dataKey]?.count,
              showSizeChanger: false,
            }}
            onRow={handleRowClick}
          />
        </div>
      </Card>
      <Modal
        open={isModal}
        onOk={handleSubmit}
        closable
        onCancel={() => setIsModal(false)}
        title="Delete Campaign"
        okText="Delete"
        cancelButtonProps={{
          disabled: deleteLoading,
        }}
        confirmLoading={deleteLoading}
      >
        Are you sure want to delete this campaign ?
      </Modal>
    </>
  );
}

export default CampaignListing;
